import { Box } from "@mui/material";
import LocalImage from "../../../../components/LocalImage";

const RewardImage = () => {
  return (
    <Box>
      <LocalImage
        src="img/community/Season Full Gym Reward 1.png"
        alt=""
        style={{ marginTop: '32px', width: '100%', height: '100%' }}
      />
    </Box>
  );
};

export default RewardImage;
