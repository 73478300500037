import { Box } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading4 } from '../../../components/Heading';

const Tab = ({
  title,
  list,
  mt,
  selected,
  onChange,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: mt || 0,
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            background: '#141516',
            padding: 1,
          }}
        >
          {list.map((item, index) => (
            <Box
              key={item.id}
              onClick={() => onChange(item.id)}
              sx={{
                cursor: "pointer",
                width: "100%",
                textAlign: "center",
                borderRight: index === list.length - 1 ? "0px" : "2px #B1B1B1 solid",
                padding: 3,
                color: item.id === selected ? "#FFFFFF" : "#0076FF",
              }}
            >
              <Heading4>{item.title}</Heading4>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Tab;
