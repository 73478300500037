import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";

import {
  LEADERBOARD_PERIODS,
  LEADERBOARD_ACTIONS,
} from '../../../config/constants';
import Container from '../../../components/Layout/Container';
import {
  Heading2,
  Heading4,
} from '../../../components/Heading';
import Loading from '../../../components/Loading';
import Tab from '../../Leaderboards/Tab';
import { callBackbonePostApi } from '../../../services/backboneApiService';

const Leaderboards = () => {
  const navigate = useNavigate();
  const [ leaderboardData, setLeaderboardData ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    callBackbonePostApi({
      action: "getAllLeaderboardPeriods",
    }, '')
    .then((data) => {
      if (data?.status === 200 && data?.data) {
        setLeaderboardData(data?.data);
      }
      setIsLoading(false);
    })
    .catch(() => {
      setIsLoading(false);
    });
  }, []);

  const handleChangePeriod = (category, newPeriod) => {
    navigate(`/leaderboards?category=${category}&period=${newPeriod}`);
  }

  const gemsLeaderboard = leaderboardData.find(item => item.name === "getGemsLeaderboard");
  const itemPeriods = [];
  let selectedAction = "";
  if (!!gemsLeaderboard) {
    Object.keys(LEADERBOARD_PERIODS).forEach(item => {
      const leaderboard = (leaderboardData.find(subItem => subItem.name === gemsLeaderboard.name)?.periods || []);
      if (leaderboard.indexOf(LEADERBOARD_PERIODS[item].id) > -1) {
        itemPeriods.push({
          id: item,
          title: LEADERBOARD_PERIODS[item].title
        });
      }
    });
    Object.keys(LEADERBOARD_ACTIONS).forEach(item => {
      if (LEADERBOARD_ACTIONS[item]?.id === gemsLeaderboard.name) {
        selectedAction = item;
      }
    });
  }

  return (
    <Container sx={{ marginTop: 5 }}>
      <Heading2>LEADERBOARDS</Heading2>
      <Box sx={{ marginTop: 3 }}>
        {!!isLoading && <Loading />}
        {!isLoading && !!gemsLeaderboard && <Box>
          <Heading4 sx={{ marginTop: 3, marginBottom: 3 }}>UNITY GEMS EARNED</Heading4>
          <Tab
            mt={2}
            list={itemPeriods}
            selected=""
            onChange={(newPeriod) => handleChangePeriod(selectedAction, newPeriod)}
          />
        </Box>}
      </Box>
    </Container>
  );
};

export default Leaderboards;
