import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import useQuery from '../../utils/useQuery';
import { formatLocalizedDate } from '../../utils';
import { callBackbonePostApi } from '../../services/backboneApiService';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import Container from '../../components/Layout/Container';
import { Heading4 } from '../../components/Heading';
import FooterMenu from '../../components/Footer/FooterMenu';
import TopBanner from './TopBanner';
import MembersList from './MembersList';
import PersonalInfo from './PersonalInfo';
import Tab from './Tab';
import { getUser } from '../../utils/cookie';

import {
  LEADERBOARD_ACTIONS,
  LEADERBOARD_PERIODS,
  LEADERBOARD_LOCATIONS,
} from '../../config/constants';
import ResultDiagram from '../Profiles/GymProfile/SeasonReward/ResultDiagram';
import axios from 'axios';

const LeaderBoards = () => {
  const queryParameters = useQuery();
  const loggedinUser = getUser();
  const category = queryParameters.get('category') || 'totalWork';
  const location = queryParameters.get('location') || null;
  const userId = queryParameters.get('userId') || queryParameters.get('userid') || "";
  const isTv = queryParameters.get('tv') === "true";

  const [period, setPeriod] = useState(queryParameters.get('period'));
  const [fullData, setFullData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const periods = [];

  useEffect(() => {
    const init = () => {
      setIsLoading(true);

      const action = LEADERBOARD_ACTIONS[category]?.id;
      if (!action) {
        setIsLoading(false);
        return;
      }

      let payload = {
        action,
        location
      }

      if (category !== 'fullgymreward') {
        callBackbonePostApi(payload, '')
        .then((data) => {
          setIsLoading(false);
          if (data?.status === 200 && data?.data) {
            setFullData(data?.data);
            if (!period) {
              let defaultPeriod = "";
              Object.keys(LEADERBOARD_PERIODS).forEach(item => {
                if (!!data?.data[LEADERBOARD_PERIODS[item].id] && !defaultPeriod) {
                  defaultPeriod = item;
                }
              });
              setPeriod(defaultPeriod);
            }
          }
        })
        .catch(() => {
          setIsLoading(true);
        });
      } else {
        axios.post('https://elyjpeuz80.execute-api.us-west-2.amazonaws.com/prod/Backbone', {
          "action": "getGymRewardInfo",
          "gymId": 2
        }, {
          headers: {
            'x-api-key': 'X2W1pNLc2V4C9rziMe9Mb6jWOhZgGL7T7IFSaAd2'
          }
        }).then((response) => {
          setFullData(response.data);
          setIsLoading(false);
        }).catch(() => {
          setIsLoading(true);
        })
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, location]);

  const handleChangePeriod = (newPeriod) => {
    setPeriod(newPeriod);
    window.history.replaceState(null, "", `/leaderboards?category=${category}&location=${location || ""}&period=${newPeriod}&userId=${userId || ""}`)
  }

  if (isLoading) {
    return <Loading fullscreen />;
  }

  const key = LEADERBOARD_PERIODS[period || "all"].id;
  let data = {};
  let isDataExists = 0;

  if (!!fullData && category !== 'fullgymreward') {
    Object.keys(LEADERBOARD_PERIODS).forEach(item => {
      if (!!fullData[LEADERBOARD_PERIODS[item].id]) {
        periods.push({
          id: item,
          title: LEADERBOARD_PERIODS[item].title,
        });
      }
    });
    data = fullData[key] || {};
  } else if (!!fullData && category === 'fullgymreward') {
    fullData.forEach(value => {
      Object.keys(LEADERBOARD_PERIODS).forEach(item => {
        if (value.period === LEADERBOARD_PERIODS[item].id) {
          periods.push({
            id: item,
            title: LEADERBOARD_PERIODS[item].title,
          });
        }
        if (value.period === period) {
          isDataExists = 1;
          data = value;
        }
      });
    });
  }

  const breadcrumbs = [
    { title: 'Home', link: 'https://www.blackbox-vr.com' },
    { title: 'Community', link: '/' },
  ];
  if (location) {
    breadcrumbs.push({ title: `${LEADERBOARD_LOCATIONS[location].title} Gym`, link : `/gym/${LEADERBOARD_LOCATIONS[location].id}/${LEADERBOARD_LOCATIONS[location].title}` });
  }
  breadcrumbs.push({ title: "Leaderboards", link: `/main-leaderboards?location=${location || ""}&userId=${userId || ""}` });
  breadcrumbs.push({ title: LEADERBOARD_ACTIONS[category]?.title || "" });

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - ${LEADERBOARD_ACTIONS[category]?.title || ""} Leaderboards`}
        description={`Black Box VR - ${LEADERBOARD_ACTIONS[category]?.title || ""} Leaderboards`}
      />
      <TopBanner
        title={location ? `${LEADERBOARD_LOCATIONS[location].title} GYM LEADERBOARD` : "Leaderboard"}
        subTitle={LEADERBOARD_ACTIONS[category]?.title || ""}
        breadcrumbs={breadcrumbs}
      />
      {!isTv && <Tab
        mt={7}
        list={periods}
        selected={period}
        onChange={handleChangePeriod}
      />}
      {userId && category !== "teamRaid" && !isTv && <PersonalInfo
        userId={userId || loggedinUser?.userId || ""}
        data={data}
      />}
      {!isEmpty(data) ? (
        category === "fullgymreward" 
          ? 
            <Box
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200, marginBottom: '100px' }}
            >
              <ResultDiagram data={data} width={120} height={923}/>
            </Box>
          : <MembersList type={(category === "teamRaid") ? "team" : "member"} data={data} isTv={isTv} />
      ) : (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 200, marginBottom: '100px' }}
        >
            <Typography>No Data</Typography>
        </Box>
      )
      }

      {!isTv && <Container sx={{marginBottom: (category === 'fullgymreward' ? 30 : 0)}}>
        {fullData?.LastUpdated && <Box sx={{ marginBottom: 3 }}>
          <Typography sx={{ 
            fontWeight: 400,
            textAlign: "center",
            fontSize: 16,
          }}>Last Updated: {formatLocalizedDate(new Date(fullData?.LastUpdated), 'll - h:mma')} {new Date(fullData?.LastUpdated).toLocaleTimeString('en-us',{ timeZoneName:'short' }).split(' ')[2]}</Typography>
        </Box>}
        <Link to={`/main-leaderboards?location=${location || ""}&userId=${userId || ""}`}>
          <Heading4 sx={{
            color: "#0076FF",
            textAlign: "center",
            marginBottom: 5,
          }}>View all Leaderboards</Heading4>
        </Link>
      </Container>}
      {!isTv && <FooterMenu/>}
    </Box>
  );
};

export default LeaderBoards;
