import { Box, Link, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Container from '../../../components/Layout/Container';
import VideoPlayerPlaceholder from '../../../assets/img/stats/video-player-placeholder.webp';
import { DesktopVideoHeader, DesktopVideoInfo } from './DesktopVideoInfo';
import MobileVideoInfo from './MobileVideoInfo';

const GamePlayVideo = ({
  data,
  order,
}) => {
  const { username } = useParams();

  const trophies = data.trophies_earned;
  const userScore = data.user_score;
  const chalName = data.chal_name;
  const chalScore = data.chal_score;
  const startTime = data.starttime;
  const repCount = data.rep_count;
  const setCount = data.set_count;
  const calories = data.calories;
  const sliceCount = data.slice_count;
  const championsDeployed = data.user_champions_deployed;
  const totalWork = data.total_work;
  const video = data.video_link;
  console.log(userScore)

  return (
    <Box sx={{
      backgroundColor: order % 2 === 0 ? "#262626" : "transparent",
      paddingTop: 4,
      paddingBottom: 5,
      paddingLeft: {
        xs: 2,
        tl: 0,
      },
      paddingRight: {
        xs: 2,
        tl: 0,
      },
    }}>
      <Container>
        <DesktopVideoHeader
          startTime={startTime}
          trophies={trophies}
          username={username}
          userScore={userScore}
          chalName={chalName}
          chalScore={chalScore}
          matchOutcome={data.match_outcome}
          isRaidBattle={!!data.isRaidBattle}
          challengerReached={data.challenger_reached}
          raidPointsEarned={data.raid_points_earned}
        />
        <Box mt={3} width="100%" sx={{ aspectRatio: 1.8735 }}>
          {!video && <Typography sx={{
            fontSize: "24px",
            width: {
              xs: "auto",
              tl: "455px",
            },
            margin: "auto",
            paddingTop: "100px",
          }}>
            This gameplay video is not available for this workout. This member may have chosen to hide their videos, the video may still be uploading, or the video may have expired.
          </Typography>}
          {!!video && (<>
            <ReactPlayer
              url={video}
              playing
              loop
              controls
              light={<img src={VideoPlayerPlaceholder} style={{ width: "100%", height: "100%" }} alt='Thumbnail' />}
              width="100%"
              height="100%"
            />
            <Box mt={1} display='flex' justifyContent='flex-end'>
              <Link href={video} color='#0076FF' sx={{ cursor: 'pointer', 'textDecoration': 'none' }}> Download Video </Link>
            </Box>
          </>
          )}
        </Box>
        <DesktopVideoInfo
          repCount={repCount}
          setCount={setCount}
          calories={calories}
          sliceCount={sliceCount}
          championsDeployed={championsDeployed}
          totalWork={totalWork}
        />
        <MobileVideoInfo
          trophies={trophies}
          username={username}
          userScore={userScore}
          chalName={chalName}
          chalScore={chalScore}
          startTime={startTime}
          repCount={repCount}
          setCount={setCount}
          calories={calories}
          sliceCount={sliceCount}
          championsDeployed={championsDeployed}
          totalWork={totalWork}
          matchOutcome={data.match_outcome}
          isRaidBattle={!!data.isRaidBattle}
          challengerReached={data.challenger_reached}
          raidPointsEarned={data.raid_points_earned}
        />
      </Container>
    </Box>
  );
};

export default GamePlayVideo;
