import { Box, Typography } from '@mui/material';

import { Heading3 } from '../../../../../components/Heading';

const SummaryCell = ({
  label,
  value,
  description,
  textAlign = 'center',
  noMargin = false,
  sx,
}) => {
  return (
    <Box sx={sx}>
      <Typography
        fontSize={14}
        mt={noMargin ? 0 : -1}
        mb={noMargin ? 0 : 1}
        sx={{
          display: 'flex',
          justifyContent: textAlign,
          alignItems: 'center',
        }}
      >
        {label}{' '}
      </Typography>
      {!!value && <Heading3 sx={{ textAlign: 'center' }}>{value}</Heading3>}
    </Box>
  );
};

export default SummaryCell;
