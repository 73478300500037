import { Box } from '@mui/material';
import { useState, useEffect } from 'react';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import TopBanner from '../Leaderboards/TopBanner';
import GymProfileFooter from '../Profiles/GymProfile/Footer';
import RightCornerTag from '../Profiles/MemberProfile/Details/RightCornerTag';
import BoiseNewsEvents from '../Profiles/GymProfile/BoiseNewsEvents';
import Banner from './Banner';
import Overview from './Overview';
import DatesAndEvents from './DatesAndEvents';
import Rewards from './Rewards';
import Leaderboard from './Leaderboard';

import { useBoiseNewsEvents } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';

const Seasons = () => {
  const {
    data: { data: newsData, isLoading: isNewsLoading },
  } = useBoiseNewsEvents();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ data, setData ] = useState(null);

  const breadcrumbs = [
    { title: 'Home', link: 'https://www.blackbox-vr.com' },
    { title: 'Community', link: '/' },
    { title: 'Seasons' }
  ];

  useEffect(() => {
    callBackbonePostApi({
      action: "getSeasonDetails",
    }, '')
    .then((data) => {
      setIsLoading(false);

      if (data?.status === 200 && data?.data) {
        setData(data?.data);
      }
    })
    .catch(() => {
      setIsLoading(true);
    });
  }, []);

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - Seasons`}
        description={`Black Box VR - Seasons`}
      />
      <RightCornerTag />
      <TopBanner
        title={"NEVER-ENDING FUN, EXCITEMENT, AND REWARDS"}
        subTitle={"SEASONS"}
        breadcrumbs={breadcrumbs}
      />
      {isLoading && <Box sx={{ marginTop: 3, marginBottom: 3 }}>
        <Loading />
      </Box>}
      {!isLoading && !!data && 
        <img
          src={data?.bossBannerLink}
          alt="Season Info"
          width="100%"
        />
      }
      <Banner />
      {isLoading && <Box sx={{ marginTop: 3, marginBottom: 3 }}>
        <Loading />
      </Box>}
      {!isLoading && !!data && <Overview data={data} />}
      <DatesAndEvents
        data={data}
        isLoading={isNewsLoading}
      />
      <Rewards
        data={data}
        isLoading={isNewsLoading}
      />
      <Leaderboard />
      <BoiseNewsEvents
        name="News And Events"
        data={newsData}
        viewAllSx={{
          background: "transparent",
          borderColor: "#0076FF",
        }}
        isLoading={isNewsLoading}
      />
      <GymProfileFooter />
    </Box>
  );
};

export default Seasons;
