import { useState } from 'react';
import { Box } from '@mui/material';
import { ApiRegion } from '@amityco/js-sdk';
import { AmityUiKitProvider, AmityUiKitChat } from '@amityco/ui-kit-open-source';

import useQuery from '../../utils/useQuery';

const apiKey = process.env.REACT_APP_AMITY_API_KEY;
const bbvrTheme = {
  palette: {
    primary: '#0076FF',
  },
  typography: {
    global: {
      fontFamily: 'Roboto, Inter, -apple-system, BlinkMacSystemFont, Arial, sans-serif',
      fontStyle: 'normal',
    },
  },
};

const Chat = ({
  userId,
  secondUserId,
  type,
  sx,
}) => {
  const [ connected, setConnected ] = useState(false);
  let authToken = 'undefined';
  const queryParameters = useQuery();

  const handleConnected = () => {
    console.log('connected');
    setConnected(true);
  };

  const handleDisconnected = (e) => {
    console.log('disconnected', e);
  };

  if (!!queryParameters.get('auth')) {
    authToken = queryParameters.get('auth');
  }

  return (
    <Box sx={sx}>
      <AmityUiKitProvider
        key={userId}
        apiKey={apiKey}
        userId={userId}
        authToken={authToken}
        apiRegion={ApiRegion.US}
        theme={bbvrTheme}
        onConnected={handleConnected}
        onDisconnected={handleDisconnected}
      >
        {!!connected && <AmityUiKitChat
          type={type}
          defaultChannelId={[userId, secondUserId].sort().join("-")}
          secondUserId={secondUserId}
        />}
      </AmityUiKitProvider>
    </Box>
  );
};

export default Chat;
