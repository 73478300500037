import { Box } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Container from '../../../../../components/Layout/Container';
import { Heading1 } from '../../../../../components/Heading';
import { CustomButton } from '../../../../../components/Buttons';
import VideoPlayerPlaceholder from '../../../../../assets/img/stats/video-player-placeholder.webp';
import { DesktopVideoHeader, DesktopVideoInfo } from './DesktopVideoInfo';
import MobileVideoInfo from './MobileVideoInfo';

const GamePlayVideo = ({ videoData }) => {
  const { username } = useParams();

  const data = videoData && videoData.length > 0 ? videoData[0] : null;
  const video = data?.video_link;

  if (!data || !video || video === 'Not Found') {
    return <></>;
  }

  const trophies = data.trophies_earned;
  const userScore = data.user_score;
  const chalName = data.chal_name;
  const chalScore = data.chal_score;
  const startTime = data.starttime;
  const repCount = data.rep_count;
  const setCount = data.set_count;
  const calories = data.calories;
  const sliceCount = data.slice_count;
  const championsDeployed = data.user_champions_deployed;
  const totalWork = data.total_work;

  return (
    <Box
      sx={{
        background: '#141516',
        mt: { xs: 8, tl: 5, md: 12 },
        px: { xs: 2, tl: 0 },
        pt: 2,
        pb: { xs: 8, tl: 10, md: 16 },
      }}
    >
      <Container>
        <Box sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            tl: "row"
          },
          alignItems: "center",
        }}>
          <Heading1>Latest Gameplay Video</Heading1>
          <Box sx={{
            marginLeft: { xs: 0, tl: "auto" },
            marginRight: { xs: 0, tl: "auto" },
            marginTop: { xs: 1, tl: 0 }
          }}>
            <Link to={`/member/${username}/workouts`}>
              <CustomButton>
                View Older Gameplay Videos
              </CustomButton>
            </Link>
          </Box>
        </Box>
        <DesktopVideoHeader
          trophies={trophies}
          username={username}
          userScore={userScore}
          chalName={chalName}
          chalScore={chalScore}
          matchOutcome={data.match_outcome}
          isRaidBattle={!!data.isRaidBattle}
          challengerReached={data.challenger_reached}
          raidPointsEarned={data.raid_points_earned}
        />
        <Box mt={3} width="100%" sx={{ aspectRatio: 1.8735 }}>
          <ReactPlayer
            url={video}
            playing
            loop
            controls
            light={<img src={VideoPlayerPlaceholder} style={{ width: "100%", height: "100%" }} alt='Thumbnail' />}
            width="100%"
            height="100%"
          />
        </Box>
        <DesktopVideoInfo
          startTime={startTime}
          repCount={repCount}
          setCount={setCount}
          calories={calories}
          sliceCount={sliceCount}
          championsDeployed={championsDeployed}
          totalWork={totalWork}
          videoURL={video}
        />
        <MobileVideoInfo
          trophies={trophies}
          username={username}
          userScore={userScore}
          chalName={chalName}
          chalScore={chalScore}
          startTime={startTime}
          repCount={repCount}
          setCount={setCount}
          calories={calories}
          sliceCount={sliceCount}
          championsDeployed={championsDeployed}
          totalWork={totalWork}
          matchOutcome={data.match_outcome}
          isRaidBattle={!!data.isRaidBattle}
          challengerReached={data.challenger_reached}
          raidPointsEarned={data.raid_points_earned}
          videoURL={video}
        />
      </Container>
    </Box>
  );
};

export default GamePlayVideo;
