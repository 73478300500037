import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import S3Avatar from '../../../../components/S3Avatar';
import { Heading4, Heading5 } from '../../../../components/Heading';

const TrainerCard = ({ data }) => {
  const { image, fullName, username, jobTitle } = data;

  return (
    <Box sx={{ maxWidth: 190, mb: 3 }}>
      <Link to={`/member/${username}`}>
        <Box sx={{ maxWidth: 191, height: 220 }}>
          <S3Avatar src={image} />
        </Box>
      </Link>
      <Heading4 sx={{ mt: 2 }}>{fullName}</Heading4>
      <Link to={`/member/${username}`}>
        <Heading5 sx={{ mt: 1, color: '#ff00ff' }}>{username}</Heading5>
      </Link>
      <Typography sx={{ mt: 1, fontSize: 14 }}>{jobTitle}</Typography>
    </Box>
  );
};

export default TrainerCard;
