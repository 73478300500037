import { Box } from "@mui/material"
import MetaTags from "../../components/MetaTags"
import Loading from "../../components/Loading"
import { useLocation, useParams } from "react-router-dom"
import { useUserDetails } from "../../services/communityService"
import { useEffect, useMemo, useState } from "react"
import FullScreenBox from "../../components/Layout/FullScreenBox"
import { Heading2 } from "../../components/Heading"
import DetailsSection from "../SeasonRecap/Details"
import PersonalRecordsWorkouts from "./Workouts"
import { callBackbonePostApi } from "../../services/backboneApiService"
import FooterMenu from "../../components/Footer/FooterMenu"
import ExerciseDetails from "./ExerciseDetails"
import CallToAction from "./CallToAction"

const PersonalRecords = () => {
  const { username } = useParams();
  const location = useLocation();

  const {
    data: { data: userDetails, isLoading: isLoadingUserInfo },
    error: userInfoError
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = location.state?.user || useUserDetails(username, null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const profileAggregates = userDetails?.results?.profile_aggregates;
  const userProfileInfo = userDetails?.results?.user_profile_info
    ? userDetails.results.user_profile_info[0]
    : {};
  const userId = userDetails?.results?.user_id;
  const isPublicProfile = userProfileInfo.publicProfileAllowed;
  const gymInfo = { id: userProfileInfo.gym_id, name: userProfileInfo.gym_name };
  const userImage = userProfileInfo.profile_pic_url;
  const xpTitle = userDetails?.results?.xp_title ?? '';
  const inviteState = userDetails?.results?.invite_button_state;
  const currentLeague = parseInt(userProfileInfo.league?.N ?? 0);
  const displayTrophyCount = userProfileInfo.trophies;
  const achievements = userDetails?.results?.recent_achievements;

  useEffect(() => {
    if (userInfoError) {
      setError("Member Not Found");
      setIsLoading(false);
    } else if (!isLoadingUserInfo) {
      getPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, isLoadingUserInfo, userInfoError]);

  const getPageData = async () => {
    if (location.state?.records) {
      setData(location.state?.records);
    } else {
      const bestsResponse = await callBackbonePostApi({
        action: "getAllPersonalBestsForAUser",
        userId: userId,
      }, '');
      setData(bestsResponse.data);
    }
    setIsLoading(false);
  }

  const breadcrumbs = useMemo(() => {
    const profileInfo = userDetails?.results?.user_profile_info[0];
    return [
      { title: 'Home', link: 'https://www.blackbox-vr.com' },
      { title: 'Community', link: '/' },
      { title: `${profileInfo?.gym_name} Gym`, link: `/gym/${profileInfo?.gym_id}/${profileInfo?.gym_name}` },
      { title: 'Members', link: '/' },
      { title: profileInfo?.username, link: `/member/${username}` },
      { title: 'Personal Records' }
    ]
  }, [userDetails, username]);

  const exerciseDetailsData = useMemo(() => {
    if ((data?.exerciseDetails || []).filter(item => item.shortCode === 'DA06' || item.shortCode === 'DA00').length === 2) {
      return {
        exerciseDetails: data?.exerciseDetails?.filter(item => item.shortCode !== 'DA00'),
        personalBests: data?.personalBests
      }
    } else {
      return data;
    }
  }, [data]);

  return (<>
    <Box>
      <MetaTags
        title={`Black Box VR - ${userProfileInfo?.username}’s Personal Records`}
        description={`Black Box VR - ${userProfileInfo?.username}’s Personal Records`}
      />
      {isLoading || isLoadingUserInfo ? (
        <Loading fullscreen />
      ) :
        error ? (
          <FullScreenBox>
            <Heading2>{error}</Heading2>
          </FullScreenBox>
        ) : (
          <>
            <DetailsSection
              username={username}
              userImage={userImage}
              userId={userId}
              xpTitle={xpTitle}
              currentLeague={currentLeague}
              displayTrophyCount={displayTrophyCount}
              details={profileAggregates}
              achievements={achievements}
              gymInfo={gymInfo}
              isPublicProfile={isPublicProfile}
              inviteState={inviteState}
              breadcrumbs={breadcrumbs}
            />
            <PersonalRecordsWorkouts
              username={username}
              bests={data?.personalBests}
            />
            <ExerciseDetails data={exerciseDetailsData} />
            <CallToAction username={username} />
            <FooterMenu />
          </>
        )}
    </Box>
  </>);
}

export default PersonalRecords;
