import { Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading1 } from '../../../components/Heading';

const Banner = () => {
  return (
    <Container sx={{ marginTop: 6, marginBottom: 6 }}>
      <Heading1>In the Game of Fitness, We Play to Win Together.</Heading1>
      <Typography sx={{
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 400,
        marginTop: 7,
      }}>
        We’re more than just gym members; we’re allies on a powerful journey. Together, we chase extraordinary fitness, energizing our lives and sharpening our minds. With every workout, we level up for those in our lives who count on us.
      </Typography>
    </Container>
  );
};

export default Banner;
