import { Box } from "@mui/material";
import Tracker from "./Tracker";
import RewardImage from "./RewardImage";

const ResultDiagram = (props) => {

  return (
    <Box mt={8.25} px={8} display="flex" flexDirection={{ xs: "column", xl: "row" }} alignItems="center" gap={{ xs: 2, xl: 14 }}>
      <Tracker data={props.data} width={props.width} height={props.height} />
      <RewardImage />
    </Box>
  );
}

export default ResultDiagram;
