import { Box } from '@mui/material';

import { Heading5 } from '../../../../../components/Heading';

const activeTextStyle = { ml: 4, mb: 2 };
const textStyle = { opacity: 0.5, ml: 4, mb: 2 };

const MobileRankingChart = ({ activeImage, currentLeague }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: { xs: 'flex', tl: 'none' },
        justifyContent: 'center',
        alignItems: 'center',
        mt: 4,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: 'calc(50% + 92px)',
          left: 'calc(50% - 77px)',
          top: 0,
          bottom: 0,
          border: '1px solid #B1B1B1',
          borderRight: 'none',
          zIndex: -1,
        }}
      />
      {activeImage && <img src={activeImage} width={164} height={164} alt="Diamond" />}
      <Box>
        <Box py={3}>
          <Box sx={currentLeague === 1 ? activeTextStyle : textStyle}>
            <Heading5>Bronze</Heading5>
          </Box>
          <Box sx={currentLeague === 2 ? activeTextStyle : textStyle}>
            <Heading5>Silver</Heading5>
          </Box>
          <Box sx={currentLeague === 3 ? activeTextStyle : textStyle}>
            <Heading5>Gold</Heading5>
          </Box>
          <Box sx={currentLeague === 4 ? activeTextStyle : textStyle}>
            <Heading5>Platinum</Heading5>
          </Box>
          <Box sx={currentLeague >= 5 && currentLeague <= 9 ? activeTextStyle : textStyle}>
            <Heading5>Diamond I - V</Heading5>
          </Box>
          <Box sx={currentLeague >= 10 && currentLeague <= 14 ? activeTextStyle : textStyle}>
            <Heading5>Championship I - V</Heading5>
          </Box>
          <Box sx={currentLeague >= 15 ? activeTextStyle : textStyle}>
            <Heading5>Pro I - V</Heading5>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileRankingChart;
