import { Box } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading4 } from '../../../components/Heading';

const LocationSelector = ({
  list,
  selected,
  onChange,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        background: '#262626',
        height: "114px",
        paddingLeft: { xs: 1, tl: 0 },
        paddingRight: { xs: 1, tl: 0 },
      }}
    >
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Heading4>CHOOSE LOCATION:</Heading4>
          {list.map((item, index) => (
            <Box
              key={item.id}
              onClick={() => onChange(item.id)}
              sx={{
                cursor: "pointer",
                textAlign: "center",
                borderRight: index === list.length - 1 ? "0px" : "2px #B1B1B1 solid",
                paddingLeft: {
                  xs: 1,
                  tl: 3,
                },
                paddingRight: {
                  xs: 1,
                  tl: 3,
                },
                color: item.id === selected ? "#FFFFFF" : "#0076FF",
              }}
            >
              <Heading4 sx={{ fontWeight: 400 }}>{item.title}</Heading4>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default LocationSelector;
