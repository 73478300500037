import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { isSafari } from 'react-device-detect';

import { formatCompactNumber } from '../../../utils';

import { Heading4 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';
import S3Avatar from '../../../components/S3Avatar';
import CaptainMark from '../../../components/CaptainMark';
import { CustomButton } from '../../../components/Buttons';
import SummaryCell from '../../../components/SummaryCell';

const columns = [
  {
    id: 'image',
    label: 'Name',
    width: { xs: 72, lg: 128 },
    format: (value, isCaptain) => (
      <Link to={`/member/${value?.name}`}>
        <Box
          sx={{
            position: 'relative',
            width: { xs: 64, lg: 96 },
            height: { xs: 64, lg: 96 },
            mr: { xs: 0, lg: 4 },
            ' img': { width: '100%', height: '100%', objectFit: 'cover' },
          }}
        >
          <S3Avatar src={value.url} />
          {isCaptain && <CaptainMark />}
        </Box>
      </Link>
    ),
  },
  {
    id: 'title',
    label: '',
    minWidth: '12vw',
    format: (value) => (
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Link to={`/member/${value?.name}`}>
          <Heading4 sx={{ fontSize: { xs: 16, lg: 24 } }}>{value?.name}</Heading4>
        </Link>
        {value.desc && (
          <Typography mt={{ xs: 1, lg: 2 }} sx={{ fontSize: { xs: 14, lg: 16 } }}>
            {value.desc}
          </Typography>
        )}
      </Box>
    ),
  },
  {
    id: 'raidPoints',
    label: 'Raid Points',
    labelDescription:
      'Raid Points are a scoring mechanism used during Arena Raids, a competitive team event within the game. During these raids, you and your team battle against NPC Challengers, and the difficulty of the challengers increases as you defeat each one. The higher the Challenger you defeat, the higher your Raid Points score! These points contribute to your team’s total score for the event, influencing your rank on the leaderboard and the rewards you receive​​.',
    format: (value) => (
      <Box display="flex" alignItems="center">
        <LocalImage
          src={`img/team/team-raid-points.webp`}
          alt="raid points"
          width={32}
          height={32}
        />
        <Heading4 sx={{ ml: 2 }}>{formatCompactNumber(value)}</Heading4>
      </Box>
    ),
  },
  {
    id: 'challengerReached',
    label: 'Challenger Reached',
    labelDescription:
      'During Arena Raids, you and your team battle against NPC Challengers, and the difficulty of the challengers increases as you defeat each one. The higher the Challenger you defeat, the higher your Raid Points score!',
    format: (value) => <Heading4>{formatCompactNumber(value)}</Heading4>,
  },
  {
    id: 'battles',
    label: 'Raid Battles',
    labelDescription:
      'During Arena Raids, you and your team can do up to three Arena Raid battles. Each one starts where the previous one ended. Can you beat your previous personal best?',
    format: (value) => <Heading4>{formatCompactNumber(value)}</Heading4>,
  },
  {
    id: 'arrow',
    lable: '',
    width: 50,
    align: 'right',
    format: (value) => (
      <Link to={`/member/${value}`}>
        {' '}
        <ArrowForwardIcon className="action" sx={{ fontSize: { xs: 24, lg: 48 } }} />
      </Link>
    ),
  },
];

const MembersTable = ({ members }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <TableContainer>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell key="empty-hd-1" className="empty" />
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{ width: column.width, minWidth: column.minWidth, '& p': { fontSize: 16 } }}
              >
                <SummaryCell
                  label={column.label}
                  description={column.labelDescription}
                  isQuestion={!!column.labelDescription}
                  toolipSize="large"
                  textAlign="flex-start"
                  noMargin
                />
              </TableCell>
            ))}
            <TableCell key="empty-hd-2" className="empty" />
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((row, index) => {
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
                className={index === 0 ? 'captain' : ''}
              >
                <TableCell key="empty-1" className="empty" />
                {columns.map((column) => {
                  const value = column.id !== 'arrow' ? row[column.id] : row?.title?.name;

                  if (index === 0 || isSmallScreen) {
                    if (column.id === 'image') {
                      return (
                        <TableCell key={column.id} colSpan="2">
                          <Box
                            display="flex"
                            alignItems={{ xs: 'flex-start', lg: 'center' }}
                            flexDirection={{ xs: 'column', lg: 'row' }}
                          >
                            {column.format ? column.format(value, index === 0) : value}
                            {columns[1].format(row[columns[1].id])}
                          </Box>
                        </TableCell>
                      );
                    }
                    if (column.id !== 'title') {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    }
                  } else {
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  }

                  return null;
                })}
                <TableCell key="empty-2" className="empty" />
              </TableRow>
            );
          })}
          {Array.from(Array(12 - members.length).keys()).map((_, index) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={`available-${index}`}>
              <TableCell className="empty" />
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: 64, lg: 96 },
                    height: { xs: 64, lg: 96 },
                    border: '1px solid #313131',
                  }}
                >
                  <Typography
                    fontSize={12}
                    sx={{ maxWidth: 60, textAlign: 'center', lineHeight: '20px' }}
                  >
                    Spot Available
                  </Typography>
                </Box>
              </TableCell>
              <TableCell colSpan="5">
                <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                  <Heading4 sx={{ mr: { xs: 8, lg: 0 } }}>Available</Heading4>
                  <CustomButton
                    className="action"
                    sx={{ position: 'absolute', right: 0, display: { xs: 'none', lg: 'block' } }}
                  >
                    Request to Join
                  </CustomButton>
                  <CustomButton
                    className="action"
                    sx={{
                      position: 'absolute',
                      right: 0,
                      height: 27,
                      padding: '4px 16px',
                      lineHeight: '20px',
                      display: { xs: 'block', lg: 'none !important' },
                    }}
                  >
                    Join
                  </CustomButton>
                </Box>
              </TableCell>
              <TableCell className="empty" />
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default MembersTable;

const StyledTable = styled(Table)((props) => ({
  '& .MuiTableRow-root': {
    '& td': {
      cursor: 'auto',

      '&:nth-of-type(2) > div > a > div': {
        marginBottom: 16,
      },
    },

    '&.captain': {
      background: !isSafari
        ? 'linear-gradient(90deg, rgba(187, 10, 148, 0.50) 0%, rgba(147, 4, 115, 0.45) 64.69%, rgba(148, 1, 115, 0.15) 88.66%)'
        : 'rgba(187, 10, 148, 0.50) !important',
      backdropFilter: 'blur(12px)',

      '& td': {
        border: 0,
      },
      '& td:nth-of-type(2)': {
        '& > div > a > div': {
          width: 128,
          height: 128,
          marginBottom: 24,

          [props.theme.breakpoints.up('lg')]: {
            width: 152,
            height: 152,
            marginBottom: 0,
          },

          '& img': {
            border: '1px solid #0099CD',
          },
        },
      },
    },

    '& .MuiTableCell-root': {
      background: 'transparent',
      color: '#FFF',
      fontSize: 16,
      borderTop: '1px solid #B1B1B1',
      borderBottom: 0,
      padding: props.theme.spacing(2, 1),

      '&.empty': {
        border: 0,
        padding: 0,
        width: 'calc(50vw - 625px)',
        minWidth: '7vw',
        pointerEvents: 'none',
        cursor: 'auto',
      },

      [props.theme.breakpoints.up('lg')]: {
        '& .action': {
          display: 'none',
        },
      },
    },
    '& th.MuiTableCell-root': {
      padding: props.theme.spacing(3, 1),
    },
    '& td:nth-of-type(2)': {
      [props.theme.breakpoints.up('lg')]: {
        border: 0,
      },
    },

    [props.theme.breakpoints.up('lg')]: {
      '&:hover': {
        '& .action': {
          display: 'inline-flex',
        },
      },
    },
  },

  '& tr:nth-of-type(2)': {
    '& td': {
      border: '0 !important',
    },
  },
}));
