import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { getUser } from '../../utils/cookie';
import Chat from '../../components/Chat';
import FooterMenu from '../../components/Footer/FooterMenu';
import Container from '../../components/Layout/Container';

const ChatPage = () => {
  const { userId } = useParams();
  const loggedinUser = getUser();

  return (
    <>
      <Container>
        <Box sx={{ height: "500px" }}>
          <Chat
            type="member"
            userId={loggedinUser?.userId}
            secondUserId={userId}
            sx={{ mt: { xs: 10, md: 16 } }}
          />
        </Box>
      </Container>
      <FooterMenu />
    </>
  );
};

export default ChatPage;
