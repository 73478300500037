import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { callBackbonePostApi } from '../../../services/backboneApiService';

import MetaTags from '../../../components/MetaTags';
import { Heading2 } from '../../../components/Heading';
import StyledInput from '../../../components/Form/StyledInput';
import { CustomButton } from '../../../components/Buttons';
import FooterMenu from '../../../components/Footer/FooterMenu';

const ForgotPassword = () => {
  const { search } = useLocation();
  const isInvalidError = new URLSearchParams(search).get('error');

  const [error, setError] = useState();
  const [email, setEmail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const onSubmit = () => {
    if (!email) {
      setError('Please enter your username or email address.');
    } else {
      setIsLoading(true);

      const payload = {
        action: 'startPasswordRecovery',
        userNameOrEmail: email,
      };

      callBackbonePostApi(payload)
        .then(() => {
          setIsLoading(false);

          setIsSubmit(true);
        })
        .catch(() => {
          setIsLoading(false);

          enqueueSnackbar('Submit failed! Please try later again.', {
            variant: 'error',
          });
        });
    }
  };

  return (
    <Box>
      <MetaTags title="Black Box VR - Forgot Password!" />
      <Box position="relative" display="flex" justifyContent="center" mb={28}>
        <Box maxWidth={618} width="100%" mt={8} px={2}>
          <Heading2>RESET YOUR PASSWORD</Heading2>

          {!isSubmit ? (
            // ------- Forgot Password Form
            <Box>
              {isInvalidError ? (
                <Typography mt={2} sx={{ '& strong': { color: '#FF370B' } }}>
                  <strong>ERROR!</strong> The password reset link is not valid or has expired.
                  Please request a new link.
                </Typography>
              ) : (
                <Typography mt={2}>Forgot your password? Reset it here!</Typography>
              )}
              <Box component="form" noValidate mt={4}>
                <StyledInput
                  label="Black Box VR username or email address:"
                  type="text"
                  sx={{ mb: 3 }}
                  onInput={(e) => {
                    setEmail(e.target.value);
                    setError('');
                  }}
                />
                {error && (
                  <Box>
                    <Typography sx={{ color: '#FF370B', pt: 1, pb: 2 }}>{error}</Typography>
                  </Box>
                )}
                <CustomButton
                  disabled={!!error}
                  loading={isLoading}
                  type="submit"
                  onClick={onSubmit}
                >
                  Reset Password
                </CustomButton>
              </Box>
            </Box>
          ) : (
            // ------- Success page
            <Box mb={3}>
              <Typography mt={2}>
                If there is an account with that information, you will receive an email with
                instructions for resetting your password.
              </Typography>
              <Box mt={3}>
                <Link to="/signin">
                  <Typography component="span" color="#0076FF">
                    Log In To Your Account
                  </Typography>
                </Link>
              </Box>
            </Box>
          )}

          <Typography mt={2}>
            Having trouble logging in? Contact us at support@blackbox-vr.com.
          </Typography>
        </Box>
      </Box>
      <FooterMenu />
    </Box>
  );
};

export default ForgotPassword;
