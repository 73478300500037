import { Box } from '@mui/material';

const Container = ({ children, sx }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: { xs: '100%', tl: '86vw' },
        maxWidth: '1244px',
        margin: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default Container;
