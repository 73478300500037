import { Box } from '@mui/material';

import FooterBanner from '../../../../components/Footer/FooterBanner';
import FooterMenu from '../../../../components/Footer/FooterMenu';

const GymProfileFooter = () => {
  return (
    <Box>
      <FooterBanner />
      <Box mt={{ xs: 8, tl: 10, md: 16 }}>
        <FooterMenu />
      </Box>
    </Box>
  );
};

export default GymProfileFooter;
