import { Box } from '@mui/material';

import Container from '../../../components/Layout/Container';
import LocalImage from '../../../components/LocalImage';
import CustomBreadcrumbs from '../../../components/Breadcrumbs';
import { Heading1, Heading4 } from '../../../components/Heading';
import useQuery from '../../../utils/useQuery';

const TopBanner = ({
  title,
  subTitle,
  breadcrumbs,
}) => {
  const queryParameters = useQuery();
  const isTv = queryParameters.get('tv') === "true";

  return (
    <Box
      sx={{
        background: '#141516',
        display: 'flex',
        alignItems: 'center',
        minHeight: { xs: 360, tl: 296 },
      }}
    >
      <Container>
        {!!breadcrumbs && !isTv && <Box sx={{ marginBottom: 2 }}>
          <CustomBreadcrumbs items={breadcrumbs} />
        </Box>}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: { xs: 'column', tl: 'row' },
            justifyContent: (isTv ? 'center' : 'left'),
          }}
        >
          <Box
            sx={{
              width: {
                xs: 95,
                tl: 190,
              },
              height: {
                xs: 95,
                tl: 190,
              }
            }}
          >
            <LocalImage
              // src={`img/logo-mark.png`}
              src={`img/new-logo-mark.png`}
              width="100%"
              height="100%"
              alt=""
              loading="lazy"
            />
          </Box>
          <Box
            width={{ xs: 'calc(100% - 16px)', tl: 'auto' }}
            ml={{ xs: 2, tl: 3, lg: 6 }}
            mt={{ xs: 4, tl: 0 }}
            textAlign={{ xs: "center", tl: "left" }}
          >
            <Heading4 sx={{ fontSize: 24 }}>{title}</Heading4>
            <Heading1 sx={{ fontSize: { xs: 40, md: 60, xl: 60 }, mt: 2 }}>{subTitle}</Heading1>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default TopBanner;
