import { Box, Grid, Typography } from '@mui/material';

import { formatCompactNumber, getRelativeTime } from '../../../../../utils';

import BadgeFrame from './BadgeFrame';
import Container from '../../../../../components/Layout/Container';

import BackgroundImageLg from '../../../../../assets/img/stats/bg.webp';
import BackgroundImageMd from '../../../../../assets/img/stats/bg.webp';
import BackgroundImageSm from '../../../../../assets/img/stats/bg.webp';
import HeroLevelImage from '../../../../../assets/img/stats/badges/hero-level.webp';
import ArenaImage from '../../../../../assets/img/stats/badges/arena.webp';
import StreakImage from '../../../../../assets/img/stats/badges/streak.webp';
import TrophiesImage from '../../../../../assets/img/stats/badges/trophies.webp';
import SessionTimeImage from '../../../../../assets/img/stats/badges/season-time.webp';

const StatsBoard = ({ stats }) => {
  const { heroLevel, arena, streak, trophies, seasonEndDate } = stats;

  return (
    <Box
      sx={{
        backgroundImage: {
          xs: `url(${BackgroundImageSm})`,
          tl: `url(${BackgroundImageMd})`,
          md: `url(${BackgroundImageLg})`,
        },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: { xs: 548, tl: 328, md: '43vw' },
        minHeight: { md: '550px' },
        maxHeight: '826px',
        marginTop: { xs: 4, md: -2 },
      }}
    >
      <Container>
        <Grid
          container
          mt={0}
          mx={{ xs: 'auto', md: '-16px' }}
          width={{ xs: 326, tl: 'auto' }}
          maxWidth={829}
          rowSpacing={{ xs: 3, tl: 4 }}
          columnSpacing={2}
          sx={{
            '& > div': {
              maxWidth: { xs: 'fit-content', md: '50%' },
            },
          }}
        >
          <Grid item xs={6}>
            <BadgeFrame
              background={HeroLevelImage}
              mdBackground={HeroLevelImage}
              label="Hero Level"
              value={Math.floor(heroLevel)}
            />
          </Grid>
          <Grid item xs={6}>
            <BadgeFrame
              background={ArenaImage}
              mdBackground={ArenaImage}
              label="ARENA"
              value={arena}
            />
          </Grid>
          <Grid item xs={6}>
            <BadgeFrame
              background={StreakImage}
              mdBackground={StreakImage}
              label="Streak"
              value={streak}
            />
          </Grid>
          <Grid item xs={6}>
            <BadgeFrame
              background={TrophiesImage}
              mdBackground={TrophiesImage}
              label="Trophies"
              value={formatCompactNumber(trophies)}
            />
            <Box
              sx={{
                background: `url(${SessionTimeImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: 'fit-content',
                minWidth: 260,
                height: 32,
                display: 'flex',
                alignItems: 'center',
                ml: '44px',
              }}
            >
              <Typography mx={3} sx={{ fontSize: 14, color: "#000000" }}>
                Season ends {getRelativeTime(seasonEndDate)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default StatsBoard;
