import { Box } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import MetaTags from '../../components/MetaTags';
// import Loading from '../../components/Loading';
import Footer from '../../components/Footer/FooterMenu';
// import { Heading2 } from '../../components/Heading';
// import FullScreenBox from '../../components/Layout/FullScreenBox';
import Container from '../../components/Layout/Container';
import DetailsSection from '../SeasonRecap/Details';

import { useUserDetails } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';
import YourWeekRecap from './WeekRecap';
import PerformanceAnalysis from './PerformanceAnalysis';
import ScoreCard from './ScoreCard';
import WorkoutCharts from './WorkoutCharts';
import ExerciseDetails from './ExerciseDetails';
import AIExerciseAnalysis from './AIExerciseAnalysis';
import SeasonProgress from './SeasonProgress';
import FullScreenBox from '../../components/Layout/FullScreenBox';
import { Heading2 } from '../../components/Heading';
import Loading from '../../components/Loading';
import CallToAction from './CallToAction';
import moment from 'moment';

const WeekRecap = () => {
  const { username, date } = useParams();
  const {
    data: { data: userDetails, isLoading: isLoadingUserInfo },
    error: userInfoError
  } = useUserDetails(username, null);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userInfoError) {
      setError("Member Not Found");
      setIsLoading(false);
    } else if (!isLoadingUserInfo) {
      const dateIsValid = moment(date, 'YYYY-MM-DD').isValid();
      if (dateIsValid) {
        getUserWeeklyRecap(userDetails, date);
      } else {
        setError("Invalid Date");
        setIsLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, isLoadingUserInfo, userInfoError]);

  const getUserWeeklyRecap = async (userInfo, date) => {
    setError(null);
    setIsLoading(true);
    try {
      const weeklyRecapResponse = await callBackbonePostApi({
        action: "getUserWeeklyRecap",
        userId: userInfo.results.user_id,
        startDate: date
      }, '');
      setData(weeklyRecapResponse.data);
    }
    catch (e) {
      setError("Data Not Found");
    }
    setIsLoading(false);
  }

  const profileAggregates = userDetails?.results?.profile_aggregates;
  const userProfileInfo = userDetails?.results?.user_profile_info
    ? userDetails.results.user_profile_info[0]
    : {};
  const userId = userDetails?.results?.user_id;
  const isPublicProfile = userProfileInfo.publicProfileAllowed;
  const gymInfo = { id: userProfileInfo.gym_id, name: userProfileInfo.gym_name };
  const userImage = userProfileInfo.profile_pic_url;
  const xpTitle = userDetails?.results?.xp_title ?? '';
  const inviteState = userDetails?.results?.invite_button_state;
  const currentLeague = parseInt(userProfileInfo.league?.N ?? 0);
  const displayTrophyCount = userProfileInfo.trophies;
  const achievements = userDetails?.results?.recent_achievements;
  const volumeOverTime = data?.historicalCharts?.volume_per_week || {};
  const workOverTime = data?.historicalCharts?.work_per_week || {};
  const repsPerWeek = data?.historicalCharts?.reps_per_week || {};
  const setsPerWeek = data?.historicalCharts?.sets_per_week || {};

  const breadcrumbs = useMemo(() => {
    const profileInfo = userDetails?.results?.user_profile_info[0];
    return [
      { title: 'Home', link: 'https://www.blackbox-vr.com' },
      { title: 'Community', link: '/' },
      { title: `${profileInfo?.gym_name} Gym`, link: `/gym/${profileInfo?.gym_id}/${profileInfo?.gym_name}` },
      { title: 'Members', link: '/' },
      { title: profileInfo?.username, link: `/member/${username}` },
      { title: 'Recaps' },
      { title: `Week Recap: ${moment(data?.currentWeek?.startDate).format('ll')}` }
    ]
  }, [userDetails, data, username]);

  return (<>
    {error ? (
      <FullScreenBox>
        <Heading2>{error}</Heading2>
      </FullScreenBox>
    ) : (
      <Box>
        <MetaTags
          // title={`Black Box VR - ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}'s Week ${date} Recap!`}
          title='Black Box VR | Weekly Recap'
          description='Black Box VR | Weekly Recap'
        // description={`Black Box VR - ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}'s Week ${date} Recap!`}
        />
        {isLoading || isLoadingUserInfo ? (
          <Loading fullscreen />
        ) : (
          <>
            <DetailsSection
              username={username}
              userImage={userImage}
              userId={userId}
              xpTitle={xpTitle}
              currentLeague={currentLeague}
              displayTrophyCount={displayTrophyCount}
              details={profileAggregates}
              achievements={achievements}
              gymInfo={gymInfo}
              isPublicProfile={isPublicProfile}
              inviteState={inviteState}
              breadcrumbs={breadcrumbs}
            />
            <YourWeekRecap
              data={data}
            />
            <PerformanceAnalysis
              data={data}
            />
            <ScoreCard
              data={data}
              username={username}
            />
            <Container>
              <WorkoutCharts
                endDate={data?.currentWeek?.endDate}
                averageVolume={data?.currentWeek?.weeklyRecap?.avgVolumePerWorkout}
                averageWork={data?.currentWeek?.weeklyRecap?.avgWorkPerWorkout}
                averageSets={data?.currentWeek?.weeklyRecap?.avgSetsPerWorkout}
                averageReps={data?.currentWeek?.weeklyRecap?.avgRepsPerWorkout}
                averageWorkout={data?.currentWeek?.weeklyRecap?.completedWorkouts}
                volumeOverTime={volumeOverTime}
                workOverTime={workOverTime}
                repsPerWeek={repsPerWeek}
                setsPerWeek={setsPerWeek}
                workoutPerWeek={data?.historicalCharts?.workouts_per_week || {}}
              />
              <ExerciseDetails data={data} />
            </Container>
            <AIExerciseAnalysis
              data={data}
            />
            <SeasonProgress
              data={data}
            />
            <CallToAction username={username} />
          </>
        )}
        <Footer />
      </Box>
    )}
  </>
  );
};

export default WeekRecap;
