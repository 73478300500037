import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';

import useQuery from '../../utils/useQuery';

import MetaTags from '../../components/MetaTags';
import Container from '../../components/Layout/Container';
import { Heading2, Heading4 } from '../../components/Heading';
import Loading from '../../components/Loading';
import FooterMenu from '../../components/Footer/FooterMenu';
import TopBanner from '../Leaderboards/TopBanner';
import Tab from '../Leaderboards/Tab';
import LocationSelector from './LocationSelector';

import LeaderboardBg from '../../assets/img/leaderboards/bg.png';
import { callBackbonePostApi } from '../../services/backboneApiService';
import {
  LEADERBOARD_PERIODS,
  LEADERBOARD_LOCATIONS,
  LEADERBOARD_ACTIONS,
} from '../../config/constants';

const MainLeaderBoards = () => {
  const navigate = useNavigate();
  const queryParameters = useQuery();
  let location = queryParameters.get('location') || null;
  const userId = queryParameters.get('userId') || queryParameters.get('userid') || "";
  const [isLoading, setIsLoading] = useState(true);
  const [leaderboardData, setLeaderboardData] = useState([]);

  useEffect(() => {
    callBackbonePostApi({
      action: "getAllLeaderboardPeriods",
    }, '')
    .then((data) => {
      setIsLoading(false);

      if (data?.status === 200 && data?.data) {
        setLeaderboardData(data?.data);
      }
    })
    .catch(() => {
      setIsLoading(true);
    });
  }, []);

  if (!!location && !LEADERBOARD_LOCATIONS[location]) {
    location = null;
  }

  const breadcrumbs = [
    { title: 'Home', link: 'https://www.blackbox-vr.com' },
    { title: 'Community', link: '/' },
  ];
  if (location) {
    breadcrumbs.push({ title: `${LEADERBOARD_LOCATIONS[location].title} Gym`, link : `/gym/${LEADERBOARD_LOCATIONS[location].id}/${LEADERBOARD_LOCATIONS[location].title}` });
  }
  breadcrumbs.push({ title: "Leaderboards" });

  const handleChangePeriod = (category, newPeriod) => {
    navigate(`/leaderboards?category=${category}&location=${location || ""}&period=${newPeriod}&userId=${userId || ""}`);
  }

  const handleChangeLocation = (newLocation) => {
    navigate(`/main-leaderboards?location=${newLocation || ""}&userId=${userId || ""}`);
  }

  const locationList = [{
    id: '',
    title: 'ALL GYMS',
  }];
  Object.keys(LEADERBOARD_LOCATIONS).forEach(item => {
    locationList.push({
      id: item,
      title: LEADERBOARD_LOCATIONS[item].title,
    });
  });

  return (
    <Box>
      <MetaTags title={`Black Box VR - Leaderboards`} />
      <TopBanner
        title={!location ? "Black Box VR Community" : `${LEADERBOARD_LOCATIONS[location].title} Location`}
        subTitle={!location ? "Leaderboards" : "Gym Leaderboards"}
        breadcrumbs={breadcrumbs}
      />
      <Box
        sx={{
          height: {
            xs: 300,
            tl: 592,
          },
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${LeaderboardBg})`,
        }}
      >
      </Box>
      <LocationSelector
        selected={location || ""}
        list={locationList}
        onChange={handleChangeLocation}
      />
      <Container>
        {!!isLoading && <Box sx={{
          margin: 8,
        }}>
          <Loading />
        </Box>}
        {!isLoading && <Box
          sx={{
            marginLeft: { xs: 1, tl: 0 },
            marginRight: { xs: 1, tl: 0 },
            marginBottom: 10,
          }}
        >
          <Heading2 sx={{ marginTop: 5 }}>Individuals{!!location && ` - ${LEADERBOARD_LOCATIONS[location].title}`}</Heading2>
          {leaderboardData.filter(item => item.category === "individual").filter(item => item.periods && item.periods.length > 0).map(data => {
            const itemPeriods = [];
            let selectedAction = "";
            Object.keys(LEADERBOARD_PERIODS).forEach(item => {
              const leaderboard = (leaderboardData.find(subItem => subItem.name === data.name)?.periods || []);
              if (leaderboard.indexOf(LEADERBOARD_PERIODS[item].id) > -1) {
                itemPeriods.push({
                  id: item,
                  title: LEADERBOARD_PERIODS[item].title
                });
              }
            });
            Object.keys(LEADERBOARD_ACTIONS).forEach(item => {
              if (LEADERBOARD_ACTIONS[item]?.id === data.name) {
                selectedAction = item;
              }
            });
            return (
              <Box key={data.name}>
                <Heading4 sx={{ marginTop: 3 }}>{data.displayName}</Heading4>
                <Tab
                  mt={2}
                  list={itemPeriods}
                  selected=""
                  onChange={(newPeriod) => handleChangePeriod(selectedAction, newPeriod)}
                />
              </Box>
            );
          })}
          <Heading2 sx={{ marginTop: 5 }}>Teams{!!location && ` - ${LEADERBOARD_LOCATIONS[location].title}`}</Heading2>
          {leaderboardData.filter(item => item.category === "teams").filter(item => item.periods && item.periods.length > 0).map(data => {
            const itemPeriods = [];
            let selectedAction = "";
            Object.keys(LEADERBOARD_PERIODS).forEach(item => {
              const leaderboard = (leaderboardData.find(subItem => subItem.name === data.name)?.periods || []);
              if (leaderboard.indexOf(LEADERBOARD_PERIODS[item].id) > -1) {
                itemPeriods.push({
                  id: item,
                  title: LEADERBOARD_PERIODS[item].title
                });
              }
            });
            Object.keys(LEADERBOARD_ACTIONS).forEach(item => {
              if (LEADERBOARD_ACTIONS[item]?.id === data.name) {
                selectedAction = item;
              }
            });
            return (
              <Box key={data.name}>
                <Heading4 sx={{ marginTop: 3 }}>{data.displayName}</Heading4>
                <Tab
                  mt={2}
                  list={itemPeriods}
                  selected=""
                  onChange={(newPeriod) => handleChangePeriod(selectedAction, newPeriod)}
                />
              </Box>
            );
          })}
        </Box>}
      </Container>
      <FooterMenu />
    </Box>
  );
};

export default MainLeaderBoards;
