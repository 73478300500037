import { Box, Typography } from '@mui/material';
import Markdown from 'react-markdown'

import Container from '../../../components/Layout/Container';
import { Heading2, Heading3 } from '../../../components/Heading';
import SummaryCell from '../../../components/SummaryCell';
import LocalImage from '../../../components/LocalImage';
import ExerciseBg from '../../../assets/img/seasons/exercise-total.webp';
import TooltipBg from '../../../assets/img/week/tooltip.webp';
import StarIcon from '../../../assets/img/week/star.webp';

import { formatCompactNumber } from '../../../utils';

const PerformanceAnalysis = ({
  data
}) => {
  return (
    <Container sx={{ marginTop: 8 }}>
      <Heading2>AI PERFORMANCE ANALYSIS</Heading2>
      <Box sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: {
          xs: "column",
          tl: "row",
        },
        justifyContent: "space-between",
      }}>
        <Box sx={{
          marginRight: {
            xs: 0,
            tl: 7
          },
          marginBottom: {
            xs: 2,
            tl: 0
          }
        }}>
          <Box sx={{
            float: {
              xs: "none",
              tl: "right",
            },
            marginLeft: {
              xs: "auto",
              tl: 4
            },
            textAlign: "center",
          }}>
            <Box sx={{
              background: `url(${TooltipBg})`,
              width: "264px",
              height: "110px",
              margin: "auto",
            }}>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "26px",
                padding: "18px 25px",
              }}>
                Great week! Let’s take a look at the details together.
              </Typography>
            </Box>
            <LocalImage src="img/week/no-cage.webp" width="180" height="180" alt="no-cage" loading="lazy" />
          </Box>
          {
            (data?.currentWeek?.weeklyRecap?.general_recap_response || "").split("\n").map((item, idx) => (
              <Box key={`description-${idx}`} mb={3} sx={{
                fontSize: "16px",
                lineHeight: "26px",
              }}>
                <Markdown>{item}</Markdown>
              </Box>
            ))
          }
        </Box>
        <Box>
          <Box sx={{
            width: {
              xs: "auto",
              tl: "461px",
            },
            padding: "22px 23px 46px 23px",
            background: `url(${ExerciseBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            textAlign: "center"
          }}>
            <Heading3>EXERCISE TOTALS</Heading3>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: 2,
              marginBottom: 5,
            }}>
              Unlocking your potential, one VR workout at a time.
            </Typography>
            <Box mb={3}>
              <SummaryCell
                label="Workouts Completed"
                value={formatCompactNumber(data?.currentWeek?.weeklyRecap?.completedWorkouts)}
                isLarge
                isStar={!!data?.currentWeek?.weeklyRecap?.completedWorkoutsIsPersonalBest}
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Sets | Reps"
                value={`${formatCompactNumber(data?.currentWeek?.weeklyRecap?.totalSets)}|${formatCompactNumber(data?.currentWeek?.weeklyRecap?.totalReps)}`}
                description='A “set” in strength training refers to a specific number of consecutive repetitions of an exercise performed without rest.<br />A “rep” or repetition is one complete motion of a particular exercise, from the starting position back to the starting position again.'
                isQuestion
                isLarge
                isStar={!!data?.currentWeek?.weeklyRecap?.totalSetsIsPersonalBest || !!data?.currentWeek?.weeklyRecap?.totalVolumeIsPersonalBest}
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Volume (Lbs.) "
                value={formatCompactNumber(data?.currentWeek?.weeklyRecap?.totalVolume)}
                description="In strength training, “volume” refers to the total amount of weight lifted, commonly calculated as the number of sets multiplied by the number of repetitions per set, further multiplied by the weight used for each exercise. <br/>Example set: 100 lbs. x 10 reps = 1000 lbs. of volume"
                isQuestion
                isLarge
                isStar={!!data?.currentWeek?.weeklyRecap?.totalVolumeIsPersonalBest}
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Work (Ft x Lbs.)"
                value={formatCompactNumber(data?.currentWeek?.weeklyRecap?.totalWork)}
                description="Work in foot-pounds (ft.*lbs) is a measure of the energy expended in lifting a weight over a certain distance. It is calculated by multiplying the force exerted on the weight (measured in pounds) by the distance the weight is moved (measured in feet). <br/>Unlike volume, work also takes into account the rep length."
                isQuestion
                isLarge
                isStar={!!data?.currentWeek?.weeklyRecap?.totalWorkIsPersonalBest}
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Calories Burned"
                value={formatCompactNumber(data?.currentWeek?.weeklyRecap?.totalCaloriesBurned)}
                isLarge
                isStar={!!data?.currentWeek?.weeklyRecap?.totalCaloriesBurnedIsPersonalBest}
              />
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
          }}>
            <img
              src={StarIcon}
              alt="Star"
              width="24px"
              height="24px"
            />
            <Typography sx={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: 700,
              marginLeft: 1,
            }}>
              = New Personal Record!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default PerformanceAnalysis;
