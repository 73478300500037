import { Box, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading1 } from '../../../components/Heading';
import Rewards from './Rewards';
import Progress from './Progress';

const SeasonProgress = ({
  data,
}) => {
  return (
    <Container sx={{
      marginTop: 6
    }}>
      <Heading1>SEASON {data?.currentWeek?.weeklyRecap?.seasonXpProgress?.current_season || 1} XP PROGRESS</Heading1>
      <Typography sx={{
        fontSize: "16px",
        lineHeight: "26px",
        width: { xs: '100%', tl: '70%' }
      }}>
        Earn Season XP by completing workout quests. Strive to achieve 100% and earn the top reward! This shows your consistency with your workouts, which is the true secret to fitness success.
      </Typography>
      <Box sx={{
        display: "flex",
        flexDirection: { xs: 'column', tl: 'row' },
        marginTop: 5,
        marginBottom: 10,
        width: '100%',
        alignItems: 'center',
        gap: { xs: 2, tl: 0 }
      }}>
        <Progress data={data} />
        <Rewards data={data} />
      </Box>
    </Container>
  );
};

export default SeasonProgress;
