import { Box, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Container from '../../../components/Layout/Container';
import LocalImage from '../../../components/LocalImage';
import { CustomButton } from '../../../components/Buttons';
import { Heading1, Heading2, Heading3, Heading4 } from '../../../components/Heading';
import { HtmlTooltip, TooltipContent } from '../../../components/Tooltip';
import QuestionIcon from '../../../assets/img/stats/question-icon.png';

const TeamProfileSeasonInfo = ({
  data,
  recapData,
  teamname,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box mt={6}>
      {data?.team_raid_points > 0 && <Container>
        <Box sx={{
          display: "flex",
          justifyContent: "space-between"
        }}>
          <Heading2>Season {recapData?.season} Totals</Heading2>
          <Box sx={{
            textAlign: "center",
          }}>
            <CustomButton href="/seasons">
              View Season Details
            </CustomButton>
          </Box>
        </Box>
        <Box sx={{
          backgroundColor: "#141516",
          marginTop: 4,
          padding: {
            xs: 2,
            tl: 4,
          },
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              tl: "row",
            },
            justifyContent: "space-between"
          }}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Box sx={{
                display: "flex",
              }}>
                <LocalImage src="img/team/team-raid-points.webp" alt="Team Raid Points" loading="lazy" height="131px" />
                <Box ml={3}>
                  <Box sx={{
                    display: "flex",
                  }}>
                    <Heading3>TEAM RAID POINTS</Heading3>
                    <HtmlTooltip
                      title={<TooltipContent title="" desc="Raid Points are a scoring mechanism used during Arena Raids, a competitive team event within the game. During these raids, you and your team battle against NPC Challengers, and the difficulty of the challengers increases as you defeat each one. The higher the Challenger you defeat, the higher your Raid Points score! These points contribute to your team’s total score for the event, influencing your rank on the leaderboard and the rewards you receive." />}
                      placement="bottom"
                      arrow
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      onClose={handleTooltipClose}
                      open={open}
                    >
                      <IconButton onClick={handleTooltipOpen} sx={{ color: '#ffffff' }}>
                        <img src={QuestionIcon} width="16px" height="16px" alt="total found" />
                      </IconButton>
                    </HtmlTooltip>
                  </Box>
                  <Heading1>{data?.team_raid_points}</Heading1>
                </Box>
              </Box>
            </ClickAwayListener>
            <Box>
              <Heading3>GYM RANK</Heading3>
              <Heading1>{data?.gym_rank}</Heading1>
            </Box>
            <Box mt={{
              xs: 2,
              tl: 0,
            }}>
              <Heading3>GYM LEADERBOARD</Heading3>
              <Link to="/leaderboards?category=teamRaid&period=currentSeason">
                <Heading4 sx={{ marginTop: 1, color: "#0076FF" }}>THIS SEASON</Heading4>
              </Link>
              <Link to="/leaderboards?category=teamRaid&period=lastSeason">
                <Heading4 sx={{ marginTop: 1, color: "#0076FF" }}>LAST SEASON</Heading4>
              </Link>
            </Box>
          </Box>
        </Box>
      </Container>}
      <Box sx={{
        textAlign: "center"
      }}>
        <CustomButton href={`/team/${teamname}`} sx={{ marginTop: 8, marginBottom: 12, marginLeft: "auto", marginRight: "auto" }}>
          View Team Main Page
        </CustomButton>
      </Box>
    </Box>
  );
};

export default TeamProfileSeasonInfo;
