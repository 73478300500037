import { Box, Collapse, Container, Stack, Typography } from "@mui/material";
import { Heading3 } from "../../../../../components/Heading";
import VipImage from '../../../../../assets/img/stats/vip.png';
import { useEffect, useState } from "react";
import SparkleAnimation1 from '../../../../../assets/animations/sparkle2.json';
import Lottie from 'lottie-react';
import { VIPCODE_STATUS } from "../../../../../config/constants";
import { useNavigate } from "react-router-dom";

const VipSection = ({
  vipCode,
  status = VIPCODE_STATUS.INVALID,
  username,
  gymID
}) => {
  const navigate = useNavigate();
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true);
    }, 500);
  }, [])

  const goToSignUpPage = () => {
    navigate(`/signup/${gymID}`, { state: { vipCode } })
  }

  const Content = {
    VALID: (
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Heading3 sx={{ color: '#000' }}>
          Congratulations!
        </Heading3>
        <Box display="flex" flexDirection="column" gap={3} sx={{
          '& li': { fontSize: { xs: 14, tl: 16 } }
        }}>
          <Typography color="black" fontSize={{ xs: 14, tl: 16 }}>
            You've been hand-picked by <b>{username}</b> to experience Black Box VR - the revolutionary fitness experience that's changing lives.
          </Typography>
          <Typography color="black" fontWeight={700} fontSize={{ xs: 14, tl: 16 }}>
            This exclusive VIP invitation gives you:
          </Typography>
          <ul style={{ color: 'black', listStylePosition: 'inside', paddingLeft: '8px' }}>
            <li style={{ lineHeight: '24px' }}>
              A full 4-week, risk-free trial for only $1 (normally just 7 days!)
            </li>
            <li style={{ lineHeight: '24px' }}>
              Access to cutting-edge fitness technology
            </li>
            <li style={{ lineHeight: '24px' }}>
              Personalized workouts that adapt to your level
            </li>
            <li style={{ lineHeight: '24px' }}>
              The chance to join an exclusive, supportive community
            </li>
          </ul>
        </Box>
        <Typography color="black" fontSize={{ xs: 14, tl: 16 }}>
          <b>{username}</b> wants you to join them on this incredible fitness journey. Don't miss this rare opportunity - VIP invitations are limited and highly sought after!
        </Typography>
      </Box>
    ),
    EXPIRED: (
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Heading3 sx={{ color: '#000' }}>
          This Vip Code has expired
        </Heading3>
        <Box display="flex" flexDirection="column" gap={3} sx={{
          '& li': { fontSize: { xs: 14, tl: 16 } }
        }}>
          <Typography color="black" fontSize={{ xs: 14, tl: 16 }}>
            This VIP code is no longer valid. Contact <b>{username}</b> to see if they can give you a new one, or signup without a VIP code to get started today!
          </Typography>
          <Typography color="black" fontSize={{ xs: 14, tl: 16 }}>
            If you believe this is an error, please contact us at support@blackbox-vr.com or reach out to the gym staff directly.
          </Typography>
        </Box>
      </Box>
    ),
    INVALID: (
      <Box display="flex" flexDirection="column" gap={2.5}>
        <Heading3 sx={{ color: '#000' }}>
          This Vip Code Not Valid
        </Heading3>
        <Box display="flex" flexDirection="column" gap={3} sx={{
          '& li': { fontSize: { xs: 14, tl: 16 } }
        }}>
          <Typography color="black" fontSize={{ xs: 14, tl: 16 }}>
            This VIP code is not valid. It may have been used already or the code was not entered correctly. Contact <b>{username}</b> to see if they can give you a new one, or signup without a VIP code to get started today!
          </Typography>
          <Typography color="black" fontSize={{ xs: 14, tl: 16 }}>
            If you believe this is an error, please contact us at support@blackbox-vr.com or reach out to the gym staff directly.
          </Typography>
        </Box>
      </Box>
    )
  };

  return (
    <Container>
      <Collapse orientation="vertical" in={animation} timeout={1000}>
        <Box
          sx={{
            maxWidth: '900px',
            position: 'relative',
            display: 'flex',
            flexDirection: { xs: 'column', tl: 'row' },
            alignItems: { xs: 'center', tl: 'center' },
            top: { xs: 70, tl: 28 },
            background: 'linear-gradient(90deg, #FFF 0%, #999 100%)',
            borderRadius: '10px',
            border: '2px solid #F4BA04',
            padding: '44px 16px 44px 44px',
            gap: '16px'
          }}
        >
          {Content[status]}
          <Stack minWidth="250px" alignItems="center" gap={1} flexShrink={1}>
            <Box
              sx={{
                position: 'relative',
                '& img.effect': {
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  height: 200,
                  opacity: 0.6,
                },
              }}
            >
              <Lottie
                animationData={SparkleAnimation1}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  height: 200,
                  margin: 'auto',
                }}
              />
              <img src={VipImage} alt="Vip" width={155} height={200} />
            </Box>
            {status === VIPCODE_STATUS.VALID && (
              <Box
                width="196px"
                padding="26px"
                border="1px solid #000"
                borderRadius="60px"
                color="black"
                textAlign="center"
                textTransform="uppercase"
                fontWeight={700}
                letterSpacing="-0.406px"
                sx={{
                  background: "linear-gradient(318deg, #EF9E00 -14.19%, #FFFE0B 99.67%)",
                  cursor: "pointer",
                  '&:hover': { opacity: 0.9 }
                }}
                onClick={goToSignUpPage}
              >
                Claim your 4 week trial!
              </Box>
            )}
          </Stack>
        </Box>
      </Collapse>
    </Container>
  );
}

export default VipSection;
