import { Box, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import LocalImage from '../../../components/LocalImage';
import Loading from '../../../components/Loading';
import { Heading1, Heading3 } from '../../../components/Heading';

const formatDate = (date) => {
  if (!date) {
    return "";
  }
  const parts = date.split(",");
  const subParts = parts[0].split(" ");
  return subParts[0].substring(0, 3) + " " + subParts[1];
};

const DatesAndEvents = ({
  data,
  isLoading,
}) => {
  return (
    <Container sx={{ marginTop: 10 }}>
      <Heading1 sx={{ marginBottom: 6 }}>Season Dates And Events</Heading1>
      {isLoading && <Box sx={{ marginTop: 3, marginBottom: 3 }}>
        <Loading />
      </Box>}
      {!isLoading && !!data && <Box>
        <Box sx={{
          paddingTop: 3,
          marginBottom: 3,
          borderTop: "1px solid #B1B1B1",
          display: "flex",
          flexDirection: {
            xs: "column",
            tl: "row"
          },
          paddingRight: {
            xs: 0,
            tl: "200px",
          },
          alignItems: "center",
        }}>
          <LocalImage src="img/seasons/event-1.webp" alt="Season-Event" loading="lazy" width="100%" height="100%" />
          <Box sx={{
            marginLeft: {
              xs: 0,
              tl: 6,
            },
            marginTop: {
              xs: 3,
              tl: 0,
            },
          }}>
            <Heading3>ARENA RAID #1: {formatDate(data.month1RaidStart)} - {formatDate(data.month1RaidEnd)}</Heading3>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: 2,
            }}>
              Prepare for the ultimate test of your strength, strategy, and teamwork in Black Box VR's thrilling Arena Raid event. Get valuable loot that your whole team can share!
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          paddingTop: 3,
          marginBottom: 3,
          borderTop: "1px solid #B1B1B1",
          display: "flex",
          flexDirection: {
            xs: "column",
            tl: "row"
          },
          paddingLeft: {
            xs: 0,
            tl: "200px",
          },
          alignItems: "center",
        }}>
          <Box sx={{
            marginRight: {
              xs: 0,
              tl: 6,
            },
            marginBottom: {
              xs: 3,
              tl: 0,
            },
          }}>
            <Heading3>ARENA RAID #2: {formatDate(data.month2RaidStart)} - {formatDate(data.month2RaidEnd)}</Heading3>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: 2,
            }}>
              At the end of month two, jump back into another Arena Raid and see if you can shatter your previous records! Get Raid Points for every Challenger you take down, and watch your team climb up the leaderboards.
            </Typography>
          </Box>
          <LocalImage src="img/seasons/event-2.webp" alt="Season-Event" loading="lazy" width="100%" height="100%" />
        </Box>
        <Box sx={{
          paddingTop: 3,
          marginBottom: 3,
          borderTop: "1px solid #B1B1B1",
          display: "flex",
          flexDirection: {
            xs: "column",
            tl: "row"
          },
          paddingRight: {
            xs: 0,
            tl: "200px",
          },
          alignItems: "center",
        }}>
          <LocalImage src="img/seasons/event-3.webp" alt="Season-Event" loading="lazy" width="100%" height="100%" />
          <Box sx={{
            marginLeft: {
              xs: 0,
              tl: 6,
            },
            marginTop: {
              xs: 3,
              tl: 0,
            },
          }}>
            <Heading3>SEASON FINALE ARENA RAID #3: {formatDate(data.month3RaidStart)} - {formatDate(data.month3RaidEnd)}</Heading3>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: 2,
            }}>
              It all comes down to this!  In the final Arena Raid of the Season, the number of Raid Points you earn is multiplied, making it the most important event yet. Get special rewards and loot that can only be found during the final month. 
            </Typography>
          </Box>
        </Box>
      </Box>}
    </Container>
  );
};

export default DatesAndEvents;
