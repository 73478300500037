import axios from 'axios';
import useSWR from 'swr';
import { AmityUserTokenManager, ApiRegion } from '@amityco/js-sdk';

import useApiResponse from '../hooks/useApiResponse';
import { getAmityBearerToken, setAmityBearerToken } from '../utils/cookie';

const amityApi = axios.create({
  baseURL: process.env.REACT_APP_AMITY_API_URL,
});

amityApi.interceptors.request.use((config) => {
  const token = getAmityBearerToken();

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  return config;
});

amityApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export default amityApi;

export function getCacheKey(url, params) {
  const cacheKey = [url];
  if (params) {
    cacheKey.push(JSON.stringify(params));
  }
  // cacheKey.push(getToken())
  return cacheKey;
}

export function useAmityApi(url, params, config) {
  const { data, mutate, error } = useSWR(url ? getCacheKey(url, params) : null, async () => {
    const { data } = await amityApi.get(url, { params, ...config });
    return { data, isLoading: false };
  });

  const cachedResponse = useApiResponse(data);

  return {
    data: cachedResponse || { isLoading: url ? true : false },
    mutate,
    error,
  };
}

export const getAmityAPIToken = async (id) => {
  const token = getAmityBearerToken();

  if (!token) {
    const apiKey = process.env.REACT_APP_AMITY_API_KEY;

    const { accessToken } = await AmityUserTokenManager.createUserToken(apiKey, ApiRegion.US, {
      userId: id,
    });

    setAmityBearerToken(accessToken);
  }
};
