import { Box } from '@mui/material';

const BackImage = ({ image }) => {
  return (
    <Box
      sx={{
        backgroundColor: '#141516',
        display: 'flex',
        justifyContent: { xs: 'center', lg: 'flex-end' },
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
        position: 'absolute',
        overflow: 'hidden',
        height: '100%',
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: { xs: '100%', lg: 'auto' },
          height: { xs: 160, tl: 'auto' },
        }}
      >
        <img src={image} alt="" loading="lazy" />
        <Box
          sx={{
            background: {
              xs: 'linear-gradient(360deg, #151617 0%, rgba(21, 22, 23, 0.64) 100%)',
              tl: 'linear-gradient(90deg, #151617 1%, rgba(21,22,23,0.64) 100%)',
            },
            position: 'absolute',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            left: 0,
            bottom: 0,
            right: 0,
            top: 0,
          }}
        />
      </Box>
    </Box>
  );
};

export default BackImage;
