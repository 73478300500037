import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import MembersList from './MembersList';
import { Heading1, Heading2 } from '../../../../components/Heading';
import { CustomButton } from '../../../../components/Buttons';

import ImmortalsLogo from '../../../../assets/img/team/logo/immortals.png';
import ChallengesIcon from '../../../../assets/img/team/challenges.svg';
import Loading from '../../../../components/Loading';

const Team = ({ team, isLoading }) => {
  const { teamName, teamIcon, teamDescription, teamCaptain } = team;
  const members = [
    ...team?.members.filter((m) => m.id === teamCaptain),
    ...team?.members.filter((m) => m.id !== teamCaptain),
  ];

  const navigate = useNavigate();

  const onGoTeamProfile = () => {
    navigate(`/team/${teamName}`);
  };

  return (
    <Box mt={{ xs: 8, tl: 10, md: 16 }} mx={{ xs: 2, tl: 0 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading1>TEAM</Heading1>
        {teamName && <CustomButton onClick={onGoTeamProfile}>View {teamName} Profile</CustomButton>}
      </Box>
      {isLoading ? (
        <Loading sx={{ my: { xs: 3, tl: 5, lg: 9 } }} />
      ) : (
        <>
          <Box
            onClick={onGoTeamProfile}
            sx={{
              background: {
                xs: '#141516',
                tl: 'linear-gradient(88deg, #141516 0%, rgba(20, 21, 22, 0.00) 100%)',
              },
              display: 'flex',
              alignItems: { lg: 'center' },
              flexDirection: { xs: 'column', lg: 'row' },
              mt: { xs: 3, tl: 5, lg: 9 },
              p: 3,
              cursor: 'pointer',

              '&:hover': {
                boxShadow: '0px 0px 32px 0 #FFF',
                transition: 'box-shadow 0.3s ease-in-out',
              },
            }}
          >
            <Box display="flex" flexDirection={{ xs: 'column', tl: 'row' }} alignItems="center">
              <Box sx={{ '& img': { width: 222, height: 222 } }} flex={{ xs: 1 }}>
                <img src={teamIcon ?? ImmortalsLogo} alt="Immortals" />
              </Box>
              <Box flex={{ xs: 1 }} ml={{ xs: 0, tl: 5 }} mt={{ xs: 3, tl: 0 }}>
                <Typography
                  fontSize={24}
                  fontWeight={700}
                  mb={{ xs: 2, tl: 1 }}
                  display="flex"
                  alignItems="center"
                  justifyContent={{ xs: 'center', tl: 'unset' }}
                  sx={{ '& img': { mr: 1 } }}
                >
                  <img src={ChallengesIcon} alt="" />
                  {members.length}/12
                </Typography>
                <Heading2>{teamName}</Heading2>
              </Box>
            </Box>
            <Box flex={1}>
              <Typography
                fontSize={{ xs: 16, tl: 24 }}
                fontWeight={{ xs: 400, tl: 700 }}
                width={{ xs: '100%', tl: 'fit-content' }}
                textAlign={{ xs: 'center', tl: 'left' }}
                m={{ xs: '32px auto auto 0', lg: 'auto' }}
                maxWidth={400}
                sx={{ '& br': { display: { xs: 'none', lg: 'block' } } }}
              >
                {teamDescription}
              </Typography>
            </Box>
          </Box>
          <MembersList members={members} />
        </>
      )}
    </Box>
  );
};

export default Team;
