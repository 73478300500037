import { Box,Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading3, Heading4 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';
import OverviewBoard from './OverviewBoard';

import OverviewBg from '../../../assets/img/team/overview-bg.png';
import OverviewBgMd from '../../../assets/img/team/overview-bg-md.png';
import OverviewBgSm from '../../../assets/img/team/overview-bg-sm.png';

const formatDate = (date) => {
  if (!date) {
    return "";
  }
  const parts = date.split(",");
  const subParts = parts[0].split(" ");
  return subParts[0].substring(0, 3) + " " + subParts[1];
};

const Overview = ({ data }) => {
  const boardData = [
    {
      title: 'Current Season',
      value: `${formatDate(data.month1Start)} - ${formatDate(data.seasonEnd)}`,
    }, {
      title: 'Season Ends In',
      value: (data.timeToSeasonEnd || "").split(",")[0],
    },
  ];

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundImage: {
            xs: `url(${OverviewBgSm})`,
            tl: `url(${OverviewBgMd})`,
            lg: `url(${OverviewBg})`,
          },
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: { xs: 4, tl: '32px 76px', md: '40px 64px' },
          marginTop: 6,
        }}
      >
        <Box width="100%">
          <OverviewBoard data={boardData} />
        </Box>
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          tl: "row"
        },
        marginTop: {
          xs: 3,
          tl: 0,
        },
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <Box sx={{
          marginRight: {
            xs: 0,
            tl: 14,
          }
        }}>
          <Heading3>WHAT ARE BLACK BOX VR SEASONS?</Heading3>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            marginTop: 2,
          }}>
            The ONLY secret to fitness success is sticking to your workouts over the long-term. Other fitness programs get mind-numbingly boring very quickly. But not at Black Box VR!<br /><br />Seasons are 3-month-long themed challenges that provide you with a fresh and exciting fitness experience!  Each Season introduces a new theme and boss, along with brand new challenges, quests, game features, and rewards to keep workouts engaging and motivating.
          </Typography>
        </Box>
        <LocalImage style={{ marginTop: "-70px" }} src="img/seasons/avatar.webp" alt="Seasons" loading="lazy" width="100%" height="100%" />
      </Box>
      <Box sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          tl: "row"
        },
        paddingLeft: {
          xs: 0,
          tl: "114px",
        },
        paddingRight: {
          xs: 0,
          tl: "94px",
        },
        justifyContent: "center",
        alignItems: "center",
      }}>
        <img
          src={data.bossImageURL || "img/seasons/season-boss.webp"}
          alt="Seasons"
          height="100%"
          width="100%"
        />
        <Box sx={{
          marginLeft: {
            xs: 0,
            tl: 8,
          },
          marginTop: {
            xs: 3,
            tl: 0,
          },
        }}>
          <Heading3>THIS Season’S BOSS</Heading3>
          <Heading4 sx={{ marginTop: 5 }}>{data.currentMonthBoss}</Heading4>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            marginTop: 2,
          }}>
            {data.currentMonthBossTaunt}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Overview;
