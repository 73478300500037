import { useEffect, useState } from 'react';

const LocalImage = ({ src, alt, ...rest }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const fetchActiveImage = async () => {
      try {
        const response = await import(`../../assets/${src}`);
        setImage(response.default);
      } catch (err) {
        console.error(err);
      }
    };
    fetchActiveImage();
  }, [src]);

  return <img src={image} alt={alt} {...rest} />;
};

export default LocalImage;
