import { Box } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';

import useQuery from '../../utils/useQuery';
import { getUser } from '../../utils/cookie';

import Header from '../../components/Header';

const PortalLayout = ({ isHideHeader, isRequiredAuth }) => {
  const queryParameters = useQuery();
  const version = queryParameters.get('version');
  const isTv = queryParameters.get('tv') === "true";

  const isAuthenticated = getUser();

  if (isRequiredAuth && !isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return (
    <Box position="relative">
      {!isHideHeader && version !== 'webview' && <Header />}
      <Box
        position="relative"
        mt={!isHideHeader && version !== 'webview' && !isTv ? { xs: 6, lg: '60px' } : 0}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default PortalLayout;
