import { Box,Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading3 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';
import { CustomButton } from '../../../components/Buttons';

const Overview = ({
  username
}) => {
  return (
    <Container>
      <Box sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          tl: "row"
        },
        marginTop: {
          xs: 3,
          tl: 0,
        },
        justifyContent: "space-between",
        alignItems: "center",
      }}>
        <Box sx={{
          marginRight: {
            xs: 0,
            tl: 14,
          }
        }}>
          <Heading3>WHAT ARE BLACK BOX VR SEASONS?</Heading3>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            marginTop: 2,
          }}>
            The ONLY secret to fitness success is sticking to your workouts over the long-term. Other fitness programs get mind-numbingly boring very quickly. But not at Black Box VR!<br /><br />Seasons are 3-month-long themed challenges that provide you with a fresh and exciting fitness experience!  Each Season introduces a new theme and boss, along with brand new challenges, quests, game features, and rewards to keep workouts engaging and motivating.
          </Typography>
        </Box>
        <LocalImage style={{ marginTop: "-70px" }} src="img/seasons/avatar.webp" alt="Seasons" loading="lazy" width="100%" height="100%" />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <CustomButton href={`/member/${username}`} sx={{ marginTop: 5, marginBottom: 10 }}>
          View Profile Main Page
        </CustomButton>
      </Box>
    </Container>
  );
};

export default Overview;
