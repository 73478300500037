import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { getUser } from '../utils/cookie';

const backboneApi = axios.create({
  baseURL: process.env.REACT_APP_BACKBONE_API_URL,
});

// Request interceptor
backboneApi.interceptors.request.use((config) => {
  const user = getUser();

  if (user?.token) {
    config.headers.Authorization = `Bearer ${user.token}`;
  }

  const fullUrl = `${config.baseURL}${config.url}`;
  console.log("Request Full URL: ", fullUrl);
  console.log("Request Method: ", config.method);
  console.log("Request Headers: ", JSON.stringify(config.headers, null, 2));
  console.log("Request Data: ", JSON.stringify(config.data, null, 2));

  config.headers['x-api-key'] = process.env.REACT_APP_BACKBONE_API_KEY;

  // Add Authorization header to OPTIONS requests if the URL contains /web
  if (config.method.toUpperCase() === 'OPTIONS' && config.url.includes('/web')) {
    config.headers.Authorization = `Bearer ${user.token}`;
  }

  return config;
}, (error) => {
  console.error("Request Error: ", error);
  return Promise.reject(error);
});

// Response interceptor
backboneApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Error Object: ", error);  // Log the entire error object

    if (error.response) {
      console.error("Response Status: ", error.response.status);
      console.error("Response Data: ", JSON.stringify(error.response.data, null, 2));
    } else if (error.request) {
      console.error("Request Object: ", error.request);  // Log the request object if no response is received
    } else {
      console.error("Error Message: ", error.message);
    }

    if (error.response?.status === 401 && error.response?.data === 'Unauthorized') {
      enqueueSnackbar('Unauthorized: Your session expired. Please reload the web app.', {
        variant: 'error',
      });

      window.stop();
    }

    return Promise.reject(error.response || 'Something went wrong');
  }
);

export default backboneApi;
