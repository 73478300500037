import { Box } from '@mui/material';
import loadable from '@loadable/component';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';

import { callSparkPostApi } from '../../../services/sparkApiService';
import { callBackbonePostApi } from '../../../services/backboneApiService';
import { getSignUpInfo, setSignUpInfo, removeSignUpInfo } from '../../../utils/cookie';

import MetaTags from '../../../components/MetaTags';
import Loading from '../../../components/Loading';
import { useLocation } from 'react-router-dom';

const InitialSignUp = loadable(() => import('./InitialSignUp'));
const Personalization = loadable(() => import('./Personalization'));
const ClaimReward = loadable(() => import('./ClaimReward'));

const Registration = () => {
  const location = useLocation();
  const [step, setStep] = useState();
  const [claimStep, setClaimStep] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userId, setUserId] = useState();
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    const signUpInfo = getSignUpInfo();

    setStep(signUpInfo?.step ?? 1);
    setUserInfo(signUpInfo?.userInfo1 ?? {});
    setClaimStep(signUpInfo?.claimStep ?? 1);
  }, []);

  const onNext = (objs) => {
    const newUserInfo = { ...userInfo, ...objs };
    setUserInfo(newUserInfo);

    if (step < 3) {
      setStep(step + 1);
      setSignUpInfo({ userInfo1: newUserInfo, step: step + 1 });
      const savePayload = {
        action: 'saveIncompleteSignUp',
        email: objs.email,
        location: objs.location,
        mainGoal: objs.fitnessGoal,
        inviteCode: objs.inviteCode
      }
      callBackbonePostApi(savePayload)
        .then(() => {
        })
        .catch(() => {
        });
    } else {
      setIsSubmit(true);

      const registerPayload = {
        displayName: newUserInfo.userName,
        userName: newUserInfo.userName,
        password: newUserInfo.password,
        scriptData: {
          email: newUserInfo.email,
          userName: newUserInfo.userName,
          firstName: newUserInfo.firstName,
          lastName: newUserInfo.lastName,
          birthdate: '',
          country: '',
          gender: newUserInfo.gender,
          gym: newUserInfo.location,
          inviteCode: newUserInfo.inviteCode ?? '',
          signUpForEmails: true,
          fbUserId: null,
          fbAccessToken: null,
        },
        '@class': '.RegistrationRequest',
      };

      callSparkPostApi(registerPayload)
        .then((data) => {
          if (data.status === 200) {
            const submitError = data.data.error;

            if (submitError) {
              console.log(submitError);
              enqueueSnackbar(submitError?.DETAILS || 'Submit failed! Please try again later.', {
                variant: 'error',
              });
              setIsSubmit(false);
            } else {
              const userId = data.data.userId;

              const questionsPayload = {
                action: 'updateFitnessQuestions',
                userId: userId,
                fitnessGoal: newUserInfo.fitnessGoal,
                activityLevel: newUserInfo.activityLevel,
                commitmentLevel: newUserInfo.commitmentLevel,
                speedToReachGoal: newUserInfo.speedToReachGoal,
              };
              callBackbonePostApi(questionsPayload)
                .then(() => {
                  setUserId(userId);
                  setIsSubmit(false);
                  removeSignUpInfo();
                })
                .catch(() => {
                  setIsSubmit(false);

                  enqueueSnackbar('Submit failed! Please try later again.', {
                    variant: 'error',
                  });
                });
            }
          } else {
            setIsSubmit(false);
          }
        })
        .catch(() => {
          setIsSubmit(false);

          enqueueSnackbar('Submit failed! Please try later again.', {
            variant: 'error',
          });
        });
    }
  };

  return (
    <Box position="relative">
      <MetaTags title="Black Box VR - Sign Up!" />
      {isSubmit ? (
        <Loading fullscreen />
      ) : (
        <>
          {step === 1 && <InitialSignUp onNext={onNext} vipCode={location?.state?.vipCode} />}
          {step === 2 && <Personalization onNext={onNext} />}
          {step === 3 && (
            <ClaimReward step={claimStep} setStep={setClaimStep} userId={userId} onNext={onNext} />
          )}
        </>
      )}
    </Box>
  );
};

export default Registration;
