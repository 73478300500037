import { Typography } from '@mui/material';

const futuraStyle = {
  fontFamily: 'Futura',
  fontWeight: 800,
  lineHeight: 1,
  textTransform: 'uppercase',
};

export const Heading1 = ({ sx, children }) => {
  return (
    <Typography
      variant="h1"
      sx={{ ...futuraStyle, fontSize: { xs: 34, tl: 40, md: 60, xl: 60 }, ...sx }}
    >
      {children}
    </Typography>
  );
};

export const Heading2 = ({ sx, children }) => {
  return (
    <Typography
      variant="h2"
      sx={{ ...futuraStyle, fontSize: { xs: 28, tl: 32, md: 40, xl: 48 }, ...sx }}
    >
      {children}
    </Typography>
  );
};

export const Heading3 = ({ sx, children }) => {
  return (
    <Typography
      variant="h3"
      sx={{ ...futuraStyle, fontSize: { xs: 22, tl: 28, xl: 40 }, fontWeight: 700, ...sx }}
    >
      {children}
    </Typography>
  );
};

export const Heading4 = ({ sx, children }) => {
  return (
    <Typography
      variant="h4"
      sx={{ ...futuraStyle, fontSize: { xs: 20, tl: 24 }, fontWeight: 700, ...sx }}
    >
      {children}
    </Typography>
  );
};

export const Heading5 = ({ sx, children }) => {
  return (
    <Typography variant="h5" sx={{ ...futuraStyle, fontSize: 16, fontWeight: 700, ...sx }}>
      {children}
    </Typography>
  );
};

export const Heading = ({ size, variant, sx, children }) => {
  return (
    <Typography
      variant={variant}
      sx={{
        fontFamily: 'Futura',
        fontWeight: 800,
        textTransform: 'uppercase',
        fontSize: size,
        lineHeight: 1,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
