import { Box, Typography } from '@mui/material';
import moment from 'moment';

import Container from '../../../components/Layout/Container';
import { Heading2 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';

import { formatCompactNumber } from '../../../utils';

const TeamRecap = ({
  data,
}) => {
  return (
    <Container sx={{ marginTop: 0 }}>
      <Heading2>SEASON {data?.season}, MONTH {data?.month} POST-RAID RECAP!</Heading2>
      <Typography sx={{
        fontSize: "24px",
        lineHeight: "32px",
        marginTop: 2,
      }}>
        {moment(data?.raidStartDate).local().format('ll')} - {moment(data?.raidEndDate).local().format('ll')}
      </Typography>
      <Box sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          tl: "row",
        },
        justifyContent: "center",
        marginTop: 6,
        padding: "25px 16px 25px 16px",
        backgroundColor: "#141516"
      }}>
        <Box sx={{ display: "flex", alignItems: "center", mr: 1 }}>
          <LocalImage src="img/team/team-raid-points.webp" alt="Raid Points Earned" loading="lazy" height="124px" />
          <Box ml={2}>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginBottom: 1,
            }}>Raid Points Earned</Typography>
            <Heading2 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.total_team_raid_points)}</Heading2>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
          <LocalImage src="img/seasons/volume.webp" alt="Raids Completed" loading="lazy" height="124px" />
          <Box ml={2}>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginBottom: 1,
            }}>Raids Completed</Typography>
            <Heading2 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.team_battle_count)}</Heading2>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default TeamRecap;
