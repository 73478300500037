import { Box } from '@mui/material';
import { PostTargetType } from '@amityco/js-sdk';
import { useParams } from 'react-router-dom';

import { useGymDetails, useBoiseNewsEvents } from '../../../services/communityService';

import MetaTags from '../../../components/MetaTags';
import Overview from '../../../components/Overview';
import SocialFeed from '../../../components/SocialFeed';
import FullScreenBox from '../../../components/Layout/FullScreenBox';
import { Heading2 } from '../../../components/Heading';
import Loading from '../../../components/Loading';

import TopBanner from './TopBanner';
import Addresses from './Addresses';
import HeroTrainers from './HeroTrainers';
import FeaturedMembers from './FeaturedMembers';
import BoiseTeams from './BoiseTeams';
import SeasonReward from './SeasonReward';
import BoiseLeaders from './BoiseLeaders';
import BoiseNewsEvents from './BoiseNewsEvents';
import GymProfileFooter from './Footer';
import { SOCIAL_SEARCH_TYPE } from '../../../config/constants';

const GymProfile = () => {
  const { id } = useParams();
  const {
    data: { data, isLoading },
    error,
  } = useGymDetails(id);
  const {
    data: { data: newsData, isLoading: isNewsLoading },
  } = useBoiseNewsEvents();

  const gymDetails = data?.results;

  if (error || (!isLoading && !gymDetails)) {
    return (
      <FullScreenBox>
        <Heading2>Gym not found</Heading2>
      </FullScreenBox>
    );
  }

  return (
    <Box>
      <MetaTags title={`${gymDetails?.GymName} Gym`} />
      {isLoading ? (
        <Loading fullscreen />
      ) : (
        <>
          <TopBanner name={gymDetails?.GymName} bannerImg={gymDetails?.GymImage} />
          <Addresses
            address={gymDetails?.AddressDetails}
            contact={gymDetails?.ContactDetails}
            openHours={gymDetails?.HoursOfOperation}
          />
          <HeroTrainers trainers={gymDetails?.EmployeeDetails} />
          <Box mt={{ xs: 5, tl: 7, lg: 13 }}>
            <Overview
              title="Gym Totals"
              description="Bound by Progress, Driven by Dedication. Together, Our Numbers Tell the Story of Triumph."
              data={{
                total_workouts: gymDetails?.GymTotals?.workout_count ?? 0,
                team_total_sets: gymDetails?.GymTotals?.total_sets ?? 0,
                team_total_reps: gymDetails?.GymTotals?.total_reps ?? 0,
                team_total_volume: gymDetails?.GymTotals?.total_volume ?? 0,
                team_total_work: gymDetails?.GymTotals?.total_work ?? 0,
                team_total_calories_burned: gymDetails?.GymTotals?.total_calories_burned ?? 0,
              }}
            />
          </Box>
          <FeaturedMembers members={gymDetails?.FeaturedMembers} />
          <BoiseTeams name={gymDetails?.GymName} teams={gymDetails?.Teams} />
          <SeasonReward />
          <BoiseLeaders
            name={gymDetails?.GymName}
            teams={{
              seasonRaidPoints: gymDetails?.HighestRaidPointTeams,
              completedTeamWorkouts: gymDetails?.HighestWorkoutTeams,
            }}
            individuals={{
              completedWorkouts: gymDetails?.HighestWorkoutIndividuals,
              doneWork: gymDetails?.HighestWorkIndividuals,
              highestStreak: gymDetails?.HighestStreaks,
              mostTrophies: gymDetails?.HighestTrophyIndividuals,
            }}
          />
          {newsData && (
            <BoiseNewsEvents name={gymDetails?.GymName} data={newsData} isLoading={isNewsLoading} />
          )}
          <SocialFeed
            userId={'5cb20e57c7a3f404ff343783'}
            targetType={PostTargetType.CommunityFeed}
            searchType={SOCIAL_SEARCH_TYPE.GYM}
            showTargetId={id}
            sx={{ mt: { xs: 8, tl: 10, lg: 16 } }}
          />
          <GymProfileFooter />
        </>
      )}
    </Box>
  );
};

export default GymProfile;
