import { Box } from '@mui/material';

import PlaceholderImg from '../../assets/img/placeholder-img.webp';

const S3Avatar = ({ src, width = '100%', height = '100%', sx }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${!src || src === 'Not Found' ? PlaceholderImg : src})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        width: width,
        height: height,
        ...sx,
      }}
    />
  );
};

export default S3Avatar;
