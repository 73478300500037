import { Box, Tabs, Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Heading4 } from '../../../../../components/Heading';

export const CustomTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))((props) => ({
  '& .MuiTabs-flexContainer': {
    justifyContent: 'space-between',

    '& button': {
      marginRight: 24,

      '&:last-child': {
        marginRight: 0,
      },
    },
  },
  '& .MuiTabs-scrollButtons': {
    position: 'absolute',
    top: -4,
    right: 0,
    width: 48,
    height: 48,
    borderRadius: '50%',
    border: '1px solid #FFFFFF',
    opacity: 1,

    '&:first-of-type': {
      right: 60,
    },

    '&.Mui-disabled': {
      opacity: 0.2,

      [props.theme.breakpoints.up('lg')]: {
        opacity: 0,
      },
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#FF00FF',
    height: 4,

    [props.theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      bottom: 16,
    },
  },
  '& .MuiTabs-indicatorSpan': {
    [props.theme.breakpoints.up('md')]: {
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '16px 10.5px 0 10.5px',
      borderColor: '#FF00FF transparent transparent transparent',
    },
  },
}));

export const CustomTab = styled(({ title, subTitle, iconImage, ...rest }) => (
  <Tab
    {...rest}
    label={
      <Box
        sx={{
          display: { xs: 'flex', md: 'block' },
          flexDirection: 'row-reverse',
        }}
      >
        <Box>
          <Heading4 sx={{ marginBottom: 1 }}>{title}</Heading4>
          <Typography mb={2}>{subTitle}</Typography>
        </Box>
        <Box>
          <img src={iconImage} alt={title} />
        </Box>
      </Box>
    }
  />
))((props) => ({
  paddingLeft: 0,
  paddingRight: 0,
  color: '#FFF',
  opacity: 0.5,

  '& img': {
    width: 80,
    height: 80,
    borderRadius: '50%',
    border: '1px solid #454545',

    [props.theme.breakpoints.down('md')]: {
      width: 34,
      height: 34,
      border: '1px solid #313131 !important',
      marginRight: 24,
    },
  },

  '&.Mui-selected': {
    color: '#FFF',
    opacity: 1,
    '& img': {
      border: '2px solid #FF00FF',
    },
  },
}));

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            position: 'relative',
            py: { xs: 5, md: 8 },
            pl: { xs: 0, md: 1 },
            borderBottom: { md: '1px solid #B1B1B1' },
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};
