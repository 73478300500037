import moment from 'moment';

export const formatCompactNumber = (number) => {
  if (!number) return 0;

  const formatWithSuffix = (n, suffix) => {
    if (n >= 100) return Math.floor(n) + suffix;
    if (n >= 10) return n.toFixed(1).replace(/\.0$/, '') + suffix;
    return n.toFixed(2).replace(/\.00$/, '') + suffix;
  };

  if (number < 1000) {
    return number;
  } else if (number < 1_000_000) {
    return formatWithSuffix(number / 1000, 'K');
  } else if (number < 1_000_000_000) {
    return formatWithSuffix(number / 1_000_000, 'M');
  } else if (number < 1_000_000_000_000) {
    return formatWithSuffix(number / 1_000_000_000, 'B');
  } else {
    return formatWithSuffix(number / 1_000_000_000_000, 'T');
  }
};

export const formatLocalizedDate = (date, format = 'll') => {
  if (!date) return '';

  const stillUtc = moment.utc(date).toDate();
  const local = moment(stillUtc).local().format(format);

  return local;
};

export const getRelativeTime = (date) => {
  if (!date) return '';

  const endDate = moment(date);
  const currentDate = moment();

  moment.updateLocale('en', {
    relativeTime: {
      future: function (number) {
        const diffDays = endDate.diff(currentDate, 'days');
        const diffHours = endDate.diff(currentDate, 'hours');
        const diffMins = endDate.diff(currentDate, 'minutes');
        const diffSeconds = endDate.diff(currentDate, 'seconds');

        if (diffDays > 0) {
          const restHours = diffHours - diffDays * 24;
          return `in ${diffDays} ${diffDays < 2 ? 'day' : 'days'}, ${restHours} ${restHours < 2 ? 'hour' : 'hours'
            } `;
        } else if (diffHours > 0) {
          const restMins = diffMins - diffHours * 60;
          return `in ${diffHours} ${diffHours < 2 ? 'hour' : 'hours'}, ${restMins} ${restMins < 2 ? 'minute' : 'minutes'
            } `;
        } else if (diffMins > 0) {
          return `in ${diffMins} ${diffMins < 2 ? 'minute' : 'minutes'}`;
        } else if (diffSeconds > 0) {
          return `in ${diffSeconds} ${diffSeconds < 2 ? 'second' : 'seconds'}`;
        } else {
          return `in ${number}`;
        }
      },
      past: '%s ago',
      s: 'a few seconds',
      ss: '%d seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  });

  return moment(date).fromNow();
};

export const formatNumber = (number, decimal = 0) => {
  if (!number) return 0;
  return number.toLocaleString(
    undefined, // leave undefined to use the visitor's browser
    { minimumFractionDigits: decimal }
  );
};

export const sortObjectArray = (objs, sortedKey, order = 'asc') => {
  if (!objs) return [];
  const comparison = order === 'desc' ? -1 : 1;
  const newObjs = [...objs];
  newObjs.sort((a, b) =>
    a[sortedKey] > b[sortedKey] ? 1 * comparison : b[sortedKey] > a[sortedKey] ? -1 * comparison : 0
  );
  return newObjs;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sortWeekdays = (obj, sortKey) => {
  const data = [...obj];
  const sorter = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  data.sort(function sortByDay(a, b) {
    let day1 = a[sortKey].toLowerCase();
    let day2 = b[sortKey].toLowerCase();
    return sorter[day1] - sorter[day2];
  });

  return data;
};

export const decodeHtmlCharCodes = (str) => {
  return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
    return String.fromCharCode(charCode);
  });
};
