import { Box, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const SocialButtons = () => {
  return (
    <Box
      sx={{
        position: { xs: 'relative', tl: 'absolute' },
        right: 0,
        top: { tl: 200, md: 255 },
        display: 'flex',
        flexDirection: { xs: 'row', tl: 'column' },
        justifyContent: { xs: 'space-around', tl: 'initial' },
        background: { tl: '#1E1E1E' },
        borderTopLeftRadius: { tl: 10 },
        borderBottomLeftRadius: { tl: 10 },
        borderTop: { xs: '1px solid #B1B1B1', tl: 'none' },
        borderBottom: { xs: '1px solid #B1B1B1', tl: 'none' },
        padding: { xs: 1, tl: 0.5 },
        mt: { xs: 9, tl: 0 },

        '& a': {
          color: 'white',
          py: { xs: 0, tl: 1 },

          '& svg': {
            fontSize: 30,
          },

          '&:nth-of-type(2)': {
            marginTop: 1,
            marginBottom: 1,
          },
        },
      }}
    >
      <IconButton aria-label="Instagram" href="https://www.instagram.com/blackbox_vr/">
        <InstagramIcon />
      </IconButton>
      <IconButton aria-label="Facebook" href="https://www.facebook.com/blackboxvrfitness/">
        <FacebookIcon />
      </IconButton>
      <IconButton aria-label="Twitter" href="https://twitter.com/blackbox_vr">
        <TwitterIcon />
      </IconButton>
    </Box>
  );
};

export default SocialButtons;
