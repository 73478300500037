import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { formatCompactNumber } from '../../../utils';

import { Heading4, Heading5 } from '../../../components/Heading';
import S3Avatar from '../../../components/S3Avatar';
import { CustomButton } from '../../../components/Buttons';
import CaptainMark from '../../../components/CaptainMark';

import GoldCup from '../../../assets/img/team/team-raid-points.webp';

const MobileMembersTable = ({ members }) => {
  return (
    <Box mt={3}>
      {members.map((member, index) => (
        <Link key={index} to={`/member/${member.title.name}`}>
          <Box
            mx={index === 0 ? 0 : 2}
            px={index === 0 ? 2 : 0}
            py={3}
            borderBottom={index === 0 ? '0' : '1px solid #B1B1B1'}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              background:
                index === 0
                  ? 'linear-gradient(90deg, rgba(187, 10, 148, 0.50) 0%, rgba(147, 4, 115, 0.45) 64.69%, rgba(148, 1, 115, 0.15) 88.66%)'
                  : 'none',
            }}
          >
            <Box display="flex" alignItems="center">
              <Box position="relative">
                <S3Avatar
                  src={member.image.url}
                  width={64}
                  height={64}
                  sx={{ border: '1px solid #313131' }}
                />
                {index === 0 && <CaptainMark />}
              </Box>
              <Box ml={2}>
                <Heading5>{member.title.name}</Heading5>
                <Typography sx={{ fontSize: 12, mt: 0.5 }}>{member.title.desc}</Typography>
                <Typography sx={{ fontSize: 12, fontWeight: 700, color: '#FF00FF', mt: 1 }}>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ minWidth: 100, '& img': { mr: 1 } }}>
              <Heading5>
                <img src={GoldCup} width={16} alt="" />
                {formatCompactNumber(member.raidPoints)}
              </Heading5>
              <Heading5 sx={{ mt: 1 }}>
                {formatCompactNumber(member.challengerReached)}
              </Heading5>
            </Box>
          </Box>
        </Link>
      ))}
      {Array.from(Array(12 - members.length).keys()).map((_, index) => (
        <Box
          key={index}
          mx={2}
          py={3}
          borderTop={index === 0 ? 0 : '1px solid #B1B1B1'}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 64,
              height: 64,
              border: '1px solid #313131',
            }}
          >
            <Typography
              fontSize={12}
              sx={{ maxWidth: 60, textAlign: 'center', lineHeight: '20px' }}
            >
              Spot Available
            </Typography>
          </Box>
          <Heading4>Available</Heading4>
          <CustomButton
            className="action"
            sx={{
              height: 27,
              padding: '4px 16px',
              lineHeight: '20px',
            }}
          >
            Join
          </CustomButton>
        </Box>
      ))}
    </Box>
  );
};

export default MobileMembersTable;
