import { Box, Typography } from '@mui/material';
import Markdown from 'react-markdown'

import Container from '../../../components/Layout/Container';
import { Heading2, Heading3 } from '../../../components/Heading';
import SummaryCell from '../../../components/SummaryCell';
import ExerciseBg from '../../../assets/img/seasons/exercise-total.webp';
import GameBg from '../../../assets/img/seasons/game-total.webp';

import { formatCompactNumber } from '../../../utils';

const PerformanceAnalysis = ({
  data,
  username,
}) => {
  return (
    <Container sx={{ marginTop: 8 }}>
      <Heading2>SEASON {data?.seasonId} PERFORMANCE ANALYSIS</Heading2>
      <Box sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: {
          xs: "column",
          tl: "row",
        },
        justifyContent: "space-between",
      }}>
        <Box sx={{
          marginRight: {
            xs: 0,
            tl: 13
          },
          marginBottom: {
            xs: 2,
            tl: 0
          }
        }}>
          <Typography sx={{
            fontSize: "24px",
            lineHeight: "32px",
            fontWeight: 700,
          }}>
            {username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}, the Virtual Fitness Champion: A Season {data?.seasonId} Odyssey!<br /><br />
          </Typography>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
          }}>
            {(data?.ai_response?.value || "").split("\n").map((item, idx) => (
              <Box key={`description-${idx}`} mb={3}>
                <Markdown>{item}</Markdown>
              </Box>
            ))}
          </Typography>
        </Box>
        <Box>
          <Box sx={{
            width: {
              xs: "auto",
              tl: "461px",
            },
            padding: "22px 23px 46px 23px",
            background: `url(${ExerciseBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            textAlign: "center"
          }}>
            <Heading3>SEASON EXERCISE TOTALS</Heading3>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: 2,
              marginBottom: 5,
            }}>
              Unlocking your potential, one VR workout at a time.
            </Typography>
            <Box mb={3}>
              <SummaryCell
                label="Workouts Completed"
                value={formatCompactNumber(data?.workoutcount?.value)}
                isLarge
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Sets | Reps"
                value={`${formatCompactNumber(data?.setcount?.value)}|${formatCompactNumber(data?.repcount?.value)}`}
                description='A “set” in strength training refers to a specific number of consecutive repetitions of an exercise performed without rest.<br />A “rep” or repetition is one complete motion of a particular exercise, from the starting position back to the starting position again.'
                isQuestion
                isLarge
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Volume (Lbs.) "
                value={formatCompactNumber(data?.totalvolume?.value)}
                description={data?.totalvolume?.description}
                isQuestion
                isLarge
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Work (Ft x Lbs.)"
                value={formatCompactNumber(data?.totalwork?.value)}
                description={data?.totalwork?.description}
                isQuestion
                isLarge
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Calories Burned"
                value={formatCompactNumber(data?.caloriesburned?.value)}
                isLarge
              />
            </Box>
          </Box>
          <Box sx={{
            width: {
              xs: "auto",
              tl: "461px",
            },
            padding: "22px 23px 46px 23px",
            background: `url(${GameBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            textAlign: "center",
            marginTop: 6,
          }}>
            <Heading3>SEASON GAME TOTALS</Heading3>
            <Typography sx={{
              fontSize: "16px",
              lineHeight: "26px",
              marginTop: 2,
              marginBottom: 5,
            }}>
              Showing off your strategic prowess.
            </Typography>
            <Box mb={3}>
              <SummaryCell
                label="Champions Deployed"
                value={formatCompactNumber(data?.totalchampionsdeployed?.value)}
                isLarge
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Points Scored"
                value={formatCompactNumber(data?.totalpoints?.value)}
                description={data?.totalpoints?.description}
                isQuestion
                isLarge
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Crystals Destroyed"
                value={formatCompactNumber(data?.totalcrystalsdestroyed?.value)}
                description={data?.totalcrystalsdestroyed?.description}
                isQuestion
                isLarge
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Minutes Played"
                value={formatCompactNumber(data?.totaltimeinsessions?.value)}
                description={data?.totaltimeinsessions?.description}
                isQuestion
                isLarge
              />
            </Box>
            <Box mb={3}>
              <SummaryCell
                label="Coins Earned"
                value={formatCompactNumber(data?.totalcoinsearned?.value)}
                isLarge
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default PerformanceAnalysis;
