import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  breakpoints: {
    values: {
      xs: 0,
      ip: 375,
      sm: 600,
      tl: 768,
      md: 900,
      lg: 1200,
      xl: 1366,
      xxl: 1446,
      xxxl: 1536,
    },
  },
});

export default theme;
