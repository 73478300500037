import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { Heading1, Heading4, Heading5 } from '../../../../components/Heading';

import { formatCompactNumber } from '../../../../utils';
import Leagues from '../../../../config/leagues.json';

import DesktopRankingChart from './RankingChart/Desktop';
import TabletRankingChart from './RankingChart/Tablet';
import MobileRankingChart from './RankingChart/Mobile';

import CupIcon from '../../../../assets/img/top-banner/avatar-frames/cup-icon.svg';

const ActiveDetails = ({ username, currentLeague, displayTrophyCount, arenaInLeague }) => {
  return (
    <>
      <Heading4 sx={{ mt: 2 }}>{username}</Heading4>
      <Heading5 sx={{ mt: 1 }}>{Leagues[currentLeague]?.name}</Heading5>
      <Typography
        mt={2}
        fontSize={{ xs: 14, xl: 16 }}
        fontWeight={500}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {`Arena ${arenaInLeague} - ${formatCompactNumber(displayTrophyCount)}`}{' '}
        <img src={CupIcon} alt="" style={{ marginLeft: 4 }} />
      </Typography>
    </>
  );
};

const LeagueRanking = (props) => {
  const { seasonRecap } = props;
  const currentLeague = parseInt(props.currentLeague)
  const [activeImage, setActiveImage] = useState(null);

  useEffect(() => {
    const fetchActiveImage = async () => {
      try {
        const response = await import(
          `../../../../assets/img/ranking/${Leagues[currentLeague].key}.png`
        );
        setActiveImage(response.default);
      } catch (err) {
        console.error(err);
      }
    };
    fetchActiveImage();
  }, [currentLeague]);

  return (
    <Box mt={{ xs: 10, lg: props.mt || 16 }} mx={{ xs: 2, tl: 0 }}>
      <Heading1>{props.title}</Heading1>
      <Typography mt={3} maxWidth={824}>
        Rise through Leagues with wins and trophies! Accumulate trophies to reach higher Leagues.
        Progress through ten dynamic Arenas per League, boosting both your in-game and real-life
        dominance.
      </Typography>
      {!!seasonRecap && <Box mt={3}>
        <Box sx={{
          display: "flex",
          marginTop: 2,
        }}>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            fontWeight: 700,
            width: "111px",
            marginRight: 2,
            borderRight: "1px solid #B1B1B1"
          }}>
            Season End
          </Typography>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            marginRight: 2,
            textTransform: "uppercase",
          }}>
            {Leagues[seasonRecap?.league?.value]?.name}, ARENA {seasonRecap?.arena?.value} - {formatCompactNumber(seasonRecap?.final_trophies?.value)}
          </Typography>
          <img
            src={CupIcon}
            alt="Cup"
          />
        </Box>
      </Box>}
      <DesktopRankingChart activeImage={activeImage} currentLeague={currentLeague}>
        <ActiveDetails {...props} />
      </DesktopRankingChart>
      <TabletRankingChart activeImage={activeImage} currentLeague={currentLeague}>
        <ActiveDetails {...props} />
      </TabletRankingChart>
      <MobileRankingChart activeImage={activeImage} currentLeague={currentLeague} />
    </Box>
  );
};

export default LeagueRanking;
