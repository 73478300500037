import { Box, Grid, Typography } from "@mui/material";
import { Heading3, Heading4 } from "../../../components/Heading";
import { formatCompactNumber } from "../../../utils";
import moment from "moment";

const WorkoutsInfoCard = ({
  title,
  info
}) => {
  const backgroundColors = {
    'Single Workout': "linear-gradient(90deg, #0139A4 0%, #00163E 100%)",
    'Week': "linear-gradient(90deg, #028131 0%, #011B0A 100%)",
    'Month': "linear-gradient(90deg, #600281 0%, #14011B 100%)",
    'Season': "linear-gradient(90deg, #810212 0%, #1B0104 100%)"
  };

  const InfoItem = ({ field, value, date }) => (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography textAlign="center">{field}</Typography>
      <Heading3 sx={{ textAlign: 'center' }}>{formatCompactNumber(value)}</Heading3>
      <Typography fontSize={14} textAlign="center">
        {title === 'Month' ? moment(date).format('MMM, YYYY') : moment(date).format('ll')}
      </Typography>
    </Box>
  );

  return (<Box>
    <Heading4>{title}</Heading4>
    <Box
      display="flex"
      justifyContent="center"
      mt={1.5}
      p={3}
      borderRadius="10px"
      sx={{
        background: backgroundColors?.[title]
      }}
    >
      <Grid container maxWidth="680px" rowGap={5}>
        <Grid item xs={6} tl={3}>
          <InfoItem
            field="Sets"
            value={info?.best_setcount?.value || 0}
            date={info?.best_setcount?.dateTime}
          />
        </Grid>
        <Grid item xs={6} tl={3}>
          <InfoItem
            field="Reps"
            value={info?.best_repcount?.value || 0}
            date={info?.best_repcount?.dateTime}
          />
        </Grid>
        <Grid item xs={6} tl={3}>
          <InfoItem
            field="Work (Ft.-Lbs.)"
            value={info?.best_totalwork?.value || 0}
            date={info?.best_totalwork?.dateTime}
          />
        </Grid>
        <Grid item xs={6} tl={3}>
          <InfoItem
            field="Volume (Lbs)"
            value={info?.best_totalvolume?.value || 0}
            date={info?.best_totalvolume?.dateTime}
          />
        </Grid>
        <Grid item xs={6} tl={3}>
          <InfoItem
            field="Punches/Slices"
            value={info?.best_punchesandslices?.value || 0}
            date={info?.best_punchesandslices?.dateTime}
          />
        </Grid>
        <Grid item xs={6} tl={3}>
          <InfoItem
            field="Calories Burned"
            value={info?.best_caloriesburned?.value || 0}
            date={info?.best_caloriesburned?.dateTime}
          />
        </Grid>
        <Grid item xs={6} tl={3}>
          <InfoItem
            field="Champions Deployed"
            value={info?.best_championsdeployed?.value || 0}
            date={info?.best_championsdeployed?.dateTime}
          />
        </Grid>
        <Grid item xs={6} tl={3}>
          <InfoItem
            field="Points"
            value={info?.best_totalpoints?.value || 0}
            date={info?.best_totalpoints?.dateTime}
          />
        </Grid>
      </Grid>
    </Box>
  </Box>);
}

export default WorkoutsInfoCard;
