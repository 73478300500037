import { Box, Typography } from "@mui/material";
import { Heading2 } from "../../../components/Heading";
import Container from "../../../components/Layout/Container";
import WorkoutsInfoCard from "./InfoCard";

const PersonalRecordsWorkouts = ({
  username,
  bests
}) => {
  return (
    <Container sx={{ mt: 6 }}>
      <Heading2>{username}’S Personal Records</Heading2>
      <Box display="flex" flexDirection="column" gap={6} mt={6}>
        <Typography fontSize={{ xs: 20, tl: 24 }}>Celebrating your strength, fitness, and progress along your Black Box VR journey!</Typography>
        <WorkoutsInfoCard title="Single Workout" info={bests?.AllTime} />
        <WorkoutsInfoCard title="Week" info={bests?.Week} />
        <WorkoutsInfoCard title="Month" info={bests?.Month} />
        <WorkoutsInfoCard title="Season" info={bests?.Season} />
      </Box>
    </Container>
  );
}

export default PersonalRecordsWorkouts;