import { Box } from '@mui/material';

import { BBVR_SITE_URL } from '../../config/constants';

import Container from '../Layout/Container';
import { Heading } from '../Heading';
import { CustomButton } from '../Buttons';

import BackgroundImage from '../../assets/img/Footer/footer-bg.webp';

const FooterBanner = () => {
  return (
    <Container>
      <Box
        mt={{ xs: 8, tl: 10, md: 16 }}
        mx={{ xs: 2, tl: 0 }}
        sx={{
          background: `url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          px: { xs: 3, tl: 8 },
          py: { xs: 3, tl: 7 },
        }}
      >
        <Heading
          size={{ xs: 34, tl: 60, md: 152 }}
          sx={{
            textShadow: '0px 4px 54px rgba(0, 0, 0, 0.45)',
            lineHeight: { xs: '41px', tl: '60px', md: '120px' },
            textAlign: { xs: 'center', tl: 'left' },
            mt: { xs: 2, tl: 0 },

            '& br:first-of-type': {
              display: { xs: 'none', tl: 'block' },
            },
          }}
        >
          Join <br />
          Black BOX
          <br />
          VR TODAY!
        </Heading>
        <Box mt={{ xs: 39, tl: 4, md: 8 }}>
          <CustomButton
            href={`${BBVR_SITE_URL}/locations`}
            sx={{ width: { xs: '100%', tl: 'auto' } }}
          >
            Try For $1!
          </CustomButton>
        </Box>
      </Box>
    </Container>
  );
};

export default FooterBanner;
