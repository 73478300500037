import { Box, Link, Typography } from '@mui/material';

import { Heading, Heading2 } from '../../../../../components/Heading';
import { formatCompactNumber, formatLocalizedDate } from '../../../../../utils';

const MobileVideoInfo = ({
  trophies,
  username,
  userScore,
  chalName,
  chalScore,
  startTime,
  setCount,
  repCount,
  calories,
  sliceCount,
  championsDeployed,
  totalWork,
  matchOutcome,
  isRaidBattle,
  challengerReached,
  raidPointsEarned,
  videoURL
}) => {
  return (
    <Box display={{ xs: 'block', md: 'none' }}>
      <Box
        display={{ xs: 'block', tl: 'flex' }}
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Box display="flex" alignItems="center">
          <Heading2
            sx={!isRaidBattle ? {
              background: 'linear-gradient(135deg, #FFA400 -5.47%, #FFFE00 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            } : {
              color: "#E9008C",
            }}
          >
            {isRaidBattle ? "ARENA RAID!" : matchOutcome}
          </Heading2>
          {!isRaidBattle && <Typography ml={2}>{formatCompactNumber(trophies)} Trophies</Typography>}
        </Box>
        <Box mt={{ xs: 2, tl: 0 }} display='flex' justifyContent='space-between'>
          <Typography sx={{ fontSize: 14 }}>
            {formatLocalizedDate(startTime, 'LL')}
          </Typography>
          <Link href={videoURL} color='#0076FF' sx={{ cursor: 'pointer', 'textDecoration': 'none' }}> Download Video </Link>
        </Box>
      </Box>
      {!isRaidBattle && <Box
        display={{ xs: 'flex', tl: 'flex' }}
        alignItems="center"
        mt={{ xs: 2, tl: 1 }}
        py={{ xs: 2, tl: 0 }}
        borderTop={{ xs: '1px solid #B1B1B1', tl: '0' }}
        borderBottom={{ xs: '1px solid #B1B1B1', tl: '0' }}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Heading size={16}>{username}</Heading>
          <Heading2 sx={{ color: '#4ADE80', ml: 2 }}>{userScore}</Heading2>
        </Box>
        <Box display="flex" alignItems="center">
          <Heading size={16}>{chalName}</Heading>
          <Heading2 sx={{ color: '#FF370B', ml: 2 }}>{chalScore}</Heading2>
        </Box>
      </Box>}
      {!!isRaidBattle && <Box
        display={{ xs: 'flex', tl: 'flex' }}
        alignItems="center"
        mt={{ xs: 2, tl: 1 }}
        py={{ xs: 2, tl: 0 }}
        borderTop={{ xs: '1px solid #B1B1B1', tl: '0' }}
        borderBottom={{ xs: '1px solid #B1B1B1', tl: '0' }}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <Heading size={16}>CHALLENGER REACHED</Heading>
          <Heading2 sx={{ color: '#4ADE80', ml: 2 }}>{challengerReached}</Heading2>
        </Box>
        <Box display="flex" alignItems="center">
          <Heading size={16}>RAID POINTS EARNED</Heading>
          <Heading2 sx={{ color: '#FF370B', ml: 2 }}>{raidPointsEarned}</Heading2>
        </Box>
      </Box>}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          '& h4': { fontSize: 16, fontWeight: 800 },
        }}
      >
        <Box flex={1}>
          <Heading>Sets: {formatCompactNumber(setCount)}</Heading>
          <Heading sx={{ mt: 2 }}>Reps: {formatCompactNumber(repCount)}</Heading>
          <Heading sx={{ mt: 2 }}>PunChes/Slices: {formatCompactNumber(sliceCount)}</Heading>
        </Box>
        <Box flex={1}>
          <Heading>Caloric Burn: ~{formatCompactNumber(calories)}</Heading>
          <Heading sx={{ mt: 2 }}>
            CHAMPIONS DEPLOYED: {formatCompactNumber(championsDeployed)}
          </Heading>
          <Heading sx={{ mt: 2 }}>Work: {formatCompactNumber(totalWork)} Ft*Lbs</Heading>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileVideoInfo;
