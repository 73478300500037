import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Heading5 } from '../../../../components/Heading';
import S3Avatar from '../../../../components/S3Avatar';
import CaptainMark from '../../../../components/CaptainMark';

const MembersList = ({ members }) => {
  const navigate = useNavigate();

  const onGoProfile = (username) => () => {
    if (username) {
      navigate(`/member/${username}`);
    }
  };

  return (
    <Grid
      container
      mt={{ xs: 0, lg: 4 }}
      pb={{ xs: 2, tl: 0 }}
      rowSpacing={4}
      columnSpacing={1}
      sx={{ flexWrap: { xs: 'nowrap', tl: 'wrap' }, overflowX: { xs: 'scroll', tl: 'unset' } }}
    >
      {Array.from(Array(12).keys()).map((index) => {
        const member = members[index];

        return (
          <Grid
            key={index}
            item
            xs={1}
            tl={3}
            xl={2}
            sx={(theme) => ({
              opacity: member ? 1 : 0.5,
              [theme.breakpoints.down('tl')]: {
                maxWidth: 155,
                mr: 2,
              },
            })}
          >
            <Box
              onClick={onGoProfile(member?.username)}
              sx={[
                {
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 2,
                  border: '1px solid #313131',
                  width: { xs: 145, md: 191 },
                  height: { xs: 160, md: 220 },
                  transition: 'all 0.3s ease-in-out',

                  '& img': {
                    objectFit: 'cover',
                  },
                },
                member && {
                  cursor: 'pointer',
                  '&:hover': {
                    boxShadow: '0px 0px 32px 0 #FFF',

                    transform: 'scale(1.1, 1.1)',
                    zIndex: 9999,
                  },
                },
              ]}
            >
              {member ? (
                <>
                  <S3Avatar src={member.image} />
                  {index === 0 && <CaptainMark sx={{ left: 0, bottom: 0 }} />}
                </>
              ) : (
                <Typography fontSize={12}>Spot Available</Typography>
              )}
            </Box>
            <Heading5>{member ? member.username : 'Spot Available'}</Heading5>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MembersList;
