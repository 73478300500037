import { Box, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { getUser, removeUser } from '../../utils/cookie';
import { BBVR_SITE_URL } from '../../config/constants';

import Container from '../Layout/Container';

const menuItems = [
  {
    title: 'About',
    link: `${BBVR_SITE_URL}/about-us/`,
  },
  {
    title: 'In the media',
    link: `${BBVR_SITE_URL}/pr/`,
  },
  {
    title: 'Game play guide',
    link: `${BBVR_SITE_URL}/guides-category/game-play-guide/`,
  },
  {
    title: 'Privacy policy mobile app',
    link: `${BBVR_SITE_URL}/privacy-policy-mobile-app/`,
  },
  {
    title: 'Contact us',
    link: `${BBVR_SITE_URL}/contact/`,
  },
  {
    title: 'Find a location',
    link: `${BBVR_SITE_URL}/locations/`,
  },
  {
    title: 'Terms of use',
    link: `${BBVR_SITE_URL}/terms-of-use/`,
  },
  {
    title: 'Privacy policy',
    link: `${BBVR_SITE_URL}/privacy-policy/`,
  },
];

const FooterMenu = () => {
  const currentYear = new Date().getFullYear();
  const isAuthenticated = getUser();

  const onLogout = () => {
    removeUser();

    window.location.reload();
  };

  return (
    <Box sx={{ background: '#252525', pb: 12 }}>
      <Container>
        <Grid pt={12} pb={10} container flexWrap={{ xs: 'wrap-reverse', sm: 'wrap' }}>
          <Grid container item xs={12} sm={10} md={11}>
            {menuItems.map((menu, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Link to={menu.link}>
                  <Typography mb={2} textAlign={{ xs: 'center', tl: 'left' }}>
                    {menu.title}
                  </Typography>
                </Link>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            md={1}
            sx={{
              '& p': {
                mb: 2,
                textAlign: { xs: 'center', tl: 'left' },
              },
            }}
          >
            {!isAuthenticated ? (
              <Link to="/signin">
                <Typography>Log In</Typography>
              </Link>
            ) : (
              <Typography
                sx={{ cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                onClick={onLogout}
              >
                Log Out
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box borderTop="1px solid #FFFFFF" pt={2}>
          <Typography fontSize={14} textAlign={{ xs: 'center', tl: 'left' }}>
            © {currentYear} Black Box VR. All Rights Reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterMenu;
