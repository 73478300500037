import { Box } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

import { BBVR_SITE_URL } from '../../../../config/constants';

import { Heading } from '../../../../components/Heading';
import TriangleImage from '../../../../assets/img/top-banner/triangle.svg';

const RightCornerTag = () => {
  return (
    <Link to={BBVR_SITE_URL}>
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          width: { xs: '100%', tl: 160, md: 241 },
          height: { xs: 48, tl: 160, md: 241 },
          background: {
            xs: 'linear-gradient(315deg, #F0F 0%, #169DFF 100%)',
            tl: `url(${TriangleImage})`,
          },
          textAlign: 'center',
          zIndex: 99,
          overflow: 'hidden',
          display: { xs: 'flex', tl: 'block' },
          alignItems: 'center',
          justifyContent: 'space-between',

          '& > div p': { transform: { tl: 'rotate(45deg)' } },
        }}
      >
        <Box sx={{ display: { xs: 'flex', tl: 'block' }, alignItems: 'center' }}>
          <Heading
            size={{ xs: 22, tl: 18, md: 27 }}
            sx={{
              width: { tl: '138px' },
              marginLeft: { xs: 2, tl: 7, md: 14 },
              marginTop: { xs: 0, tl: 4, md: 6 },
            }}
          >
            What is
          </Heading>
          <Heading
            size={{ xs: 22, tl: 26, md: 39 }}
            sx={{
              width: { tl: '217px' },
              marginTop: 0,
              marginLeft: { xs: '6px', tl: -1, md: 5 },
            }}
          >
            Black Box?
          </Heading>
        </Box>
        <Box mr={2} sx={{ display: { xs: 'flex', tl: 'none' }, alignItems: 'center' }}>
          <ArrowForwardIcon />
        </Box>
      </Box>
    </Link>
  );
};

export default RightCornerTag;
