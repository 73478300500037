import { Box, Typography } from '@mui/material';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

import { formatCompactNumber, formatLocalizedDate, formatNumber } from '../../../../../utils';

import { Heading2, Heading4 } from '../../../../../components/Heading';

const getOption = (dates, values, type = 'volumn') => ({
  grid: {
    height: 250,
    left: 0,
    top: 37,
    right: '60px',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      label: {
        formatter: function (params) {
          return formatLocalizedDate(params.value, 'YYYY-MM-DD');
        },
      },
    },
  },
  xAxis: {
    type: 'category',
    data: dates,
    show: false,
  },
  yAxis: {
    type: 'value',
    position: 'right',
    axisLabel: {
      fontWeight: 400,
      fontSize: 16,
      color: '#fff',
      formatter: function (value) {
        return formatCompactNumber(value);
      },
    },
  },
  series: [
    {
      name: type === 'volumn' ? 'Volume' : 'Work',
      data: values,
      type: 'bar',
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: type === 'volumn' ? '#FF00FF' : '#169DFF' },
          { offset: 1, color: type === 'volumn' ? '#FF2D56' : '#FF00FF' },
        ]),
        borderRadius: 5,
      },
    },
  ],
});

const WorkoutCharts = ({ averageVolume, volumeOverTime, averageWork, workOverTime }) => {
  const vDates = Object.keys(volumeOverTime).sort();
  const vValues = vDates.map((date) => volumeOverTime[date]);
  const wDates = Object.keys(workOverTime).sort();
  const wValues = wDates.map((date) => workOverTime[date]);

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      flexWrap="wrap"
      mt={{ xs: 8, tl: 10, md: 16 }}
      mx={{ xs: 2, tl: 0 }}
    >
      <Box flex={1} mr={{ md: 1 }}>
        <Heading2 sx={{ '& br': { display: { xs: 'none', md: 'block' } } }}>
          Volume Per Workout
        </Heading2>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mt: { xs: 4, md: 7 },
          }}
        >
          <Box>
            <Typography>Avg Volume</Typography>
            <Heading4>{formatNumber(Math.round(averageVolume))} LBS</Heading4>
            <Typography>Per Workout</Typography>
          </Box>
          <Box textAlign="right">
            <Typography>Lbs. of Volume</Typography>
            <Typography>(Sets x Reps)</Typography>
          </Box>
        </Box>
        <ReactEcharts option={getOption(vDates, vValues)} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 7 }}>
          <Typography>
            {vDates.length > 0 ? formatLocalizedDate(vDates[0], 'MMM YYYY') : ''}
          </Typography>
          <Typography>
            {vDates.length > 0 ? formatLocalizedDate(vDates[vDates.length - 1], 'MMM YYYY') : ''}
          </Typography>
        </Box>
      </Box>
      <Box flex={1} ml={{ md: 1 }} mt={{ xs: 6, md: 0 }}>
        <Heading2 sx={{ '& br': { display: { xs: 'none', md: 'block' } } }}>
          Work Per Workout
        </Heading2>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            mt: { xs: 4, md: 7 },
          }}
        >
          <Box>
            <Typography>Avg Work</Typography>
            <Heading4>{formatNumber(Math.round(averageWork))} FT*LBS</Heading4>
            <Typography>Per Workout</Typography>
          </Box>
          <Box textAlign="right">
            <Typography>Ft.*Lbs. of Work</Typography>
          </Box>
        </Box>
        <ReactEcharts option={getOption(wDates, wValues, 'work')} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 7 }}>
          <Typography>
            {wDates.length > 0 ? formatLocalizedDate(wDates[0], 'MMM YYYY') : ''}
          </Typography>
          <Typography>
            {wDates.length > 0 ? formatLocalizedDate(wDates[wDates.length - 1], 'MMM YYYY') : ''}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutCharts;
