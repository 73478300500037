import { Box, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading2, Heading3 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';
import Loading from '../../../components/Loading';

import { formatCompactNumber } from '../../../utils';
import moment from 'moment';

const WeekRecap = ({
  data,
}) => {
  const isLoading = false;

  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading2>YOUR WEEKLY RECAP!</Heading2>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography sx={{
            fontSize: "24px",
            lineHeight: "32px",
            marginTop: 2,
          }}>
            {moment(data?.currentWeek?.startDate).format('ll')} - {moment(data?.currentWeek?.endDate).format('ll')}
          </Typography>
          <Box sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              tl: "row",
            },
            justifyContent: "space-between",
            marginTop: 6,
            padding: "25px 16px 25px 16px",
            backgroundColor: "#141516"
          }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalImage src="img/seasons/trophy.webp" alt="Trophies Gained" loading="lazy" height="124px" />
              <Box ml={2}>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  marginBottom: 1,
                }}>Trophies Gained</Typography>
                <Heading3 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.currentWeek?.weeklyRecap?.totalTrophies)}</Heading3>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalImage src="img/seasons/volume.webp" alt="Workouts Completed" loading="lazy" height="124px" />
              <Box ml={2}>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  marginBottom: 1,
                }}>Workouts Completed</Typography>
                <Heading3 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.currentWeek?.weeklyRecap?.completedWorkouts)}</Heading3>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalImage src="img/seasons/season-xp.webp" alt="Season XP Earned" loading="lazy" height="124px" />
              <Box ml={2}>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  marginBottom: 1,
                }}>Season XP Earned</Typography>
                <Heading3 sx={{ marginBottom: 2 }}>
                  {
                    formatCompactNumber((data?.currentWeek?.weeklyRecap?.seasonXpProgress?.current -
                      data?.previousWeek?.weeklyRecap?.seasonXpProgress?.current) || 0)
                  }
                </Heading3>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalImage src="img/seasons/calories.webp" alt="Calories Burned" loading="lazy" height="124px" />
              <Box ml={2}>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  marginBottom: 1,
                }}>Calories Burned</Typography>
                <Heading3 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.currentWeek?.weeklyRecap?.totalCaloriesBurned)}</Heading3>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default WeekRecap;
