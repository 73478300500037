import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { callBackbonePostApi } from '../../services/backboneApiService';
import { formatLocalizedDate } from '../../utils';

import Loading from '../../components/Loading';
import FullScreenBox from '../../components/Layout/FullScreenBox';
import S3Avatar from '../../components/S3Avatar';
import { Heading, Heading1, Heading2 } from '../../components/Heading';
import DynamicFont from '../../components/DynamicFont';
import LocalImage from '../../components/LocalImage';

import MaskImage from '../../assets/img/top-banner/avatar-frames/avatar-mask1.png';
import AvatarBorder from '../../assets/img/top-banner/avatar-frames/avatar-border.png';

const MemberQuestionnaire = () => {
  const { username } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    const init = () => {
      setIsLoading(true);

      const payload = {
        action: 'getUserResponses',
        userNameOrEmail: username,
      };

      callBackbonePostApi(payload, '')
        .then((data) => {
          setIsLoading(false);

          if (data?.status === 200 && data?.data?.userResponses) {
            setData(data.data);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

    init();
  }, [username]);

  const renderQuestionCard = (question, answer) => {
    return (
      <Grid item xs={6} md={6}>
        <Typography sx={{ mb: 2, fontWeight: 700 }}>{question}</Typography>
        <Box
          sx={{
            backgroundColor: '#F3F4F5',
            height: 350,
            padding: 1,
            overflow: 'hidden',
            fontStyle: 'italic',
          }}
        >
          <DynamicFont defaultFont={16} maxHeight={352} content={(answer || "").split("\n").map((item, idx) => (
            <span key={idx}>
              {item}
              <br/>
            </span>
          ))} />
        </Box>
      </Grid>
    );
  };

  if (isLoading) {
    return <Loading fullscreen />;
  }

  if (!data) {
    return (
      <FullScreenBox>
        <Heading2>Member not found</Heading2>
      </FullScreenBox>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        background: '#fff',
        minHeight: '100vh',
        color: '#000',
      }}
    >
      <Box width={752} minWidth={752} px={4} py={2} backgroundColor="#fff">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            sx={{
              backgroundImage: `url(${AvatarBorder})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: 158,
              height: 172,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: 153,
                minHeight: 169,
                maskImage: `url(${MaskImage})`,
                maskSize: '100%',
                maskRepeat: 'no-repeat',
                mt: '2px',

                '& img': {
                  objectFit: 'cover',
                },
              }}
            >
              <S3Avatar src={data.userProfilePicUrl} />
            </Box>
          </Box>
          <Box ml={4} display="flex" flexDirection="column">
            <Box sx={{ '& img': { height: 15 } }}>
              <LocalImage src="img/logo-black.png" alt="Black Box" loading="lazy" height="100%" />
            </Box>
            <Heading1>{username}</Heading1>
            {data.joinDate && (
              <Heading sx={{ mt: 0.5, fontWeight: 400, '& strong': { color: '#FF00FF' } }}>
                Join Date: <strong>{formatLocalizedDate(data.joinDate, 'LL')}</strong>
              </Heading>
            )}
          </Box>
        </Box>
        <Grid container columnSpacing={2} rowSpacing={2} mt={1}>
          {renderQuestionCard(
            'How has Black Box VR changed your life?',
            data.userResponses.lifeChange
          )}
          {renderQuestionCard(
            'What improvements have you seen?',
            data.userResponses.healthImprovements
          )}
          {renderQuestionCard(
            'Favorite thing about Black Box VR?',
            data.userResponses.favoriteThing
          )}
          {renderQuestionCard('What makes Black Box VR different?', data.userResponses.difference)}
        </Grid>
      </Box>
    </Box>
  );
};

export default MemberQuestionnaire;
