import { Box } from '@mui/material';

const FullScreenBox = ({ children, ...rest }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={{ xs: 'calc(100vh - 48px)', lg: 'calc(100vh - 60px)' }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default FullScreenBox;
