import { Box, Typography } from '@mui/material';

const StyledInput = ({ label, sx, ...rest }) => {
  return (
    <Box sx={sx}>
      <Typography fontWeight={700} mb={1}>
        {label}
      </Typography>
      <input
        style={{
          background: '#3C3F40',
          fontSize: 16,
          minHeight: 55,
          width: '100%',
          border: 0,
          outline: 'none',
          padding: '0 16px',
          boxSizing: 'border-box',
          color: '#fff',
        }}
        {...rest}
      />
    </Box>
  );
};

export default StyledInput;
