import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { formatLocalizedDate, decodeHtmlCharCodes } from '../../../../utils';

import Loading from '../../../../components/Loading';
import S3Avatar from '../../../../components/S3Avatar';
import { Heading4 } from '../../../../components/Heading';

const NewsItem = ({ mediaId, title, date, link, isBorder }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    fetch(`${process.env.REACT_APP_WP_API_URL}/media/${mediaId}`)
      .then((response) => response.json())
      .then((media) => {
        const featuredImage = media.source_url;
        setImage(featuredImage);
      });
  }, [mediaId]);

  return (
    <Box display="flex" flexDirection={{ xs: 'column', tl: 'row' }} mb={2}>
      <Box
        sx={{
          minWidth: { xs: '100%', tl: 197, lg: 401 },
          height: { xs: 193, tl: 111, lg: 226 },
          border: image ? 0 : '1px solid #313131',
        }}
      >
        {image ? (
          <a href={link} target="_blank" rel="noreferrer">
            <S3Avatar src={image} />
          </a>
        ) : (
          <Loading />
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          borderBottom: isBorder ? '1px solid #B1B1B1' : 0,
          mt: { xs: 2, tl: 0 },
          ml: { xs: 0, tl: 4 },
          pb: 2,
          flex: 1,
        }}
      >
        <a href={link} target="_blank" rel="noreferrer">
          <Heading4>{decodeHtmlCharCodes(title)}</Heading4>
        </a>
        <Typography sx={{ mt: 2 }}>{formatLocalizedDate(date, 'LL')}</Typography>
      </Box>
    </Box>
  );
};

export default NewsItem;
