import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

import { BBVR_SITE_URL } from '../../config/constants';

export const CustomTabs = styled(Tabs)(() => ({
  minHeight: 32,

  '& .MuiTabs-indicator': {
    backgroundColor: '#0076FF',
    height: 3,
  },
}));

export const CustomTab = styled(Tab)((props) => ({
  color: '#A0A0A0',
  fontSize: 16,
  fontWeight: 500,
  padding: '4px 8px',
  textTransform: 'capitalize',
  letterSpacing: '-0.4px',
  minWidth: 'auto',
  minHeight: 22,

  [props.theme.breakpoints.up('xxxl')]: {
    padding: '4px 12px',
  },

  [props.theme.breakpoints.down('xl')]: {
    padding: 4,
    minWidth: 'auto',
  },

  '&.Mui-selected': {
    color: '#A0A0A0',
  },
}));

const TopMenu = () => {
  const [value, setValue] = useState(false);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <Box mr={2.5}>
      <CustomTabs value={value} onChange={handleChange} aria-label="Top Menu">
        <CustomTab
          value="t1"
          label="Gym Locations"
          component={Link}
          to={`${BBVR_SITE_URL}/locations/`}
        />
        <CustomTab value="t2" label="The Game" component={Link} to={`${BBVR_SITE_URL}/the-game/`} />
        <CustomTab
          value="t3"
          label="The Workout"
          component={Link}
          to={`${BBVR_SITE_URL}/research-update-the-surprising-exercise-science-behind-black-box-vr/`}
        />
        <CustomTab
          value="t4"
          label="The Hardware"
          component={Link}
          to={`${BBVR_SITE_URL}/the-hardware/`}
        />
        <CustomTab value="t5" label="News" component={Link} to={`${BBVR_SITE_URL}/blog/`} />
        <CustomTab value="t6" label="About" component={Link} to={`${BBVR_SITE_URL}/about-us/`} />
        {/* <CustomTab value="t7" label="Community" component={Link} to="/" /> */}
        <CustomTab value="t8" label="Shop" component={Link} to={`${BBVR_SITE_URL}/shop/`} />
      </CustomTabs>
    </Box>
  );
};
export default TopMenu;
