import { Box } from '@mui/material';
import { Link } from "react-router-dom";

import Container from '../../../components/Layout/Container';
import Loading from '../../../components/Loading';
import { formatCompactNumber } from '../../../utils';
import {
  Heading2,
  Heading4,
  Heading5,
} from '../../../components/Heading';
import S3Avatar from '../../../components/S3Avatar';
import { useUserDetailsByUserId } from '../../../services/communityService';

import BackgroundImage from '../../../assets/img/leaderboards/personal-info-bg.webp';

const PersonalInfo = ({
  userId,
  data,
}) => {
  const {
    data: { data: userDetails, isLoading: isLoadingUserDetails },
  } = useUserDetailsByUserId(userId, null);
  const userProfile = userDetails?.results?.user_info && userDetails?.results?.user_info[0] ? userDetails?.results?.user_info[0] : {}
  const userProfileImage = userDetails?.results?.user_profile_info && userDetails?.results?.user_profile_info[0] ? userDetails?.results?.user_profile_info[0]["profile_pic_url"] : ""
  const profileContainer = (
    <Box
      sx={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: "167px",
        marginTop: 7,
      }}
    >
      {isLoadingUserDetails && <Loading />}
      {!isLoadingUserDetails && userProfile && userDetails && userDetails.results && <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Box>
          <Heading4>YOUR PERSONAL DATA</Heading4>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: 1,
            }}
          >
            <Box sx={{
              width: "100px",
              minHeight: "100px",
            }}>
              <S3Avatar src={userProfileImage} />
            </Box>
            <Box sx={{
              marginLeft: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              padding: 1,
            }}>
              <Heading4>{userProfile["Username"]}</Heading4>
              <Heading5>{userProfile["FirstName"]} {userProfile["LastName"]}</Heading5>
            </Box>
            <Box sx={{
              marginTop: "20px",
              marginLeft: 6,
            }}>
              <Heading2>{formatCompactNumber(data[userId]?.raidPointsContributed || data[userProfile["Username"]]?.raidPointsContributed || data[userProfile["Username"].toLowerCase()]?.totalWork || data[userProfile["Username"].toLowerCase()]?.totalWorkouts || data[userProfile["Username"].toLowerCase()]?.total_gems || data[userId]?.raidPoints || data[userId]?.AllTimeHighest || data[userId]?.CurrentStreak || data[userProfile["Username"].toLowerCase()]?.averageWork || data[userProfile["Username"].toLowerCase()]?.lifetimeXP || data[Object.keys(data).find(key => data[key].username.toLowerCase() === userProfile["Username"].toLowerCase())]?.SeasonXP|| 0)}</Heading2>
            </Box>
          </Box>
        </Box>
      </Box>}
    </Box>
  );

  return (
    <Container>
      {!isLoadingUserDetails && userDetails ? (<Link to={`/member/${userProfile["Username"]}`}>
        {profileContainer}
      </Link>) : profileContainer}
    </Container>
  );
};

export default PersonalInfo;
