import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

export const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'transparent',
  color: '#FFFFFF',
  borderBottom: '1px solid #B1B1B1',
}));

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ color: '#FFFFFF', fontSize: '1rem' }} />}
    {...props}
  />
))(() => ({
  padding: 0,

  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '& .MuiAccordionSummary-content': {
    margin: '16px 0',
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 0,
  margin: '8px 0 32px',
}));
