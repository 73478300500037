import { Box } from '@mui/material';

import { BBVR_SITE_URL } from '../../../../config/constants';

import Loading from '../../../../components/Loading';
import Container from '../../../../components/Layout/Container';
import { Heading1 } from '../../../../components/Heading';
import NewsItem from './NewsItem';
import { CustomButton } from '../../../../components/Buttons';

const initalPageNumber = 3;

const BoiseNewsEvents = ({ name, data, viewAllSx, isLoading }) => {
  const showData = data?.slice(0, initalPageNumber) || [];

  return (
    <Container>
      <Box mt={{ xs: 8, tl: 10, lg: 12 }} mx={{ xs: 2, tl: 0 }}>
        <Heading1>{name}</Heading1>
        {(isLoading || !data) ? (
          <Loading sx={{ mt: { xs: 8, tl: 10, lg: 16 } }} />
        ) : (
          <Box mt={{ xs: 3, tl: 4, lg: 6 }}>
            {showData.map((obj, index) => (
              <NewsItem
                key={index}
                mediaId={obj?.featured_media}
                title={obj?.title?.rendered}
                date={obj?.date}
                link={obj?.link}
                isBorder={index < (data || []).length - 1}
              />
            ))}
            <Box display="flex" justifyContent="center" mt={4}>
              <CustomButton variant="outlined" href={`${BBVR_SITE_URL}/blog/`} target="_blank" sx={viewAllSx}>
                View All
              </CustomButton>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default BoiseNewsEvents;
