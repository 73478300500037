import { Box } from '@mui/material';
import { ApiRegion } from '@amityco/js-sdk';
import { AmityUiKitProvider, AmityUiKitSocial, AmityPageTypes } from '@amityco/ui-kit-open-source';

import useQuery from '../../utils/useQuery';

const apiKey = process.env.REACT_APP_AMITY_API_KEY;
const bbvrTheme = {
  palette: {
    primary: '#0076FF',
  },
  typography: {
    global: {
      fontFamily: 'Roboto, Inter, -apple-system, BlinkMacSystemFont, Arial, sans-serif',
      fontStyle: 'normal',
    },
  },
};

const SocialFeed = ({ userId, targetType, searchType, showTargetId, sx }) => {
  let authToken = 'undefined';
  const queryParameters = useQuery();

  const handleConnected = () => {
    console.log('connected');
  };

  const handleDisconnected = (e) => {
    console.log('disconnected', e);
  };

  if (!!queryParameters.get('auth')) {
    authToken = queryParameters.get('auth');
  }

  return (
    <Box sx={sx}>
      <AmityUiKitProvider
        key={userId}
        apiKey={apiKey}
        userId={userId}
        authToken={authToken}
        apiRegion={ApiRegion.US}
        theme={bbvrTheme}
        onConnected={handleConnected}
        onDisconnected={handleDisconnected}
      >
        <AmityUiKitSocial
          landingPage={AmityPageTypes.NewsFeed}
          feedOptions={{
            showPostCreator: false,
            showOptionMenu: false,
            targetId: process.env.REACT_APP_AMITY_GLOBAL_COMMUNITY_ID,
            targetType: targetType,
            searchType: searchType,
            showTargetId: showTargetId,
            isHideWhenEmpty: true,
          }}
        />
      </AmityUiKitProvider>
    </Box>
  );
};

export default SocialFeed;
