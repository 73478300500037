import { styled } from '@mui/material/styles';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const StyledBreadcrumbs = styled(Breadcrumbs)((props) => ({
  [props.theme.breakpoints.down('tl')]: {
    display: 'none',
  },

  fontSize: 12,
  color: '#FFF',

  '& p': {
    fontSize: 12,
    color: '#B1B1B1',
  },

  '& .MuiBreadcrumbs-separator': {
    margin: props.theme.spacing(0, 0.5),
  },

  '& a:hover': {
    textDecoration: 'underline',
  },
}));

const CustomBreadcrumbs = ({ items }) => {
  return (
    <StyledBreadcrumbs aria-label="breadcrumb">
      {items.map((item, index) =>
        item.link ? (
          <Link key={index} to={item.link}>
            {item.title}
          </Link>
        ) : (
          <Typography key="static">{item.title}</Typography>
        )
      )}
    </StyledBreadcrumbs>
  );
};

export default CustomBreadcrumbs;
