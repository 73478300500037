import { Box, Grid } from '@mui/material';

import Container from '../../../../components/Layout/Container';
import { Heading1, Heading2 } from '../../../../components/Heading';
import GymLeaderboards from './GymLeaderboards';

import IndividualBackground from '../../../../assets/img/boise-leaders/individual-bg.png';
import TeamBackground from '../../../../assets/img/boise-leaders/team-bg.png';
import ImmortalsLogo from '../../../../assets/img/team/logo/immortals.png';

const BoiseLeaders = ({ name, teams, individuals }) => {
  const { completedWorkouts, doneWork, highestStreak, mostTrophies } = individuals;

  const _completedWorkouts = Object.keys(completedWorkouts || {})?.map((name) => ({
    image: completedWorkouts[name]?.profilePicUrl,
    name: completedWorkouts[name]?.username,
    value: completedWorkouts[name]?.workoutcount,
  }));

  const _doneWork = Object.keys(doneWork || {})?.map((name) => ({
    image: doneWork[name]?.profilePicUrl,
    name: doneWork[name]?.username,
    value: doneWork[name]?.work,
  }));

  const _highestStreak = Object.keys(highestStreak || {})?.map((name) => ({
    image: highestStreak[name]?.profilePicUrl,
    name: highestStreak[name]?.username,
    value: highestStreak[name]?.streak,
  }));

  const _mostTrophies = Object.keys(mostTrophies || {})?.map((id) => ({
    image: mostTrophies[id]?.profilePicUrl,
    name: mostTrophies[id]?.username,
    value: mostTrophies[id]?.trophies,
  }));

  const { seasonRaidPoints = {}, completedTeamWorkouts = {} } = teams;
  const _seasonRaidPoints = Object.keys(seasonRaidPoints)?.map((name) => ({
    image: seasonRaidPoints[name]?.teamIcon || ImmortalsLogo,
    name: seasonRaidPoints[name]?.teamName,
    value: seasonRaidPoints[name]?.raidPoints,
  }));

  const _completedTeamWorkouts = Object.keys(completedTeamWorkouts)?.map((name) => ({
    image: completedTeamWorkouts[name]?.team_logo || ImmortalsLogo,
    name,
    value: completedTeamWorkouts[name]?.workout_count,
  }));

  return (
    <Container>
      <Box mt={{ xs: 8, tl: 10, lg: 16 }} mx={{ xs: 2, tl: 0 }}>
        <Heading1>{name}</Heading1>
        <Box mt={{ xs: 3, lg: 6 }}>
          <Box
            sx={{
              width: '100%',
              height: { xs: 57, lg: 90 },
              background: `url(${IndividualBackground})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              backgroundPosition: 'left center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Heading2 sx={{ ml: 5 }}>Individual</Heading2>
          </Box>
          <Grid container columnSpacing={5} mt={{ xs: 3, tl: 4 }}>
            <Grid item xs={12} lg={6}>
              <GymLeaderboards
                hightLight
                title="Completed Workouts"
                data={_completedWorkouts}
                type="member"
              />
            </Grid>
            <Grid item xs={12} lg={6} mt={{ xs: 4, lg: 0 }}>
              <GymLeaderboards
                hightLight
                title="All-Time Work Done (Ft.*Lbs.)"
                data={_doneWork}
                type="member"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={5} mt={{ xs: 4, lg: 8 }}>
            <Grid item xs={12} lg={6}>
              <GymLeaderboards title="Highest Current Streak" data={_highestStreak} type="member" />
            </Grid>
            <Grid item xs={12} lg={6} mt={{ xs: 4, lg: 0 }}>
              <GymLeaderboards title="MOST Trophies" data={_mostTrophies} type="member" />
            </Grid>
          </Grid>
          <Box
            sx={{
              width: '100%',
              height: { xs: 57, lg: 90 },
              background: `url(${TeamBackground})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              backgroundPosition: 'left center',
              display: 'flex',
              alignItems: 'center',
              mt: 10,
            }}
          >
            <Heading2 sx={{ ml: 5 }}>Team</Heading2>
          </Box>
          <Grid container columnSpacing={5} mt={{ xs: 3, tl: 4 }}>
            <Grid item xs={12} lg={6}>
              <GymLeaderboards title="Season Raid Points" data={_seasonRaidPoints} type="team" />
            </Grid>
            <Grid item xs={12} lg={6} mt={{ xs: 4, lg: 0 }}>
              <GymLeaderboards
                title="Team Workouts Completed"
                data={_completedTeamWorkouts}
                type="team"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default BoiseLeaders;
