import { Link } from 'react-router-dom';
import { bubble as Menu } from 'react-burger-menu';
import { Box } from '@mui/material';

import { BBVR_SITE_URL } from '../../config/constants';

import { Heading4 } from '../Heading';

const RightSideMenu = () => {
  return (
    <Box className="mobile-menu" sx={{ display: { xs: 'flex', lg: 'none' } }}>
      <Menu right isOpen={false}>
        <Box p={4} sx={{ '& h4': { mb: 2 } }}>
          <Link to={`${BBVR_SITE_URL}/locations/`}>
            <Heading4>Gym Locations</Heading4>
          </Link>
          <Link to={`${BBVR_SITE_URL}/the-game/`}>
            <Heading4>The Game </Heading4>
          </Link>
          <Link
            to={`${BBVR_SITE_URL}/research-update-the-surprising-exercise-science-behind-black-box-vr/`}
          >
            <Heading4>The Workout</Heading4>
          </Link>
          <Link to={`${BBVR_SITE_URL}/the-hardware/`}>
            <Heading4>The Hardware</Heading4>
          </Link>
          <Link to={`${BBVR_SITE_URL}/blog/`}>
            <Heading4>News</Heading4>
          </Link>
          <Link to={`${BBVR_SITE_URL}/about-us/`}>
            <Heading4>About</Heading4>
          </Link>
          {/* <Link to="">
            <Heading4>Community</Heading4>
          </Link> */}
          <Link to={`${BBVR_SITE_URL}/shop/`}>
            <Heading4>Shop</Heading4>
          </Link>
          <Link to={`${BBVR_SITE_URL}/locations/`}>
            <Heading4>Try For $1!</Heading4>
          </Link>
        </Box>
      </Menu>
    </Box>
  );
};

export default RightSideMenu;
