import { Box, Divider, Typography } from '@mui/material';
import React from 'react';

import { Heading3 } from '../../../../components/Heading';

const CellDivider = ({ sx }) => (
  <Divider
    orientation="vertical"
    sx={{ borderColor: '#B1B1B1', height: { xs: 56, tl: 83 }, ...sx }}
  />
);

const BattleOverview = ({ deckDetails }) => {
  const data = [
    { title: 'Current Favorite Card', value: deckDetails?.favoriteChampion ?? '' },
    { title: 'Cards Found', value: `${deckDetails?.cardsFound ?? ''}` },
    { title: 'Favorite Powerup', value: deckDetails?.favoritePowerup ?? '' },
  ];

  return (
    <Box
      backgroundColor="#141516"
      display="flex"
      justifyContent={{ xs: 'flex-start', tl: 'space-around' }}
      alignItems="center"
      flexWrap={{ xs: 'wrap', tl: 'unset' }}
      p={{ xs: '32px 24px', tl: 4, md: 5 }}
      mt={{ xs: 4, md: 6 }}
      textAlign="center"
      sx={(theme) => ({
        [theme.breakpoints.down('tl')]: {
          '& > div': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',

            '& p': {
              maxWidth: 124,
              minHeight: 42,
            },
          },
        },
      })}
    >
      <Box sx={{ width: { xs: '50%', tl: 'auto' } }}>
        <Typography fontSize={14} mb={1}>
          {data[0].title}
        </Typography>
        <Heading3>{data[0].value}</Heading3>
      </Box>
      <CellDivider />
      <Box sx={{ width: { xs: '49%', tl: 'auto' } }}>
        <Typography fontSize={14} mb={1}>
          {data[1].title}
        </Typography>
        <Heading3>{data[1].value}</Heading3>
      </Box>
      <CellDivider sx={{ display: { xs: 'none', tl: 'block' } }} />
      <Box sx={{ width: { xs: '50%', tl: 'auto' }, mt: { xs: 5, tl: 0 } }}>
        <Typography fontSize={14} mb={1}>
          {data[2].title}
        </Typography>
        <Heading3>{data[2].value}</Heading3>
      </Box>
    </Box>
  );
};

export default BattleOverview;
