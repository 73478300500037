import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

const StyledLoadingButton = styled(LoadingButton)(() => ({
  '&.Mui-disabled': {
    color: 'grey',
    border: '1px solid grey',

    '& .MuiLoadingButton-loadingIndicator': {
      color: '#ffffff',
    },
  },
  '&.MuiLoadingButton-loading': {
    color: 'transparent !important',
  },
  '&:hover': {
    backgroundColor: '#1565c0',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}));

export const CustomButton = ({ children, sx, variant = 'contained', ...rest }) => {
  return (
    <StyledLoadingButton
      variant={variant}
      sx={{
        background: '#1976d2',
        height: 48,
        px: 4,
        borderRadius: '48px',
        color: '#F5F5F5',
        fontSize: 16,
        fontWeight: 700,
        letterSpacing: '-0.406px',
        textTransform: 'none',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </StyledLoadingButton>
  );
};
