import { Box, Typography, IconButton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from 'react';

import { Heading1, Heading3 } from '../../../components/Heading';
import { HtmlTooltip, TooltipContent } from '../../../components/Tooltip';
import { formatCompactNumber } from '../../../utils';
import QuestionIcon from '../../../assets/img/stats/question-icon.png';
import BackgroundImage from '../../../assets/img/stats/season-bg.webp';

const SeasonStats = ({
  seasonStats,
}) => {
  const [open, setOpen] = useState([false, false]);

  const handleTooltipClose = (idx) => {
    const newOpen = [ ...open ];
    newOpen[idx] = false;
    setOpen(newOpen);
  };

  const handleTooltipOpen = (idx) => {
    const newOpen = [ ...open ];
    newOpen[idx] = true;
    setOpen(newOpen);
  };

  return (
    <Box mt={5} mx={{ xs: 2, tl: 0 }}>
      <Heading1>SEASON XP PROGRESS</Heading1>
      <Typography sx={{
        marginTop: 4,
        fontWeight: 400,
        fontSize: 16,
        width: {
          xs: "100%",
          tl: "824px"
        }
      }}>
        Earn Season XP by completing workout quests. Strive to achieve 100% and earn the top reward! This shows your consistency with your workouts, which is the true secret to fitness success.
      </Typography>
      <Box sx={{
        display: "flex",
        flexDirection: {
          xs: "column",
          tl: "row",
        },
        marginTop: 6,
      }}>
        <Box sx={{
          width: {
            xs: "100%",
            tl: "60%",
          },
          margin: "auto",
          textAlign: "center",
          background: `url(${BackgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          py: { xs: 3, tl: 2 },
        }}>
          <img
            src={seasonStats?.current_season_flag}
            alt="Season Stats"
            loading="lazy"
            width="100%"
            style={{
              maxWidth: "402px"
            }}
          />
        </Box>
        <Box sx={{
          width: {
            xs: "100%",
            tl: "40%",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginLeft: {
            xs: 0,
            tl: 2,
          },
          marginTop: {
            xs: 2,
            tl: 0,
          },
        }}>
          <Typography sx={{
            fontWeight: 700,
            fontSize: 24,
          }}>SEASON {seasonStats?.current_season}</Typography>
          <ClickAwayListener onClickAway={() => handleTooltipClose(0)}>
            <Box sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
              <Box sx={{
                borderLeft: "1px solid #B1B1B1",
                paddingLeft: 3,
              }}>
                <Typography sx={{
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "24px",
                  marginRight: 1,
                }}>Season XP
                </Typography>
              </Box>
              <HtmlTooltip
                title={<TooltipContent title={""} desc={`Earn XP by completing quests! The more consistent you are with your weekly workouts, the more quests you’ll be able to complete, helping you to accumulate XP.<br /> Lifetime XP is the total amount of XP you’ve earned since starting at Black Box VR. Season XP is the amount of XP you’ve earned this Season. Earn Season XP to unlock Season rewards like t-shirts, hats, game cosmetics, Gear credits, flags, and more! The motivation to consistently work out never stops at Black Box VR.`} />}
                placement={"bottom"}
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={handleTooltipClose}
                open={open[0]}
                toolipSize="small"
              >
                <IconButton onClick={() => handleTooltipOpen(0)} sx={{ color: '#ffffff' }}>
                  <img src={QuestionIcon} alt="season xp" style={{ width: "16px" }} />
                </IconButton>
              </HtmlTooltip>
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                marginLeft: 3,
              }}>
                <Heading3 sx={{ fontWeight: 800 }}>{formatCompactNumber(seasonStats?.current_sxp)}</Heading3>
                <Typography sx={{
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "24px",
                }}>&nbsp;/&nbsp;{formatCompactNumber(seasonStats?.max_season_xp)} ({seasonStats?.percentage_of_max}%)
                </Typography>
              </Box>
            </Box>
          </ClickAwayListener>
          <ClickAwayListener onClickAway={() => handleTooltipClose(1)}>
            <Box sx={{
              marginTop: 3,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
              <Box sx={{
                borderLeft: "1px solid #B1B1B1",
                paddingLeft: 3,
              }}>
                <Typography sx={{
                  fontWeight: 400,
                  fontSize: 16,
                  lineHeight: "24px",
                  marginRight: 1,
                }}>Trophies Gained
                </Typography>
              </Box>
              <HtmlTooltip
                title={<TooltipContent title={""} desc={"Win workout battles to earn trophies! The number of trophies you get is determined by how many points you beat your competitor by. But be careful! If you lose your battle, you lose trophies. How many trophies can you gain this Season?"} />}
                placement={"bottom"}
                arrow
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={handleTooltipClose}
                open={open[1]}
                toolipSize="small"
              >
                <IconButton onClick={() => handleTooltipOpen(1)} sx={{ color: '#ffffff' }}>
                  <img src={QuestionIcon} alt="season xp" style={{ width: "16px" }} />
                </IconButton>
              </HtmlTooltip>
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                marginLeft: 3,
              }}>
                <Heading3 sx={{ fontWeight: 800 }}>{formatCompactNumber(seasonStats?.trophies_earned)}</Heading3>
              </Box>
            </Box>
          </ClickAwayListener>
        </Box>
      </Box>
    </Box>
  );
};

export default SeasonStats;
