import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import isEmpty from 'lodash/isEmpty';

import { CustomTab, CustomTabs, TabPanel } from './CustomTabs';
import TabDataContent from './TabDataContent';

const DesktopTabs = ({ gender, tabsData, exerciseChartsData }) => {
  const theme = useTheme();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Box sx={{ borderTop: { md: '1px solid #B1B1B1' }, mt: { xs: 4, md: 5 }, mb: 2 }}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {tabsData.map((d, index) =>
          !isEmpty(exerciseChartsData[d.id].details) ? (
            <CustomTab key={index} title={d.title} subTitle={d.subTitle} iconImage={d.icon} />
          ) : (
            <></>
          )
        )}
      </CustomTabs>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            background: '#141516',
            width: '100vw',
            height: '100%',
            top: 0,
            left: { xs: '-8vw', xxl: 'calc((1228px - 100vw) / 2)' },
            right: 0,
          }}
        />
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {tabsData.map((d, index) =>
            !isEmpty(exerciseChartsData[d.id].details) ? (
              <TabPanel key={index} value={value} index={index} dir={theme.direction}>
                <TabDataContent
                  title={d.title}
                  subTitle={d.subTitle}
                  iconImage={d.icon}
                  photo={d.photo}
                  gender={gender}
                  details={exerciseChartsData[d.id]}
                />
              </TabPanel>
            ) : (
              <></>
            )
          )}
        </SwipeableViews>
      </Box>
    </Box>
  );
};

export default DesktopTabs;
