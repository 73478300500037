import { Box } from "@mui/material";
import { CustomButton } from "../../../components/Buttons";

const CallToAction = ({ username }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      py={20}
    >
      <CustomButton
        href={`/member/${username}`}
        sx={{
          backgroundCOlor: '#0076FF'
        }}
      >
        View Profile Main Page
      </CustomButton>
    </Box>
  );
}

export default CallToAction;
