import axios from 'axios';
import useSWR from 'swr';

import useApiResponse from '../hooks/useApiResponse';

const athenaApi = axios.create({
  baseURL: process.env.REACT_APP_MAIN_API_URL,
});

athenaApi.interceptors.request.use((config) => {
  config.headers['x-api-key'] = process.env.REACT_APP_MAIN_X_API_KEY;

  return config;
});

athenaApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response);

    return Promise.reject(error.response || 'Something went wrong');
  }
);

export default athenaApi;

export function getCacheKey(url, params) {
  const cacheKey = [url];
  if (params) {
    cacheKey.push(JSON.stringify(params));
  }

  return cacheKey;
}

export function useAthenaApi(url, params, config) {
  const { data, mutate, error } = useSWR(url ? getCacheKey(url, params) : null, async () => {
    const { data } = await athenaApi.get(url, { params, ...config });
    return { data, isLoading: false };
  });

  const cachedResponse = useApiResponse(data);

  return {
    data: cachedResponse || { isLoading: url ? true : false },
    mutate,
    error,
  };
}
