import { useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import {
  Heading2,
  Heading3,
  Heading4,
} from '../../../components/Heading';
import Loading from '../../../components/Loading';
import LocalImage from '../../../components/LocalImage';
import PlaceholderImg from '../../../assets/img/placeholder-img.webp';
import { callBackbonePostApi } from '../../../services/backboneApiService';

const GemsStore = () => {
  const [ gemStore, setGemStore ] = useState(null);

  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    callBackbonePostApi({
      action: "getCurrentGemStoreItems",
    }, '')
    .then((data) => {
      if (data?.status === 200 && data?.data) {
        setGemStore(data?.data);
      }
      setIsLoading(false);
    })
    .catch(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Container sx={{ marginTop: 5 }}>
      <Heading2>UNITY GEMS STORE</Heading2>
      <Typography sx={{
        fontSize: "16px",
        lineHeight: "26px",
        fontWeight: 400,
        marginTop: 3,
        marginBottom: 3,
      }}>
        Unity Gems can be used for to unlock special game content like Powerups, Gear items, Team boosts, and even IRL items like shirt, hats, and supplements!
      </Typography>
      <LocalImage src="img/unity-gems/unity-gems-store.webp" alt="Unity Gems Store" loading="lazy" width="100%" />
      <Heading4 sx={{ marginTop: 3, marginBottom: 3 }}>EXAMPLE ITEMS</Heading4>
      <Typography sx={{
        fontSize: "16px",
        lineHeight: "26px",
        fontWeight: 400,
        marginTop: 3,
        marginBottom: 3,
      }}>
        Check the Store section of the Black Box VR mobile app to see the current selection for your gym location.
      </Typography>
      <Box sx={{ marginTop: 3 }}>
        {!!isLoading && <Loading />}
        {!isLoading && !!gemStore && <Box>
          <Heading3 sx={{ marginTop: 5, marginBottom: 4 }}>GAME CONTENT</Heading3>
          <Grid container spacing={3}>
            {(gemStore?.gameContent || []).filter(item => !!item).map((item, index) => (
              <Grid item key={item.name} md={3} xs={12}>
                <img src={item.imageUrl || PlaceholderImg} width="100%" alt="Game Content Item" />
                <Heading4
                  sx={{
                    marginTop: 3,
                    marginBottom: 1,
                  }}
                >
                  {item.name}
                </Heading4>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  fontWeight: 400,
                }}>
                  {item.cost} Unity Gems
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Heading3 sx={{ marginTop: 5, marginBottom: 4 }}>IRL Items</Heading3>
          <Grid container spacing={3}>
            {(gemStore?.IRL || []).filter(item => !!item).map((item, index) => (
              <Grid item key={item.name} md={3} xs={12}>
                <img src={item.imageUrl || PlaceholderImg} width="100%" alt="Game Content Item" />
                <Heading4
                  sx={{
                    marginTop: 3,
                    marginBottom: 1,
                  }}
                >
                  {item.name}
                </Heading4>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  fontWeight: 400,
                }}>
                  {item.cost} Unity Gems
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>}
      </Box>
    </Container>
  );
};

export default GemsStore;
