import { Box } from '@mui/material';
import { useState, useEffect, useRef } from 'react';

const DynamicFont = ({ defaultFont, maxHeight, content }) => {
  const container = useRef();

  const [font, setFont] = useState(defaultFont);

  useEffect(() => {
    const containerHeight = container.current.clientHeight;

    if (containerHeight > maxHeight && font > 1) {
      setTimeout(() => {
        setFont((f) => f - 1);
      }, 50);
    }
  }, [font, maxHeight]);

  return (
    <Box ref={container} sx={{ fontSize: font }}>
      {content}
    </Box>
  );
};

export default DynamicFont;
