import { useAthenaApi } from './athenaApi';
import amityApi, { useAmityApi } from './amityApi';
import { useWpApi } from './wpApi';
import backboneApi from './backboneApi';

export function useUserDetails(username, queryParam) {
  return useAthenaApi(
    username ? `/getuserdetails?username=${username}${queryParam ? `&${queryParam}` : ''}` : null
  );
}

export function useUserDetailsByUserId(userid, queryParam) {
  return useAthenaApi(
    userid ? `/getuserdetails?userid=${userid}${queryParam ? `&${queryParam}` : ''}` : null
  );
}

export function useTeamDetails(type, name) {
  return useAthenaApi(name ? `/getteamdetails?${type}=${name}` : null);
}

export function useGymDetails(id) {
  return useAthenaApi(id ? `/getgymdetails?gymid=${id}` : null);
}

export function useFollowInfo(userId) {
  return useAmityApi(userId ? `/users/${userId}/followInfo` : null);
}

export function followMember(id) {
  return amityApi.post(`/me/following/${id}`);
}

export function unfollowMember(id) {
  return amityApi.delete(`/me/following/${id}`);
}

export function useBoiseNewsEvents() {
  return useWpApi('/posts?_fields=id,date,title,link,featured_media&per_page=5');
}

export function sendInvitation(payload) {
  return backboneApi.post('', payload);
}
