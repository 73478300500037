import Cookie from 'js-cookie';

// Get the Amity Bearer token
export function getAmityBearerToken() {
  return Cookie.get('amityBearerToken');
}

export function setAmityBearerToken(token) {
  Cookie.set('amityBearerToken', token, { expires: 30 });
}

export function removeAmityBearerToken() {
  if (getAmityBearerToken()) {
    Cookie.remove('amityBearerToken');
  }
}

// Get the sign up info
export function getSignUpInfo() {
  const info = Cookie.get('signup');

  try {
    return JSON.parse(info);
  } catch (e) {
    return {};
  }
}

export function setSignUpInfo(info) {
  if (info) {
    Cookie.set('signup', JSON.stringify(info), { expires: 1 });
  }
}

export function removeSignUpInfo() {
  if (getSignUpInfo()) {
    Cookie.remove('signup');
  }
}

// Get the user info logged in
export function getUser() {
  const user = Cookie.get('user') || sessionStorage.getItem('user');

  try {
    return JSON.parse(user);
  } catch (e) {
    return null;
  }
}

export function setUser(user, rememberMe) {
  if (user) {
    if (rememberMe) {
      Cookie.set('user', JSON.stringify(user), { expires: 30 });
    } else {
      sessionStorage.setItem('user', JSON.stringify(user));
    }
  }
}

export function removeUser() {
  if (getUser()) {
    Cookie.remove('user');
    sessionStorage.removeItem('user');
  }
}
