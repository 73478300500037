import { Box, Hidden } from "@mui/material";
import { Heading2 } from "../../../components/Heading";
import { formatCompactNumber } from "../../../utils";
import Description from "./Description";

const Progress = ({
  data
}) => {
  const percentage =
    data?.currentWeek?.weeklyRecap?.seasonXpProgress?.current > data?.currentWeek?.weeklyRecap?.seasonXpProgress?.max
      ? 1
      : data?.currentWeek?.weeklyRecap?.seasonXpProgress?.current / data?.currentWeek?.weeklyRecap?.seasonXpProgress?.max;
  return (
    <Box sx={{
      width: { xs: '100%', tl: '50%' },
      textAlign: { xs: 'center !important', tl: 'left !important' }
    }}>
      <Heading2>YOUR PROGRESS</Heading2>
      <Box sx={{
        display: "flex",
        flexDirection: { xs: 'column', tl: 'row' },
        marginTop: 2,
        height: '360px',
      }}>
        <Hidden implementation="css" tlUp>
          <Box sx={{
            width: '360px',
            height: '75px',
            borderRadius: '40px',
            backgroundColor: '#4A4F52',
            margin: { xs: 'auto', xl: '0 32px' },
            position: 'relative',
            zIndex: 0
          }}>
            <Box sx={{
              borderRadius: '40px',
              background: "linear-gradient(to bottom, #925F01 -45.45%, #C3A128 8.33%, #FEF89D 39.47%, #F4CE02 100%)",
              position: 'absolute',
              height: '100%',
              left: 0,
              width: `${percentage * 360}px`,
              zIndex: 3
            }} />
          </Box>
        </Hidden>
        <Hidden implementation="css" tlDown>
          <Box sx={{
            width: '75px',
            borderRadius: '40px',
            backgroundColor: '#4A4F52',
            margin: { xs: '', xl: '0 32px' },
            position: 'relative',
            zIndex: 0,
            height: '100%'
          }}>
            <Box sx={{
              borderRadius: '40px',
              background: "linear-gradient(to right, #925F01 -45.45%, #C3A128 8.33%, #FEF89D 39.47%, #F4CE02 100%)",
              position: 'absolute',
              width: '100%',
              top: `${360 - percentage * 360}px`,
              height: `${percentage * 360}px`,
              zIndex: 3
            }} />
          </Box>
        </Hidden>
        <Box sx={{
          margin: '60px 16px',
          height: '180px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flexGrow: 1,
          padding: 0,
          gap: 3
        }}>
          <Description
            title="Season XP Earned"
            data={formatCompactNumber(data?.currentWeek?.weeklyRecap?.seasonXpProgress?.current)}
            tooltip="Season XP (Experience Points) is a vital component of the game, designed to track and reward your progress throughout a season! Earn Season XP by completing workout quests."
          />
          <Description
            title="100% Goal"
            data={formatCompactNumber(data?.currentWeek?.weeklyRecap?.seasonXpProgress?.max)}
            tooltip="Aim to achieve the 100% Goal number for Season XP and you’ll earn the top rewards!  You’ll also get the best fitness results."
          />
          <Description
            title="Season XP Percent"
            data={(data?.currentWeek?.weeklyRecap?.seasonXpProgress?.current / data?.currentWeek?.weeklyRecap?.seasonXpProgress?.max * 100).toFixed(0)} percent={true}
            tooltip="What percent of the 100% Goal for the season are you currently at? Aim to get to 100% or higher by the end of the season!"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Progress;
