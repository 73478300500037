import { Box, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export const HtmlTooltip = ({ toolipSize, noSubject, ...props }) => (
  <Tooltip
    classes={{
      popper: `achieve-tooltip ${toolipSize === 'large' ? 'large' : ''} ${
        noSubject ? 'top-padding' : ''
      }`,
    }}
    {...props}
  />
);

export const TooltipContent = ({ title, desc, sx }) => {
  return (
    <Box sx={sx}>
      <Typography sx={{ fontWeight: 700, fontSize: 24, textAlign: 'center' }}>{title}</Typography>
      <Typography
        sx={{ fontWeight: 400, fontSize: 16 }}
        dangerouslySetInnerHTML={{ __html: desc }}
      />
    </Box>
  );
};
