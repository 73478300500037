import { Box } from '@mui/material';

import { BBVR_SITE_URL } from '../../../../config/constants';

import FooterBanner from '../../../../components/Footer/FooterBanner';
import FooterMenu from '../../../../components/Footer/FooterMenu';
import { Heading1 } from '../../../../components/Heading';
import { CustomButton } from '../../../../components/Buttons';

const MemberProfileFooter = () => {
  return (
    <Box>
      <FooterBanner />
      <Box mt={{ xs: 8, tl: 10, md: 16 }} mb={{ xs: 6, md: 16 }} textAlign="center">
        <Heading1>Want to Dig Deeper?</Heading1>
        <CustomButton
          variant="outlined"
          href={`${BBVR_SITE_URL}/research-update-the-surprising-exercise-science-behind-black-box-vr`}
          sx={{ mt: 3 }}
        >
          Learn more about the workout
        </CustomButton>
      </Box>
      <FooterMenu />
    </Box>
  );
};

export default MemberProfileFooter;
