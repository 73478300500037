import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer/FooterMenu';
import { Heading2 } from '../../components/Heading';
import FullScreenBox from '../../components/Layout/FullScreenBox';
import Container from '../../components/Layout/Container';
import DetailsSection from './Details';
import YourSeasonRecap from './SeasonRecap';
import PerformanceAnalysis from './PerformanceAnalysis';
import SeasonStats from './SeasonStats';
import Overview from './Overview';
import LeagueRanking from '../Profiles/MemberProfile/LeagueRanking';

import { useUserDetails } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';

const SeasonRecap = () => {
  const { username, season } = useParams();
  const {
    data: { data: userDetails, isLoading: isLoadingUserDetails },
    error,
  } = useUserDetails(username, null);
  const [ isRecapLoading, setIsLoading ] = useState(true);
  const [ data, setData ] = useState(null);

  useEffect(() => {
    if (!!userDetails?.results?.user_id) {
      callBackbonePostApi({
        action: "getUserSeasonRecap",
        userId: userDetails?.results?.user_id
      }, '')
      .then((data) => {
        setIsLoading(false);

        if (data?.status === 200 && data?.data) {
          setData(data?.data);
        }
      })
      .catch(() => {
        setIsLoading(true);
      });
    }
  }, [userDetails]);

  const isLoading = !userDetails || isLoadingUserDetails || isRecapLoading;

  const profileAggregates = userDetails?.results?.profile_aggregates;
  const userProfileInfo = userDetails?.results?.user_profile_info
    ? userDetails.results.user_profile_info[0]
    : {};

  const userId = userDetails?.results?.user_id;
  const isPublicProfile = userProfileInfo.publicProfileAllowed;
  const gymInfo = { id: userProfileInfo.gym_id, name: userProfileInfo.gym_name };
  const userImage = userProfileInfo.profile_pic_url;
  const xpTitle = userDetails?.results?.xp_title ?? '';
  const inviteState = userDetails?.results?.invite_button_state;
  const currentLeague = parseInt(userProfileInfo.league?.N ?? 0);
  const displayTrophyCount = userProfileInfo.trophies;
  const achievements = userDetails?.results?.recent_achievements;

  const breadcrumbs = [
    { title: 'Home', link: 'https://www.blackbox-vr.com' },
    { title: 'Community', link: '/' },
    { title: 'Meridian Gym', link: '/gym/2/Meridian' },
    { title: 'Members', link: '/' },
    { title: username.charAt(0).toUpperCase() + username.slice(1).toLowerCase(), link: `/member/${username}` },
    { title: 'Recaps' },
    { title: `Season ${data?.seasonId} Recap` }
  ];

  if (error) {
    return (
      <FullScreenBox>
        <Heading2>Member not found</Heading2>
      </FullScreenBox>
    );
  }

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}'s Season ${season} Recap!`}
        description={`Black Box VR - ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}'s Season ${season} Recap!`}
      />
      {isLoading ? (
        <Loading fullscreen />
      ) : (
        <>
          <DetailsSection
            username={username}
            userImage={userImage}
            userId={userId}
            xpTitle={xpTitle}
            currentLeague={currentLeague}
            displayTrophyCount={displayTrophyCount}
            details={profileAggregates}
            achievements={achievements}
            gymInfo={gymInfo}
            isPublicProfile={isPublicProfile}
            inviteState={inviteState}
            breadcrumbs={breadcrumbs}
          />
          <YourSeasonRecap
            data={data}
            season={data?.seasonId}
          />
          <PerformanceAnalysis
            username={username}
            data={data}
          />
          {!!data?.final_trophies?.value && <Container>
            <LeagueRanking
              username={username}
              currentLeague={data?.league?.value}
              displayTrophyCount={data?.final_trophies?.value}
              arenaInLeague={data?.arena?.value}
              title="FINAL SEASON LEAGUE RANKING"
              seasonRecap={data}
              mt={5}
            />
            {userDetails?.results?.season_stats && userDetails?.results?.season_stats?.current_sxp > 0 && <SeasonStats
              seasonStats={{
                current_season_flag: data?.final_season_flag?.value,
                current_season: data?.seasonId,
                current_sxp: data?.final_season_xp?.value,
                max_season_xp: data?.max_season_xp,
                percentage_of_max: data?.percentage_of_max,
                trophies_earned: data?.trophiesearned,
              }}
            />}
          </Container>}
          <Overview username={username} />
        </>
      )}
      <Footer />
    </Box>
  );
};

export default SeasonRecap;
