import { Box, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading2, Heading3 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';

const Item = ({
  title,
  description,
  value,
  order,
}) => {
  return (
    <Box sx={{
      marginBottom: 2,
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: order % 2 === 0 ? "#000000" : "#141516",
    }}>
      <Box>
        <Heading3>{title}</Heading3>
        <Typography sx={{
          fontSize: "16px",
          lineHeight: "26px",
          width: "297px"
        }}>
          {description}
        </Typography>
      </Box>
      <LocalImage
        src={value ? "img/week/check.webp" : "img/week/close.webp"}
        width="120"
        height="120"
        alt="value"
        loading="lazy"
      />
    </Box>
  );
};

const ScoreCard = ({
  data
}) => {
  let greenCount = 0;

  if (data?.currentWeek?.weeklyRecap?.seasonXpProgress?.on_track) {
    greenCount++;
  }

  if (data?.currentWeek?.weeklyRecap?.completedWorkouts >= 3) {
    greenCount++;
  }

  if (data?.currentWeek?.weeklyRecap?.completedAllWeeklyQuests) {
    greenCount++;
  }

  if (data?.currentWeek?.weeklyRecap?.onTeam) {
    greenCount++;
  }

  if (data?.currentWeek?.weeklyRecap?.twelveMembers) {
    greenCount++;
  }

  if (data?.currentWeek?.weeklyRecap?.completedAllDailyQuests) {
    greenCount++;
  }
  
  if (data?.currentWeek?.weeklyRecap?.allVipCodesUsed) {
    greenCount++;
  }

  return (
    <Container sx={{ marginTop: 8 }}>
      <Heading2>YOUR WEEKLY SCORECARD: {greenCount}/8</Heading2>
      <Box sx={{
        marginTop: 4,
        marginBottom: 4,
      }}>
        <Typography sx={{
          fontSize: "16px",
          lineHeight: "26px",
        }}>
          Black Box VR was scientifically designed to give you the best fitness results while making it simple and fun!  All you have to do is play the game and follow our recommendations and your motivation and results will be better than you can imagine.
        </Typography>
      </Box>
      <Box sx={{
        width: {
          xs: "auto",
          tl: "675px",
        }
      }}>
        <Item
          title="FULL BODY?"
          description="Are you doing balanced workouts and not skipping exercises or body parts? Don’t miss out on results!"
          value={data?.currentWeek?.balanced}
          order={0}
        />
        <Item
          title="SEASON XP PROGRESS?"
          description="Are you on track to reach at least 75% of the Season XP reward ladder?"
          value={data?.currentWeek?.weeklyRecap?.seasonXpProgress?.on_track}
          order={1}
        />
        <Item
          title="AT LEAST 3 WORKOUTS?"
          description="Get around 3 - 4 workouts per week, every week, to get the best results! Consistency is the secret to fitness success."
          value={data?.currentWeek?.weeklyRecap?.completedWorkouts >= 3}
          order={2}
        />
        <Item
          title="WEEKLY QUESTS COMPLETED?"
          description="Did you complete all of this week’s personal quests? It makes the battles more fun and you get extra XP!"
          value={data?.currentWeek?.weeklyRecap?.completedAllWeeklyQuests}
          order={3}
        />
        <Item
          title="ON A TEAM?"
          description="Black Box VR is more fun and more addictive when you are a team of supportive people from your gym."
          value={data?.currentWeek?.weeklyRecap?.onTeam}
          order={4}
        />
        <Item
          title="TEAM OF 12?"
          description="Get the most rewards by having a full team of 12 people! Invite others and grow your tribe."
          value={data?.currentWeek?.weeklyRecap?.twelveMembers}
          order={5}
        />
        <Item
          title="DAILY QUESTS COMPLETED?"
          description="Did you get all seven days done? Check in to the app at least once a day to keep your motivation high and to get rewards."
          value={data?.currentWeek?.weeklyRecap?.completedAllDailyQuests}
          order={6}
        />
        <Item
          title="VIP INVITE CARDS USED?"
          description="If you have unlocked invite cards, have they been used? Help other start their fitness journey and earn Unity Gems!"
          value={data?.currentWeek?.weeklyRecap?.allVipCodesUsed}
          order={7}
        />
      </Box>
    </Container>
  );
};

export default ScoreCard;
