import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = ({ fullscreen, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: fullscreen ? { xs: 'calc(100vh - 48px)', lg: 'calc(100vh - 60px)' } : '100%',
        ...sx,
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;
