import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { callBackbonePostApi } from '../../../services/backboneApiService';

import Loading from '../../../components/Loading';
import TopBanner from './TopBanner';

const GymMasterScheduling = () => {
  const { userId } = useParams();

  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState();

  useEffect(() => {
    const autoLoginGymMaster = () => {
      callBackbonePostApi({
        action: 'getGymMasterLoginToken',
        userId,
      })
        .then((response) => {
          setToken(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    };

    autoLoginGymMaster();
  }, [userId]);

  return (
    <Box
      minWidth="100%"
      minHeight={{ xs: 'calc(100vh - 48px)', lg: 'calc(100vh - 60px)' }}
      overflow="hidden"
      sx={{
        background: 'white',
      }}
    >
      {loading ? (
        <Loading fullscreen />
      ) : (
        <>
          {token ? (
            <Box display="flex" flexDirection="column">
              <TopBanner />
              <Box sx={{ height: '120vh' }}>
                <iframe
                  title="Scheduling"
                  width="100%"
                  height="100%"
                  src={`${process.env.REACT_APP_GYMMASTER_URL}/account/book/service?${token}`}
                ></iframe>
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="black"
              position="absolute"
              width="100%"
              height="100%"
            >
              <Typography component="h1" sx={{ fontSize: '2rem', fontWeight: 'bold' }}>
                Error: Invalid Token
              </Typography>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default GymMasterScheduling;
