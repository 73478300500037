import { Box } from '@mui/material';

import BattleOverview from './BattleOverview';
import TeamEquipped from './TeamEquipped';
import { Heading1 } from '../../../../components/Heading';

const BattleDeck = ({ championTeams, deckDetails }) => {
  return (
    <Box mt={{ xs: 8, tl: 10, lg: 16 }} mx={{ xs: 2, tl: 0 }}>
      <Heading1>Battle Deck</Heading1>
      <BattleOverview deckDetails={deckDetails} />
      <TeamEquipped championTeams={championTeams} />
    </Box>
  );
};

export default BattleDeck;
