import { Box, Stack } from '@mui/material';

import { formatCompactNumber } from '../../../../../utils';

import OverviewBoard from '../../../../../components/Overview/OverviewBoard';
import { Heading1 } from '../../../../../components/Heading';
import { CustomButton } from '../../../../../components/Buttons';

const WorkoutSummary = ({ goToPersonalRecords, stats }) => {

  const data = [
    { title: 'Sets', value: formatCompactNumber(stats?.best_setcount?.value) },
    {
      title: 'Reps',
      value: formatCompactNumber(stats?.best_repcount?.value),
      description:
        'A “rep” or repetition is one complete motion of a particular exercise, from the starting position back to the starting position again.',
    },
    {
      title: 'Work (Ft-Lbs)',
      value: formatCompactNumber(stats?.best_totalwork?.value),
      description:
        'Work in foot-pounds (ft.*lbs) is a measure of the energy expended in lifting a weight over a certain distance. It is calculated by multiplying the force exerted on the weight (measured in pounds) by the distance the weight is moved (measured in feet).<br />Unlike volume, work also takes into account the rep length.',
    },
    {
      title: 'Volume (Lbs)',
      value: formatCompactNumber(stats?.best_totalvolume?.value),
      description:
        'In strength training, “volume” refers to the total amount of weight lifted, commonly calculated as the number of sets multiplied by the number of repetitions per set, further multiplied by the weight used for each exercise.<br />Example set: 100 lbs. x 10 reps = 1000 lbs. of volume',
    },
    { title: 'Calories Burned', value: formatCompactNumber(stats?.best_caloriesburned?.value) },
  ];

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={{ xs: 8, tl: 6, md: 16 }} mx={{ xs: 2, tl: 0 }}>
      <Stack
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'start', sm: 'center' }}
        gap={4}
      >
        <Heading1 sx={{ flexGrow: 1 }}>Single Workout Personal Records</Heading1>
        <CustomButton sx={{ flexShrink: 0 }} onClick={goToPersonalRecords}>View All Records</CustomButton>
      </Stack>
      <Stack
        sx={{
          background: '#141516',
          padding: { xs: 4, tl: '32px 76px', md: '40px 64px' },
          mt: 4,
        }}
      >
        <OverviewBoard data={data} />
      </Stack>
    </Box>
  );
};

export default WorkoutSummary;
