import { Box } from "@mui/material";
import { Heading2, Heading4 } from "../../../components/Heading";
import { useState } from "react";
import { HtmlTooltip, TooltipContent } from "../../../components/Tooltip";

const Rewards = ({
  data
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [openFifty, setOpenFifty] = useState(false);
  const [openMax, setOpenMax] = useState(false);

  const handleFiftyTooltipClose = () => setOpenFifty(false);
  const handleMaxTooltipClose = () => setOpenMax(false);

  const handleFiftyImageMouseOver = (e) => {
    setOpenFifty(true);
    setIsHovered(true);
  }
  const handleFiftyImageMouseOut = (e) => {
    setOpenFifty(false);
    setIsHovered(false);
  }

  const handleMaxImageMouseOver = (e) => {
    setOpenMax(true);
    setIsHovered(true);
  }
  const handleMaxImageMouseOut = (e) => {
    setOpenMax(false);
    setIsHovered(false);
  }

  return (
    <Box sx={{
      width: {xs: '80%', tl: '50%'},
      textAlign: {xs: 'center !important', tl: 'left !important'}
    }}>
      <Heading2>REWARDS</Heading2>
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        height: '100%',
        justifyContent: 'space-between',
        gap: 4,
        marginTop: {xs: 6, tl: 14, md: 12, lg: 6, xl: 2}
      }}>
        <Box sx={{
          width: '50%',
          position: 'relative',
          zIndex: 0
        }}>
          <HtmlTooltip
            title={<TooltipContent title={data?.seasonRewards['50%'].name} desc={data?.seasonRewards['50%'].description} />}
            placement='top'
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={handleFiftyTooltipClose}
            open={openFifty}
            followCursor
            classes={{
              popper: `achieve-tooltip dark`,
            }}
          >
            <img
              src={data?.seasonRewards['50%'].iconURL}
              alt="50% Reward icon"
              style={{
                width: '100%',
                aspectRatio: 1, 
                cursor: isHovered ? 'pointer' : ''
              }}
              onMouseOver={handleFiftyImageMouseOver}
              onMouseOut={handleFiftyImageMouseOut}
            />
          </HtmlTooltip>
          <Heading4>{data?.seasonRewards['50%'].name}</Heading4>
        </Box>
        <Box sx={{
          width: '50%',
          position: 'relative',
          zIndex: 0
        }}>
          <HtmlTooltip
            title={<TooltipContent title={data?.seasonRewards['100%'].name} desc={data?.seasonRewards['100%'].description}/>}
            placement='top'
            arrow
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={handleMaxTooltipClose}
            open={openMax}
            followCursor
            classes={{
              popper: `achieve-tooltip dark`,
            }}
          >
            <img
              src={data?.seasonRewards['100%'].iconURL}
              alt="100% Reward icon"
              style={{
                width: '100%',
                aspectRatio: 1, 
                cursor: isHovered ? 'pointer' : ''
              }}
              onMouseOver={handleMaxImageMouseOver}
              onMouseOut={handleMaxImageMouseOut}
            />
          </HtmlTooltip>
          <Heading4>{data?.seasonRewards['100%'].name}</Heading4>
        </Box>
      </Box>
    </Box>
  );
}

export default Rewards;
