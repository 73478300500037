import { Box, Typography } from '@mui/material';

import { Heading, Heading2, Heading3, Heading4 } from '../../../components/Heading';
import { formatCompactNumber, formatLocalizedDate } from '../../../utils';

export const DesktopVideoHeader = ({
  trophies,
  username,
  userScore,
  chalName,
  chalScore,
  startTime,
  matchOutcome,
  isRaidBattle,
  challengerReached,
  raidPointsEarned,
}) => {
  return (<>
    <Box>
      <Heading2>Battle Date: {formatLocalizedDate(startTime, 'LL')}</Heading2>
    </Box>
    <Box
      display={{ xs: 'none', md: 'flex' }}
      justifyContent="space-between"
      alignItems="center"
      mt={1}
    >
      <Box display="flex" alignItems="center">
        <Heading2
          sx={!isRaidBattle ? {
            background: 'linear-gradient(135deg, #FFA400 -5.47%, #FFFE00 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          } : {
            color: "#E9008C",
          }}
        >
          {isRaidBattle ? "ARENA RAID!" : matchOutcome}
        </Heading2>
        {!isRaidBattle && <Typography ml={3}>
          {+trophies > 0 && '+'}
          {formatCompactNumber(trophies)} Trophies
        </Typography>}
      </Box>
      {!isRaidBattle && <Box display="flex" alignItems="center">
        <Heading size={16}>{username}</Heading>
        <Heading2 sx={{ color: '#4ADE80', ml: 2 }}>{userScore}</Heading2>
        <Heading3 sx={{ mx: 3 }}>VS</Heading3>
        <Heading size={16}>{chalName}</Heading>
        <Heading2 sx={{ color: '#FF370B', ml: 2 }}>{chalScore}</Heading2>
      </Box>}
      {!!isRaidBattle && <Box display="flex" alignItems="center">
        <Heading size={16}>CHALLENGER REACHED</Heading>
        <Heading2 sx={{ color: '#4ADE80', mx: 2 }}>{challengerReached}</Heading2>
        <Heading size={16}>RAID POINTS EARNED</Heading>
        <Heading2 sx={{ color: '#FF370B', ml: 2 }}>{raidPointsEarned}</Heading2>
      </Box>}
    </Box>
  </>);
};

export const DesktopVideoInfo = ({
  startTime,
  setCount,
  repCount,
  calories,
  sliceCount,
  championsDeployed,
  totalWork,
}) => {
  return (
    <Box display={{ xs: 'none', md: 'block' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          '& h4': { fontWeight: 800 },
        }}
      >
        <Box>
          <Heading4>Sets: {formatCompactNumber(setCount)}</Heading4>
          <Heading4 sx={{ mt: 2 }}>Reps: {formatCompactNumber(repCount)}</Heading4>
        </Box>
        <Box>
          <Heading4>PunChes/Slices: {formatCompactNumber(sliceCount)}</Heading4>
          <Heading4 sx={{ mt: 2 }}>Caloric Burn: ~{formatCompactNumber(calories)}</Heading4>
        </Box>
        <Box>
          <Heading4>CHAMPIONS DEPLOYED: {formatCompactNumber(championsDeployed)}</Heading4>
          <Heading4 sx={{ mt: 2 }}>Work: {formatCompactNumber(totalWork)} Ft*Lbs</Heading4>
        </Box>
      </Box>
    </Box>
  );
};
