import { Box } from '@mui/material';
import { PostTargetType } from '@amityco/js-sdk';
import { useNavigate, useParams } from 'react-router-dom';

import { useUserDetails, useTeamDetails } from '../../../services/communityService';
import useQuery from '../../../utils/useQuery';
import { capitalizeFirstLetter } from '../../../utils';
import { SOCIAL_SEARCH_TYPE, VIPCODE_STATUS } from '../../../config/constants';

import Container from '../../../components/Layout/Container';
import Loading from '../../../components/Loading';
import { Heading2 } from '../../../components/Heading';
import MetaTags from '../../../components/MetaTags';
import FullScreenBox from '../../../components/Layout/FullScreenBox';
import SocialFeed from '../../../components/SocialFeed';

import DetailsSection from './Details';
import StatsSection from './Stats';
import Team from './Team';
import LeagueRanking from './LeagueRanking';
import SeasonStats from './SeasonStats';
import BattleDeck from './BattleDeck';

import MemberProfileFooter from './Footer';
import { useEffect, useState } from 'react';
import { callBackbonePostApi } from '../../../services/backboneApiService';

const MemberProfile = () => {
  const { username, vipCode } = useParams();
  const navigate = useNavigate();
  const queryParameters = useQuery();
  const viewerId = queryParameters.get('userId');

  const userData = useUserDetails(username, viewerId ? `viewerid=${viewerId}` : null);
  const {
    data: { data: userDetails, isLoading: isLoadingUserDetails },
    error,
  } = userData;

  const {
    data: { data: teamDetails, isLoading: isLoadingTeamDetails },
  } = useTeamDetails('username', userDetails ? username : null);

  const isLoading = !userDetails || isLoadingUserDetails;
  const [personalRecords, setPersonalRecords] = useState({});
  const [allPersonalRecords, setAllPersonalRecords] = useState({});
  const [codeStatus, setCodeStatus] = useState();

  useEffect(() => {
    if (userDetails?.results?.user_id) {
      getSingleWorkoutPersonalRecords(userDetails?.results?.user_id);
      getAllPersonalRecords(userDetails?.results?.user_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails?.results?.user_id]);

  // Checking if vip-code is valid
  useEffect(() => {
    if (vipCode) {
      checkValidityVipCode(vipCode);
    }
  }, [vipCode]);

  if (error) {
    return (
      <FullScreenBox>
        <Heading2>Member not found</Heading2>
      </FullScreenBox>
    );
  }

  const checkValidityVipCode = async (code) => {
    const vipCodeStatusResponse = await callBackbonePostApi({
      action: "validateInviteCode",
      inviteCode: code,
    }, '');

    let vipCodeStatus;
    switch (vipCodeStatusResponse?.data) {
      case 0:
        vipCodeStatus = VIPCODE_STATUS.VALID;
        break;
      case 2:
        vipCodeStatus = VIPCODE_STATUS.EXPIRED;
        break;
      case 1:
      case 3:
      default:
        vipCodeStatus = VIPCODE_STATUS.INVALID;
        break;
    }

    setCodeStatus(vipCodeStatus);
  }

  const getSingleWorkoutPersonalRecords = async (userId) => {
    const recordsResponse = await callBackbonePostApi({
      action: "getUserPersonalBests",
      userId: userId,
    }, '');
    setPersonalRecords(recordsResponse?.data?.personalBests);
  }

  const getAllPersonalRecords = async (userId) => {
    const allRecords = await callBackbonePostApi({
      action: "getAllPersonalBestsForAUser",
      userId: userId,
    }, '');
    setAllPersonalRecords(allRecords?.data);
  }

  const profileAggregates = userDetails?.results?.profile_aggregates;
  const userProfileInfo = userDetails?.results?.user_profile_info
    ? userDetails.results.user_profile_info[0]
    : {};

  const userId = userDetails?.results?.user_id;
  const isPublicProfile = userProfileInfo.publicProfileAllowed;
  const gymInfo = { id: userProfileInfo.gym_id, name: userProfileInfo.gym_name };
  const userImage = userProfileInfo.profile_pic_url;
  const appLoginUserId = viewerId || userId;
  const xpTitle = userDetails?.results?.xp_title ?? '';
  const inviteState = userDetails?.results?.invite_button_state;
  const currentLeague = parseInt(userProfileInfo.league?.N ?? 0);
  const displayTrophyCount = userProfileInfo.trophies;
  const arenaInLeague = userProfileInfo.arena?.N;
  const achievements = userDetails?.results?.recent_achievements;
  const stats = {
    lifetimeXp: userDetails?.results?.currencies?.XP,
    memberSince: profileAggregates?.member_start_date,
    heroLevel: Math.floor(userProfileInfo.hero_level?.N ?? 0),
    arena: userProfileInfo.arena?.N,
    streak: userProfileInfo.streak?.N,
    trophies: userProfileInfo.trophies,
    seasonEndDate: userDetails?.results?.season_end_date,
    averageVolume: profileAggregates?.avg_volume,
    averageWork: profileAggregates?.avg_work,
    totalReps: profileAggregates?.total_reps,
    totalSets: profileAggregates?.total_sets,
    totalCalories: profileAggregates?.total_calories,
    workoutCount: profileAggregates?.workout_count,
    totalWork: profileAggregates?.total_work,
    totalVolume: profileAggregates?.total_volume,
  };
  const userPersonalInfo = userDetails?.results?.user_info
    ? userDetails?.results?.user_info[0]
    : {};
  const videoData = userDetails?.results?.last_session_info;
  const showVideo = userProfileInfo.showLastSessionInfo;

  const _volumeOverTime = profileAggregates?.volume_over_time;
  const volumeOverTime =
    _volumeOverTime && typeof _volumeOverTime === 'string' ? JSON.parse(_volumeOverTime) : {};

  const _workOverTime = profileAggregates?.work_over_time;
  const workOverTime =
    _workOverTime && typeof _workOverTime === 'string' ? JSON.parse(_workOverTime) : {};

  const _ormsOverTime = profileAggregates?.orms_over_time;
  const ormsOverTime =
    _ormsOverTime && typeof _ormsOverTime === 'string' ? JSON.parse(_ormsOverTime) : {};

  const exerciseChartsData = {
    chestPress: { details: profileAggregates?.chest_press_details, chart: ormsOverTime.ChestPress },
    standingRow: {
      details: profileAggregates?.standing_cable_row_details,
      chart: ormsOverTime.StandingCableRow,
    },
    overheadPress: {
      details: profileAggregates?.overhead_press_details,
      chart: ormsOverTime.OverheadPress,
    },
    stiffLeg: {
      details: profileAggregates?.stiff_leg_deadlift_details,
      chart: ormsOverTime.StiffLegDeadlift,
    },
    squat: { details: profileAggregates?.cable_squat_details, chart: ormsOverTime.CableSquat },
    latPulldown: {
      details: profileAggregates?.lat_pulldown_details,
      chart: ormsOverTime.LatPulldown,
    },
  };
  const team = {
    teamName: teamDetails?.results?.team_name,
    teamIcon: teamDetails?.results?.team_icon,
    teamDescription: teamDetails?.results?.team_description,
    teamCaptain: teamDetails?.results?.team_captain,
    members:
      teamDetails?.results?.members_info?.map((m) => ({
        id: m.member_id,
        username: m.member_username,
        image: m.member_profile_pic_url,
      })) || [],
  };
  const battleDeckInfo = profileAggregates?.battle_deck_with_info ?? {};
  const championTeams = Object.keys(battleDeckInfo).map((code) => {
    return {
      code,
      element: parseInt(battleDeckInfo[code]?.element),
      level: battleDeckInfo[code]?.level,
    };
  });
  const deckDetails = {
    favoriteChampion: profileAggregates?.favorite_champion,
    cardsFound: userProfileInfo.cards_found,
    favoritePowerup: profileAggregates?.favorite_powerup,
  };

  const goToPersonalRecordsPage = () => {
    const navigationData = {
      user: { data: userData?.data, error: userData?.error },
      records: allPersonalRecords
    };

    navigate(`/member/${username}/records`, { state: navigationData });
  }

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - ${capitalizeFirstLetter(username)}’s Athlete Profile`}
        description={`Check out ${capitalizeFirstLetter(username)}’s progress at Black Box VR!`}
      />
      {isLoading ? (
        <Loading fullscreen />
      ) : (
        <>
          <DetailsSection
            username={username}
            userImage={userImage}
            userId={userId}
            xpTitle={xpTitle}
            currentLeague={currentLeague}
            displayTrophyCount={displayTrophyCount}
            details={profileAggregates}
            achievements={achievements}
            gymInfo={gymInfo}
            isPublicProfile={isPublicProfile}
            inviteState={inviteState}
            onShowChat={() => window.location.href = (`/chat/${appLoginUserId}`)}
            isLoading={isLoading}
            vipCode={vipCode}
            vipCodeStatus={codeStatus}
          />
          {isPublicProfile && (
            <>
              <StatsSection
                stats={stats}
                videoData={videoData}
                showVideo={showVideo}
                volumeOverTime={volumeOverTime}
                workOverTime={workOverTime}
                exerciseChartsData={exerciseChartsData}
                gender={userPersonalInfo.gender}
                personalRecordData={personalRecords}
                goToPersonalRecords={goToPersonalRecordsPage}
              />
              <Container>
                {!teamDetails?.results?.error && (
                  <Team team={team} isLoading={isLoadingTeamDetails} />
                )}
                <LeagueRanking
                  username={username}
                  currentLeague={currentLeague}
                  displayTrophyCount={displayTrophyCount}
                  arenaInLeague={arenaInLeague}
                  title="CURRENT LEAGUE RANKING"
                />
                {userDetails?.results?.season_stats && userDetails?.results?.season_stats?.current_sxp > 0 && <SeasonStats
                  seasonEndDate={userDetails?.results?.season_end_date}
                  seasonStats={userDetails?.results?.season_stats}
                />}
                {championTeams.length > 0 && (
                  <BattleDeck championTeams={championTeams} deckDetails={deckDetails} />
                )}
              </Container>
              {!!appLoginUserId && (
                <SocialFeed
                  userId={appLoginUserId}
                  targetType={PostTargetType.CommunityFeed}
                  searchType={SOCIAL_SEARCH_TYPE.USER}
                  showTargetId={userId}
                  sx={{ mt: { xs: 10, md: 16 } }}
                />
              )}
            </>
          )}
          <MemberProfileFooter />
        </>
      )}
    </Box>
  );
};

export default MemberProfile;
