import { Box, Grid } from '@mui/material';

import Container from '../../../../components/Layout/Container';
import { Heading1 } from '../../../../components/Heading';
import TrainerCard from './TrainerCard';

const HeroTrainers = ({ trainers }) => {
  return (
    <Container>
      <Box mt={{ xs: 8, tl: 10, lg: 16 }} mx={{ xs: 2, tl: 0 }}>
        <Heading1>Your Dedicated Hero Trainers</Heading1>
        <Box mt={{ xs: 3, tl: 4, lg: 6 }}>
          <Grid container columnSpacing={2}>
            {Object.keys(trainers).map((username, index) => (
              <Grid key={index} item xs={6} sm={4} tl={3} lg={2}>
                <TrainerCard
                  data={{
                    username,
                    fullName: trainers[username]?.FullName,
                    jobTitle: trainers[username]?.JobTitle,
                    image: trainers[username]?.ProfilePicURL,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default HeroTrainers;
