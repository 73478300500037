import { Box } from '@mui/material';

import { Heading5 } from '../../../../../components/Heading';

import BronzeIcon from '../../../../../assets/img/ranking/bronze.png';
import SilverIcon from '../../../../../assets/img/ranking/silver.png';
import GoldIcon from '../../../../../assets/img/ranking/gold.png';
import PlatinumIcon from '../../../../../assets/img/ranking/platinum.png';
import DiamondIcon from '../../../../../assets/img/ranking/diamond1.png';
import ChampionIcon from '../../../../../assets/img/ranking/champion1.png';
import ProIcon from '../../../../../assets/img/ranking/pro1.png';

const listDeskopStyle = {
  justifyContent: 'space-between',

  '& > div': {
    width: {
      xs: 'calc((100% - 48px) / 7)',
      lg: 'calc((100% - 96px) / 7)',
      xl: 'calc((100% - 144px) / 7)',
    },

    '& > div': {
      position: 'relative',
      width: '100%',
      height: 192,
      mb: 3,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',

      '& > div': {
        position: 'absolute',
        background: 'linear-gradient(180deg, #000 0%, #1E1E1E 100%)',
        width: '100%',
        bottom: 0,
      },

      '& img': {
        width: 64,
        height: 64,
        zIndex: 1,
      },

      '&.active': {
        '& > div': {
          '&.bronze': {
            background: 'linear-gradient(180deg, rgba(173, 118, 61, 0.00) 1.35%, #AD763D 100%)',
          },
          '&.silver': {
            background: 'linear-gradient(180deg, rgba(197, 197, 197, 0.00) 0%, #C5C5C5 100%)',
          },
          '&.gold': {
            background: 'linear-gradient(180deg, rgba(244, 206, 2, 0.00) 0%, #F4CE02 100%)',
          },
          '&.platinum': {
            background: 'linear-gradient(180deg, rgba(94, 175, 254, 0.00) 0%, #5EAFFE 100%)',
          },
          '&.diamond': {
            background: 'linear-gradient(180deg, rgba(255, 0, 255, 0.00) 0%, #F0F 100%)',
          },
          '&.champion': {
            background: 'linear-gradient(179deg, rgba(144, 144, 144, 0.00) 1.87%, #909090 107.44%)',
          },
          '&.pro': {
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.04%, #FFF 100%)',
          },
        },

        '& img': {
          width: 128,
          height: 128,
          objectFit: 'contain',
        },
      },
    },

    '& *': {
      textAlign: 'center',
    },
  },
};

const DesktopRankingChart = ({ activeImage, currentLeague, children }) => {
  return (
    <Box mt={12} sx={{ ...listDeskopStyle, display: { xs: 'none', md: 'flex' } }}>
      <Box>
        <Box className={currentLeague === 1 && 'active'}>
          <Box className="bronze" height={53}></Box>
          <img src={BronzeIcon} alt="Bronze" style={{ marginBottom: 24 }} />
        </Box>
        <Heading5>BRONZE</Heading5>
        {currentLeague === 1 && children}
      </Box>
      <Box>
        <Box className={currentLeague === 2 && 'active'}>
          <Box className="silver" height={64}></Box>
          <img src={SilverIcon} alt="Silver" style={{ marginBottom: 32 }} />
        </Box>
        <Heading5>SILVER</Heading5>
        {currentLeague === 2 && children}
      </Box>
      <Box>
        <Box className={currentLeague === 3 && 'active'}>
          <Box className="gold" height={74}></Box>
          <img src={GoldIcon} alt="Gold" style={{ marginBottom: 40 }} />
        </Box>
        <Heading5>GOLD</Heading5>
        {currentLeague === 3 && children}
      </Box>
      <Box>
        <Box className={currentLeague === 4 && 'active'}>
          <Box className="platinum" height={83}></Box>
          <img src={PlatinumIcon} alt="Platinum" style={{ marginBottom: 56 }} />
        </Box>
        <Heading5>PLATINUM</Heading5>
        {currentLeague === 4 && children}
      </Box>
      <Box>
        <Box className={currentLeague >= 5 && currentLeague <= 9 && 'active'}>
          <Box className="diamond" height={95}></Box>
          <img
            src={currentLeague >= 5 && currentLeague <= 9 ? activeImage : DiamondIcon}
            alt="Diamond"
            style={{ marginBottom: 64 }}
          />
        </Box>
        <Heading5>DIAMOND I-V</Heading5>
        {currentLeague >= 5 && currentLeague <= 9 && children}
      </Box>
      <Box>
        <Box className={currentLeague >= 10 && currentLeague <= 14 && 'active'}>
          <Box className="champion" height={103}></Box>
          <img
            src={currentLeague >= 10 && currentLeague <= 14 ? activeImage : ChampionIcon}
            alt="Champion"
            style={{ marginBottom: 72 }}
          />
        </Box>
        <Heading5>CHAMPIONSHIP I-V</Heading5>
        {currentLeague >= 10 && currentLeague <= 14 && children}
      </Box>
      <Box>
        <Box className={currentLeague >= 15 && 'active'}>
          <Box className="pro" height={113}></Box>
          <img
            src={currentLeague >= 15 ? activeImage : ProIcon}
            alt="Pro"
            style={{ marginBottom: 81 }}
          />
        </Box>
        <Heading5>PRO I-V</Heading5>
        {currentLeague >= 15 && children}
      </Box>
    </Box>
  );
};

export default DesktopRankingChart;
