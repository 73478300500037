import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { callBackbonePostApi } from '../../../services/backboneApiService';
import { getUser, setUser } from '../../../utils/cookie';
import { BBVR_SITE_URL } from '../../../config/constants';

import MetaTags from '../../../components/MetaTags';
import { Heading2 } from '../../../components/Heading';
import StyledInput from '../../../components/Form/StyledInput';
import { CustomButton } from '../../../components/Buttons';
import FooterMenu from '../../../components/Footer/FooterMenu';

const SignIn = ({ previousPath }) => {
  const navigate = useNavigate();

  const [error, setError] = useState();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isRemember, setIsRemember] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isAuthenticated = getUser();

    if (!!isAuthenticated) {
      redirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect = () => {
    if (
      previousPath &&
      !previousPath.includes('forgotpassword') &&
      !previousPath.includes('resetpassword')
    ) {
      navigate(-1);
    } else {
      window.location.href = BBVR_SITE_URL;
    }
  };

  const checkRemember = (event) => {
    setIsRemember(event.target.checked);
  };

  const onSubmit = () => {
    if (!username) {
      setError('Please enter your username or email address.');
    } else if (!password) {
      setError('Please enter your password.');
    } else {
      setIsLoading(true);

      const payload = {
        action: 'login',
        userName: username,
        password: password,
      };

      callBackbonePostApi(payload)
        .then((data) => {
          setIsLoading(false);

          if (data?.status === 200 && data?.data?.success) {
            const user = {
              token: data.data?.token,
              username,
              userId: data.data?.userId,
            };
            setUser(user, isRemember);

            redirect();
          } else {
            setError('Your username/email address or password is incorrect. Please try again.');
          }
        })
        .catch(() => {
          setIsLoading(false);

          enqueueSnackbar('Submit failed! Please try later again.', {
            variant: 'error',
          });
        });
    }
  };

  return (
    <Box>
      <MetaTags title="Black Box VR - Sign In!" />
      <Box position="relative" display="flex" justifyContent="center" mb={20}>
        <Box maxWidth={618} width="100%" mt={8} px={2}>
          <Heading2>LOG IN TO YOUR ACCOUNT</Heading2>
          <Typography mt={2}>Please log in to your Black Box VR account.</Typography>
          <Box component="form" noValidate mt={4}>
            <StyledInput
              label="Black Box VR username or email address:"
              type="text"
              value={username}
              sx={{ mb: 1 }}
              onInput={(e) => {
                setUsername(e.target.value);
                setError('');
              }}
            />
            <StyledInput
              label="Black Box VR password:"
              type="password"
              autoComplete="on"
              value={password}
              sx={{ mb: 1 }}
              onInput={(e) => {
                setPassword(e.target.value);
                setError('');
              }}
            />
            <Box my={3}>
              <FormControlLabel
                control={<Checkbox checked={isRemember} onChange={checkRemember} />}
                label="Remember Me"
              />
            </Box>
            {error && (
              <Box>
                <Typography sx={{ color: '#FF370B', pt: 1, pb: 2 }}>{error}</Typography>
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <CustomButton type="submit" disabled={!!error} loading={isLoading} onClick={onSubmit}>
                Log In
              </CustomButton>
              <Link to="/forgotpassword">
                <Typography component="span" color="#0076FF">
                  Forgot your password?
                </Typography>
              </Link>
            </Box>
            <Typography mt={2}>
              Having trouble logging in? Contact us at support@blackbox-vr.com.
            </Typography>
          </Box>
        </Box>
      </Box>
      <FooterMenu />
    </Box>
  );
};

export default SignIn;
