import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';

import S3Avatar from '../../../../components/S3Avatar';
import { Heading3, Heading4 } from '../../../../components/Heading';
import { Link } from 'react-router-dom';

const redGradient = 'linear-gradient(0deg, #FF2D55 0%, #FF00D6 100%)';
const greenGradient = 'linear-gradient(141deg, #00FF80 -1.41%, #4992FF 97.17%)';

const FeaturedCard = ({
  index,
  data,
  isCommunity
}) => {
  const { username, fullName, image, testimonial } = data;

  const renderViewProfileLink = useCallback(
    () => (
      <Link to={`/member/${username}`}>
        <Typography sx={{ color: '#0076ff' }}>View Profile</Typography>
      </Link>
    ),
    [username]
  );

  return (
    <Box>
      <Box sx={{ height: 338 }}>
        <S3Avatar src={image} sx={{ backgroundSize: 'contain', backgroundColor: '#111' }} />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={{ xs: 2, tl: 4, lg: 3 }}
      >
        <Heading3>{username}</Heading3>
        <Box display={{ xs: 'none', tl: 'block' }}>{renderViewProfileLink()}</Box>
      </Box>
      <Typography sx={{ mt: 1, textTransform: 'capitalize' }}>{fullName}</Typography>
      <Heading4
        sx={!isCommunity ? {
          mt: { xs: 2, tl: 3 },
          background: index % 2 === 0 ? redGradient : greenGradient,
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        } : {
          mt: { xs: 2, tl: 3 },
          color: "#AD6BF0",
          textTransform: "none",
        }}
      >
        {testimonial}
      </Heading4>
      <Box display={{ xs: 'block', tl: 'none' }} mt={3}>
        {renderViewProfileLink()}
      </Box>
    </Box>
  );
};

export default FeaturedCard;
