import { Box, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading1 } from '../../../components/Heading';

import ChallengesIcon from '../../../assets/img/team/challenges.svg';
import PlaceholderIcon from '../../../assets/img/placeholder-img.webp';
import StarIcon from '../../../assets/img/week/star.webp';
import MembersTable from './MembersTable';
import MobileMembersTable from './MobileMembersTable';
import { sortObjectArray } from '../../../utils';

const Members = ({
  data,
  recapData,
}) => {
  const captain = [],
    members = [];
  (recapData?.member_data || []).forEach((m) => {
    const member = {
      title: { name: m.username, desc: '' },
      id: m.id,
      image: { name: m.username, url: (m.image === 'N/A' || !m.image) ? PlaceholderIcon : m.image },
      raidPoints: m.raid_points,
      challengerReached: +m.challenger_reached,
      battles: m.battle_count,
    };

    if (m.id === data?.team_captain) {
      captain.push(member);
    } else {
      members.push(member);
    }
  });
  const sortedMembers = captain.concat(sortObjectArray(members, 'raidPoints', 'desc'));

  return (
    <Box mt={{ xs: 5, tl: 10, lg: 16 }}>
      <Container sx={{ display: 'flex', alignItems: 'center' }}>
        <Heading1 sx={{ ml: { xs: 2, tl: '0' } }}>Month {recapData?.month} Member Performance</Heading1>
        <Typography
          fontSize={{ xs: 16, lg: 24 }}
          fontWeight={700}
          ml={{ xs: 3, lg: 4 }}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'center', tl: 'unset' }}
          sx={{ '& img': { mr: 1 } }}
        >
          <img src={ChallengesIcon} alt="Immortals" />
          {sortedMembers.length}/12
        </Typography>
      </Container>
      <Container>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          marginTop: 3,
        }}>
          <img
            src={StarIcon}
            alt="Star"
            width="24px"
            height="24px"
          />
          <Typography sx={{
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: 700,
            marginLeft: 1,
          }}>
            = New Personal Record!
          </Typography>
        </Box>
      </Container>
      <Box mt={3} display={{ xs: 'none', tl: 'block' }}>
        <MembersTable members={sortedMembers.slice(0, 12)} />
      </Box>
      <Box mt={6} display={{ xs: 'block', tl: 'none' }}>
        <MobileMembersTable members={sortedMembers.slice(0, 12)} />
      </Box>
    </Box>
  );
};

export default Members;
