import { Box } from '@mui/material';

import { Heading2 } from '../../../../../components/Heading';
import DesktopTabs from './DesktopTabs';
import MobileAccordion from './MobileAccordion';

import FireBeamIcon from '../../../../../assets/img/stats/tabs/fire-beam.png';
import LightningBoldIcon from '../../../../../assets/img/stats/tabs/lightning-bolt.png';
import MeteorStrikeIcon from '../../../../../assets/img/stats/tabs/meteor-strike.png';
import ColdFusionIcon from '../../../../../assets/img/stats/tabs/cold-fusion.png';
import IceShardIcon from '../../../../../assets/img/stats/tabs/ice-shard.png';
import WindBlastIcon from '../../../../../assets/img/stats/tabs/wind-blast.png';

import CableDeadliftFemale from '../../../../../assets/img/exercise/cable-deadlift-female.webp';
import CableDeadliftMale from '../../../../../assets/img/exercise/cable-deadlift-male.webp';
import CableSquatFemale from '../../../../../assets/img/exercise/cable-squat-female.webp';
import CableSquatMale from '../../../../../assets/img/exercise/cable-squat-male.webp';
import ChestPressFemale from '../../../../../assets/img/exercise/chest-press-female.webp';
import ChestPressMale from '../../../../../assets/img/exercise/chest-press-male.webp';
import LatPulldownFemale from '../../../../../assets/img/exercise/lat-pulldown-female.webp';
import LatPulldownMale from '../../../../../assets/img/exercise/lat-pulldown-male.webp';
import OverheadPressFemale from '../../../../../assets/img/exercise/overhead-press-female.webp';
import OverheadPressMale from '../../../../../assets/img/exercise/overhead-press-male.webp';
import StandingRowFemale from '../../../../../assets/img/exercise/standing-row-female.webp';
import StandingRowMale from '../../../../../assets/img/exercise/standing-row-male.webp';
// import TricepFemale from '../../../../../assets/img/exercise/tricep-pushdown-female.webp';
// import TricepMale from '../../../../../assets/img/exercise/tricep-pushdown-male.webp';
// import BicepCurlFemale from '../../../../../assets/img/exercise/bicep-curl-female.webp';
// import BicepCurlMale from '../../../../../assets/img/exercise/bicep-curl-male.webp';

const data = [
  {
    id: 'chestPress',
    title: 'Fire Beam',
    subTitle: 'Chest Press',
    icon: FireBeamIcon,
    photo: {
      male: ChestPressMale,
      female: ChestPressFemale,
    },
  },
  {
    id: 'standingRow',
    title: 'Lightning Bolt',
    subTitle: 'Standing Row',
    icon: LightningBoldIcon,
    photo: {
      male: StandingRowMale,
      female: StandingRowFemale,
    },
  },
  {
    id: 'overheadPress',
    title: 'Meteor Strike',
    subTitle: 'Overhead Press',
    icon: MeteorStrikeIcon,
    photo: {
      male: OverheadPressMale,
      female: OverheadPressFemale,
    },
  },
  {
    id: 'stiffLeg',
    title: 'Cold Fusion',
    subTitle: 'Stiff Leg Deadlift',
    icon: ColdFusionIcon,
    photo: {
      male: CableDeadliftMale,
      female: CableDeadliftFemale,
    },
  },
  {
    id: 'squat',
    title: 'Ice Shard',
    subTitle: 'Squat',
    icon: IceShardIcon,
    photo: {
      male: CableSquatMale,
      female: CableSquatFemale,
    },
  },
  {
    id: 'latPulldown',
    title: 'Wind Blast',
    subTitle: 'Lat Pulldown',
    icon: WindBlastIcon,
    photo: {
      male: LatPulldownMale,
      female: LatPulldownFemale,
    },
  },
];

const PerformanceChart = ({ gender, exerciseChartsData }) => {
  return (
    <Box position="relative" mt={8} mx={{ xs: 2, tl: 0 }}>
      <Heading2 sx={{ textTransform: 'uppercase' }}>EXERCISE PERFORMANCE CHARTS</Heading2>
      <Box sx={{ display: { xs: 'none', tl: 'block' } }}>
        <DesktopTabs gender={gender} tabsData={data} exerciseChartsData={exerciseChartsData} />
      </Box>
      <Box sx={{ display: { xs: 'block', tl: 'none' } }}>
        <MobileAccordion
          gender={gender}
          accordionData={data}
          exerciseChartsData={exerciseChartsData}
        />
      </Box>
    </Box>
  );
};

export default PerformanceChart;
