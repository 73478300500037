import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import { Heading2 } from '../../../../components/Heading';
import TeamCard from './TeamCard';

import Champions from '../../../../config/champions.json';
import AirIcon from '../../../../assets/img/battle-deck/icons/air.png';
import WaterIcon from '../../../../assets/img/battle-deck/icons/water.png';
import FireIcon from '../../../../assets/img/battle-deck/icons/fire.png';

const getElementIcon = (value) => {
  if (value === 0) return AirIcon;
  else if (value === 1) return WaterIcon;
  else if (value === 2) return FireIcon;

  return null;
};

const TeamEquipped = ({ championTeams }) => {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      if (championTeams) {
        const t = [];

        for (const { code, ...r } of championTeams) {
          const iconName = Champions[code].icon;
          const response = await import(`../../../../assets/img/battle-deck/cards/${iconName}`);
          t.push({ icon: response.default, ...r });
        }

        setTeams(t);
      }
    };
    fetchImages();
  }, [championTeams]);

  return (
    <Box mt={{ xs: 7, lg: 8 }}>
      <Heading2>Champion Team Equipped</Heading2>
      <Box mt={5}>
        <Box display="flex" justifyContent="space-between">
          {teams.slice(0, 3).map((t, index) => (
            <TeamCard
              key={index}
              cardBg={t.icon}
              icon={getElementIcon(t.element)}
              count={t.level}
            />
          ))}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={{ xs: 3, lg: 2 }}>
          {teams.slice(3, 6).map((t, index) => (
            <TeamCard
              key={index}
              cardBg={t.icon}
              icon={getElementIcon(t.element)}
              count={t.level}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default TeamEquipped;
