import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';

import { callBackbonePostApi } from '../../services/backboneApiService';
import { getUser } from '../../utils/cookie';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import { Heading2 } from '../../components/Heading';
import FooterMenu from '../../components/Footer/FooterMenu';
import { CustomButton } from '../../components/Buttons';

const SUBMIT_STATUS = {
  saving: 'saving',
  saved: 'saved',
};

const MemberInterview = () => {
  const { username } = getUser();

  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [answers, setAnswers] = useState({
    adviceForNewUsers: '',
    difference: '',
    favoriteThing: '',
    healthImprovements: '',
    lifeChange: '',
  });

  useEffect(() => {
    const init = () => {
      setIsLoading(true);

      const payload = {
        action: 'getUserResponses',
        userNameOrEmail: username,
      };

      callBackbonePostApi(payload, '/web')
        .then((data) => {
          setIsLoading(false);

          if (data?.status === 200 && data?.data?.userResponses) {
            setAnswers(data.data.userResponses);
          }
        })
        .catch(() => {
          setIsLoading(true);
        });
    };

    init();
  }, [username]);

  const onChangeAnswer = (type, value) => {
    setAnswers({ ...answers, [type]: value });
    setStatus('');
  };

  const onSubmit = () => {
    setStatus(SUBMIT_STATUS.saving);

    const payload = {
      action: 'updateUserResponses',
      userNameOrEmail: username,
      ...answers,
    };

    callBackbonePostApi(payload, '/web')
      .then((data) => {
        if (data.status === 200 && data.data) {
          setStatus(SUBMIT_STATUS.saved);
        } else {
          setStatus('');
          enqueueSnackbar('Submit failed! Please try later again.', {
            variant: 'error',
          });
        }
      })
      .catch(() => {
        setStatus('');

        enqueueSnackbar('Submit failed! Please try later again.', {
          variant: 'error',
        });
      });
  };

  if (isLoading) {
    return <Loading fullscreen />;
  }

  const isDisableSubmit = Object.keys(answers).filter((key) => !!answers[key]).length === 0;

  return (
    <Box>
      <MetaTags title="Black Box VR - Member Interview" />
      <Box position="relative" display="flex" justifyContent="center" mb={3}>
        <Box component="form" noValidate maxWidth={612} width="100%" mt={8} px={2}>
          <Heading2>MEMBER INTERVIEW</Heading2>
          <Typography mt={2}>
            Tell Us Your Black Box VR Story: Share Your Journey and Inspire Others to Join the
            Fitness Revolution!
            <br />
            <br />
            Your answers will be shown on your public profile and may be shared in Black Box VR
            media. Woohoo!
            <br />
            <br />
            <strong>Instructions:</strong>
            <br />
            <br />
          </Typography>
          <Box
            sx={{
              '& ul': {
                ml: 3,
                '& li': {
                  mt: 1,
                },
              },
            }}
          >
            <ul>
              <li>Each question is optional. Answer what you feel comfortable with.</li>
              <li>Please answer in detail, with at least two or three sentences each!</li>
              <li>Each answer can be up to 2000 characters long.</li>
              <li>You can come back and edit your answers and answer more in the future.</li>
            </ul>
          </Box>
          <Box
            sx={{
              '& p': { fontWeight: 700, mt: 4 },
              '& textarea': { mt: 3, width: '100%', minHeight: 241, p: '16px 20px', fontSize: 16 },
            }}
          >
            <Typography>What is your favorite thing about Black Box VR?</Typography>
            <textarea
              placeholder="Type your answer here..."
              disabled={status === SUBMIT_STATUS.saving}
              value={answers.favoriteThing}
              onInput={(e) => onChangeAnswer('favoriteThing', e.target.value)}
            />
            <Typography>How has Black Box VR changed your life?</Typography>
            <textarea
              placeholder="Type your answer here..."
              disabled={status === SUBMIT_STATUS.saving}
              value={answers.lifeChange}
              onInput={(e) => onChangeAnswer('lifeChange', e.target.value)}
            />
            <Typography>
              What physical, emotional, and/or mental health improvements have you seen?
            </Typography>
            <textarea
              placeholder="Type your answer here..."
              disabled={status === SUBMIT_STATUS.saving}
              value={answers.healthImprovements}
              onInput={(e) => onChangeAnswer('healthImprovements', e.target.value)}
            />
            <Typography>
              What makes Black Box VR different from other gyms and programs you’ve tried?
            </Typography>
            <textarea
              placeholder="Type your answer here..."
              disabled={status === SUBMIT_STATUS.saving}
              value={answers.difference}
              onInput={(e) => onChangeAnswer('difference', e.target.value)}
            />
            <Typography>
              What would you tell people that are thinking about trying Black Box VR but aren’t
              sure?
            </Typography>
            <textarea
              placeholder="Type your answer here..."
              disabled={status === SUBMIT_STATUS.saving}
              value={answers.adviceForNewUsers}
              onInput={(e) => onChangeAnswer('adviceForNewUsers', e.target.value)}
            />
          </Box>
          <CustomButton
            type="submit"
            sx={{ mt: 7 }}
            disabled={isDisableSubmit || !!status}
            onClick={onSubmit}
          >
            {!status ? 'Save Answers' : status === SUBMIT_STATUS.saving ? 'Saving…' : 'Saved!'}
          </CustomButton>
          {status === SUBMIT_STATUS.saved && (
            <Box mt={1}>
              <Typography fontSize={24} fontWeight={700} color="#7ED321">
                Your answers have been saved.
              </Typography>
              <Typography>You can edit them and save them again any time in the future.</Typography>
            </Box>
          )}
          <Heading2 sx={{ mt: 12 }}>THANK YOU!</Heading2>
          <Typography mt={2}>
            If you’d like to share more feedback and testimonials, please talk to a Hero Trainer or
            email us at support@blackbox-vr.com.
          </Typography>
        </Box>
      </Box>
      <FooterMenu />
    </Box>
  );
};

export default MemberInterview;
