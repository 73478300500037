import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Container from '../../../components/Layout/Container';
import Loading from '../../../components/Loading';
import { Heading1, Heading4 } from '../../../components/Heading';
import Tab from '../../Leaderboards/Tab';
import { callBackbonePostApi } from '../../../services/backboneApiService';
import {
  LEADERBOARD_PERIODS,
  LEADERBOARD_ACTIONS,
} from '../../../config/constants';

const Leaderboard = () => {
  const navigate = useNavigate();
  const [ isLoading, setIsLoading ] = useState(true);
  const [ leaderboardData, setData ] = useState([]);

  useEffect(() => {
    callBackbonePostApi({
      action: "getAllSeasonLeaderboardPeriods",
    }, '')
    .then((data) => {
      setIsLoading(false);

      if (data?.status === 200 && data?.data) {
        setData(data?.data);
      }
    })
    .catch(() => {
      setIsLoading(true);
    });
  }, []);

  const handleChangePeriod = (category, newPeriod) => {
    navigate(`/leaderboards?category=${category}&period=${newPeriod}`);
  }

  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading1>SEASON LEADERBOARDS</Heading1>
      {!!isLoading && <Box sx={{ marginTop: 3, marginBottom: 3 }}>
        <Loading />
      </Box>}
      {!isLoading && <Box>
        {leaderboardData.filter(item => item.category === "individual").filter(item => item.periods && item.periods.length > 0).map(data => {
          const itemPeriods = [];
          let selectedAction = "";
          Object.keys(LEADERBOARD_PERIODS).forEach(item => {
            const leaderboard = (leaderboardData.find(subItem => subItem.name === data.name)?.periods || []);
            if (leaderboard.indexOf(LEADERBOARD_PERIODS[item].id) > -1) {
              itemPeriods.push({
                id: item,
                title: LEADERBOARD_PERIODS[item].title
              });
            }
          });
          Object.keys(LEADERBOARD_ACTIONS).forEach(item => {
            if (LEADERBOARD_ACTIONS[item]?.id === data.name) {
              selectedAction = item;
            }
          });
          return (
            <Box key={data.name}>
              <Heading4 sx={{ marginTop: 3 }}>{data.displayName}</Heading4>
              <Tab
                mt={2}
                list={itemPeriods}
                selected=""
                onChange={(newPeriod) => handleChangePeriod(selectedAction, newPeriod)}
              />
            </Box>
          );
        })}
        {leaderboardData.filter(item => item.category === "teams").filter(item => item.periods && item.periods.length > 0).map(data => {
          const itemPeriods = [];
          let selectedAction = "";
          Object.keys(LEADERBOARD_PERIODS).forEach(item => {
            const leaderboard = (leaderboardData.find(subItem => subItem.name === data.name)?.periods || []);
            if (leaderboard.indexOf(LEADERBOARD_PERIODS[item].id) > -1) {
              itemPeriods.push({
                id: item,
                title: LEADERBOARD_PERIODS[item].title
              });
            }
          });
          Object.keys(LEADERBOARD_ACTIONS).forEach(item => {
            if (LEADERBOARD_ACTIONS[item]?.id === data.name) {
              selectedAction = item;
            }
          });
          return (
            <Box key={data.name}>
              <Heading4 sx={{ marginTop: 3 }}>{data.displayName}</Heading4>
              <Tab
                mt={2}
                list={itemPeriods}
                selected=""
                onChange={(newPeriod) => handleChangePeriod(selectedAction, newPeriod)}
              />
            </Box>
          );
        })}
      </Box>}
    </Container>
  );
};

export default Leaderboard;
