import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import useQuery from '../../../../../utils/useQuery';
import { getAmityAPIToken } from '../../../../../services/amityApi';
import { useFollowInfo } from '../../../../../services/communityService';
import { formatCompactNumber } from '../../../../../utils';
import { removeAmityBearerToken } from '../../../../../utils/cookie';

import { Heading } from '../../../../../components/Heading';

const NO_GYM_ID = 'No Id Found For This Gym';

const MemberFollows = ({ userId, gymInfo }) => {
  const queryParameters = useQuery();
  const appLoginUserId = queryParameters.get('userId') || userId;

  useEffect(() => {
    removeAmityBearerToken();
    getAmityAPIToken(appLoginUserId);
  }, [appLoginUserId]);

  const { data: followInfoData, mutate, error } = useFollowInfo(userId);
  const { followCounts = [{}] } = followInfoData?.data ?? {};

  if (!!error) {
    mutate();
  }

  return (
    <Box
      mt={{ xs: 4, tl: 2 }}
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      justifyContent={{ xs: 'center', tl: 'flex-start' }}
      sx={{
        '& p': {
          fontSize: { xs: 14, md: 16 },
          textTransform: 'capitalize',
          fontWeight: 700,
          lineHeight: 1.625,
        },
      }}
    >
      <Box display="flex" justifyContent={{ xs: 'center', tl: 'flex-start' }}>
        <Heading>{formatCompactNumber(followCounts[0]?.followerCount)} Followers</Heading>
        <Heading sx={{ ml: 3 }}>
          {formatCompactNumber(followCounts[0]?.followingCount)} Following
        </Heading>
      </Box>
      {gymInfo.id !== NO_GYM_ID && (
        <Heading sx={{ ml: { md: 3 }, mt: { xs: 1, md: 0 }, '& a': { color: '#0076FF' } }}>
          Gym: <Link to={`/gym/${gymInfo.id}/${gymInfo.name}`}>{gymInfo.name}</Link>
        </Heading>
      )}
    </Box>
  );
};

export default MemberFollows;
