import { Box, Grid } from '@mui/material';
import Container from '../../../../components/Layout/Container';
import { Heading1 } from '../../../../components/Heading';
import FeaturedCard from './FeaturedCard';

const FeaturedMembers = ({ members }) => {
  return (
    <Container>
      <Box mt={{ xs: 8, tl: 10, lg: 16 }} mx={{ xs: 2, tl: 0 }}>
        <Heading1>Featured Members</Heading1>
        <Box mt={{ xs: 3, tl: 4, lg: 6 }}>
          <Grid container columnSpacing={5}>
            {Object.keys(members).map((username, index) => (
              <Grid key={index} item xs={12} md={6} mb={{ xs: 8, tl: 6 }}>
                <FeaturedCard
                  index={index}
                  data={{
                    username,
                    fullName: members[username]?.FullName,
                    testimonial: members[username]?.Testimonial,
                    image: members[username]?.ProfilePicURL,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default FeaturedMembers;
