import { Box, Typography } from '@mui/material';

import Container from '../../../../components/Layout/Container';
import { Heading1 } from '../../../../components/Heading';

import ChallengesIcon from '../../../../assets/img/team/challenges.svg';
import MembersTable from './MembersTable';
import MobileMembersTable from './MobileMembersTable';
import { sortObjectArray } from '../../../../utils';

const Members = ({ data }) => {
  const captain = [],
    members = [];
  data?.members_info?.forEach((m) => {
    const member = {
      title: { name: m.member_username, desc: '' },
      id: m.member_id,
      image: { name: m.member_username, url: m.member_profile_pic_url },
      league: m.member_league,
      trophies: +m.member_trophies,
      xp: m.member_xp,
    };

    if (m.member_id === data?.team_captain) {
      captain.push(member);
    } else {
      members.push(member);
    }
  });
  const sortedMembers = [...captain, ...sortObjectArray(members, 'trophies', 'desc')];

  return (
    <Box mt={{ xs: 5, tl: 10, lg: 16 }}>
      <Container sx={{ display: 'flex', alignItems: 'center' }}>
        <Heading1 sx={{ ml: { xs: 2, tl: '0' } }}>Members</Heading1>
        <Typography
          fontSize={{ xs: 16, lg: 24 }}
          fontWeight={700}
          ml={{ xs: 3, lg: 4 }}
          display="flex"
          alignItems="center"
          justifyContent={{ xs: 'center', tl: 'unset' }}
          sx={{ '& img': { mr: 1 } }}
        >
          <img src={ChallengesIcon} alt="Immortals" />
          {data?.member_count ?? 0}/12
        </Typography>
      </Container>
      <Box mt={6} display={{ xs: 'none', tl: 'block' }}>
        <MembersTable members={sortedMembers} />
      </Box>
      <Box mt={6} display={{ xs: 'block', tl: 'none' }}>
        <MobileMembersTable members={sortedMembers} />
      </Box>
    </Box>
  );
};

export default Members;
