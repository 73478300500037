import { Box, Divider } from '@mui/material';

import { Heading5 } from '../../../../../components/Heading';

const activeTextStyle = { display: 'flex', alignItems: 'center', mb: 2 };
const textStyle = { opacity: 0.5, ml: 9, mb: 2, '& hr': { display: 'none' } };

const TabletRankingChart = ({ activeImage, currentLeague, children }) => {
  const getGradientBackground = (level) => {
    if (level === 1) {
      return 'linear-gradient(180deg, rgba(173, 118, 61, 0.00) 1.35%, #AD763D66 100%)';
    } else if (level === 2) {
      return 'linear-gradient(180deg, rgba(197, 197, 197, 0.00) 0%, #C5C5C566 100%)';
    } else if (level === 3) {
      return 'linear-gradient(180deg, rgba(244, 206, 2, 0.00) 0%, #F4CE0266 100%)';
    } else if (level === 4) {
      return 'linear-gradient(180deg, rgba(94, 175, 254, 0.00) 0%, #5EAFFE66 100%)';
    } else if (level >= 5 && level <= 9) {
      return 'linear-gradient(180deg, rgba(255, 0, 255, 0.00) 0%, #FF00FF66 100%)';
    } else if (level >= 10 && level <= 14) {
      return 'linear-gradient(179deg, rgba(144, 144, 144, 0.00) 1.87%, #90909066 107.44%)';
    } else if (level >= 15) {
      return 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0.04%, #FFFFFF66 100%)';
    }
  };

  const renderDivider = () => <Divider sx={{ width: 40, borderColor: '#B1B1B1', mr: 2 }} />;

  return (
    <Box sx={{ position: 'relative', display: { xs: 'none', tl: 'flex', md: 'none' }, mt: 11 }}>
      <Box flex={1} position="relative">
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: 272,
            bottom: 0,
            border: '1px solid #B1B1B1',
          }}
        />
        <Box display="flex" flexDirection="column" alignItems="center">
          {activeImage && (
            <img
              src={activeImage}
              width={200}
              height={200}
              alt="Diamond"
              style={{ position: 'absolute', top: -56, zIndex: 2, objectFit: 'contain' }}
            />
          )}
          <Box
            sx={{
              background: getGradientBackground(currentLeague),
              width: 164,
              height: 297,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <Box mb={5} textAlign="center">
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box minWidth={200} pt={5}>
        <Box sx={currentLeague === 1 ? activeTextStyle : textStyle}>
          {renderDivider()}
          <Heading5>Bronze</Heading5>
        </Box>
        <Box sx={currentLeague === 2 ? activeTextStyle : textStyle}>
          {renderDivider()}
          <Heading5>Silver</Heading5>
        </Box>
        <Box sx={currentLeague === 3 ? activeTextStyle : textStyle}>
          {renderDivider()}
          <Heading5>Gold</Heading5>
        </Box>
        <Box sx={currentLeague === 4 ? activeTextStyle : textStyle}>
          {renderDivider()}
          <Heading5>Platinum</Heading5>
        </Box>
        <Box sx={currentLeague >= 5 && currentLeague <= 9 ? activeTextStyle : textStyle}>
          {renderDivider()}
          <Heading5>Diamond I - V</Heading5>
        </Box>
        <Box sx={currentLeague >= 10 && currentLeague <= 14 ? activeTextStyle : textStyle}>
          {renderDivider()}
          <Heading5>Championship I - V</Heading5>
        </Box>
        <Box sx={currentLeague >= 15 ? activeTextStyle : textStyle}>
          {renderDivider()}
          <Heading5>Pro I - V</Heading5>
        </Box>
      </Box>
    </Box>
  );
};

export default TabletRankingChart;
