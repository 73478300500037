import { Box, Typography } from '@mui/material';

import { formatCompactNumber } from '../../../../../utils';

import { Heading2, Heading3 } from '../../../../../components/Heading';
import ChartSection from './ChartSection';

const TabDataContent = ({ title, subTitle, iconImage, photo, gender, details }) => {
  const data = [
    {
      title: 'Current One Rep Max (Lbs.)',
      value: formatCompactNumber(+(details.details?.current_orm ?? 0)),
    },
    {
      title: 'Best One Rep Max (Lbs.)',
      value: formatCompactNumber(details.details?.max_orm ?? 0),
    },
    {
      title: 'Sets | Reps',
      value: `${formatCompactNumber(details.details?.set_count ?? 0)} | ${formatCompactNumber(
        details.details.rep_count ?? 0
      )}`,
    },
    {
      title: 'Work (Ft.-Lbs.)',
      value: formatCompactNumber(details.details?.total_work ?? 0),
    },
    {
      title: 'Total Volume (Lbs.)',
      value: formatCompactNumber(details.details?.total_volume ?? 0),
    },
  ];

  return (
    <Box display="flex" flexDirection={{ xs: 'column-reverse', tl: 'column', md: 'row' }}>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            '& img': {
              width: 88,
              height: 88,
              border: '1px solid #454545',
              borderRadius: '50%',
            },
          }}
        >
          <img src={iconImage} alt={title} />
          <Box ml={2}>
            <Heading3>{title}</Heading3>
            <Typography mt={1}>{subTitle}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            mt: 5,
            '& img': {
              width: { md: 250, xl: 400 },
              height: { md: 250, xl: 400 },
              objectFit: 'cover',
            },
          }}
        >
          <img src={gender === 'Female' ? photo.female : photo.male} alt="" />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', md: 'column' },
          justifyContent: 'space-between',
          flexWrap: { xs: 'wrap', tl: 'unset' },
          minWidth: 200,
          ml: { xs: 0, md: 3 },
          mt: { xs: 4, tl: 0 },
          textAlign: 'center',

          '& p': {
            fontSize: 14,
            marginBottom: 1,
          },
        }}
      >
        {data.map((d, index) => (
          <Box
            key={index}
            mb={4}
            maxWidth={{ tl: 112, md: 'none' }}
            width={{ xs: '50%', tl: 'auto' }}
          >
            <Typography sx={{ minHeight: { tl: 42, md: 'auto' } }}>{d.title}</Typography>
            <Heading3 sx={{ fontSize: { xs: 16, md: 28 } }}>{d.value}</Heading3>
          </Box>
        ))}
      </Box>

      <Box ml={{ xs: 0, md: 3 }} mt={{ xs: 0, tl: 5, md: 0 }} flex={1}>
        {!!details.chart ? (
          <ChartSection title={subTitle} data={details.chart} />
        ) : (
          <Box
            display={{ xs: 'none', tl: 'flex' }}
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Heading2>No Data</Heading2>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TabDataContent;
