import { Box, Typography, Divider } from '@mui/material';

import { formatCompactNumber, formatLocalizedDate } from '../../../../utils';
import { BBVR_SITE_URL } from '../../../../config/constants';

import Container from '../../../../components/Layout/Container';
import { CustomButton } from '../../../../components/Buttons';
import { Heading1, Heading4 } from '../../../../components/Heading';
// import CustomBreadcrumbs from '../../../../components/Breadcrumbs';
import AnimatedPopup from '../../../../components/AnimatedPopup';
import SummaryCell from '../../../../components/SummaryCell';

import GoldCup from '../../../../assets/img/team/gold-cup.svg';
import XP from '../../../../assets/img/team/xp.svg';
import ImmortalsLogo from '../../../../assets/img/team/logo/immortals.png';

const Details = ({ data }) => {
  const teamName = data?.team_name ?? '';
  const teamIcon = data?.team_icon ?? '';
  const teamDesc = data?.team_description ?? '';
  const teamAvgXp = data?.team_average_xp ?? 0;
  const teamAvgTrophies = data?.team_average_trophies ?? 0;
  const createdAt = data?.created_date;

  // const breadcrumbs = [
  //   { title: 'Home', link: '/' },
  //   { title: 'Community', link: '/' },
  //   { title: 'Teams', link: '/' },
  //   { title: teamName },
  // ];

  const renderCreatedDate = () => (
    <Typography sx={{ mt: 2, opacity: 0.8, fontSize: { xs: 14, tl: 16 } }}>
      Date Created: {formatLocalizedDate(createdAt, 'LL')}
    </Typography>
  );

  return (
    <Box py={3} minHeight={{ lg: 698 }} position="relative">
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: { xs: '100%', tl: 322, lg: 647 },
          height: { xs: 375, tl: 390, lg: '100%' },
          background: `radial-gradient(76.5% 76.5% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, #000 100%), url(${teamIcon}), lightgray 50% / cover no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          opacity: 0.5,
          filter: 'blur(12px)',
          zIndex: -1,
        }}
      />
      <Container>
        {/* <CustomBreadcrumbs items={breadcrumbs} /> */}
        <Box
          display="flex"
          flexDirection={{ xs: 'column', tl: 'row' }}
          alignItems={{ xs: 'center', tl: 'flex-start', lg: 'center' }}
          mt={{ xs: 5, lg: '84px' }}
        >
          <Box>
            <Box
              border="1px solid #313131"
              sx={{
                '& img': {
                  width: { xs: 220, tl: 200, lg: 400 },
                  height: { xs: 220, tl: 200, lg: 400 },
                },
              }}
            >
              <img src={teamIcon ?? ImmortalsLogo} alt={teamName} />
            </Box>
            <Box display={{ xs: 'none', tl: 'block' }}>{renderCreatedDate()}</Box>
          </Box>
          <Box ml={{ xs: 2, tl: 8 }} mr={{ xs: 2, tl: 0 }} flex={1}>
            <Box
              display={{ xs: 'none', tl: 'flex' }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Heading4>Team</Heading4>
              <Box>
                <AnimatedPopup description="A Black Box VR team allows up to 12 people from your home gym location to clan up and and get super fit together!<br />Teams work collaboratively to complete challenges and earn unique digital and real-life rewards. The more successful your team is in completing Shared Quests, the more Boost Items you’ll all receive for the thrilling monthly Team Arena Raids!<br />Celebrate each other’s success in the private team chat.<br />You supportive team members will make Black Box VR even more fun and encourage you to stay committed to your important fitness goals. Get ready to make some new lifelong friends!">
                  <Typography>What is a Black Box Team?</Typography>
                </AnimatedPopup>
              </Box>
            </Box>
            <Heading1
              sx={{
                mt: { xs: 4, tl: 1, lg: 2 },
                mb: { xs: 1, lg: 3 },
                wordBreak: 'break-all',
                textAlign: { xs: 'center', tl: 'left' },
              }}
            >
              {teamName}
            </Heading1>
            <Typography fontWeight={700} sx={{ textAlign: { xs: 'center', tl: 'left' } }}>
              {teamDesc}
            </Typography>
            <Box
              display="flex"
              flexDirection={{ xs: 'row', tl: 'column', lg: 'row' }}
              alignItems={{ lg: 'center' }}
              justifyContent={{ xs: 'space-around', tl: 'flex-start' }}
              mt={{ xs: 4, lg: 5 }}
            >
              <Box
                display="flex"
                flexDirection={{ xs: 'column', tl: 'row' }}
                alignItems={{ tl: 'center' }}
              >
                <img src={GoldCup} width={40} alt="" />
                <SummaryCell
                  label="Average Trophies"
                  textAlign="flex-start"
                  value={formatCompactNumber(Math.floor(teamAvgTrophies))}
                  description="Win workout battles to earn trophies! The number of trophies you get depends on how many points you beat your competitor by. But be careful! If you lose your battle, you lose trophies. Push hard, level up your body and your battle deck, and you’ll be moving up in no time."
                  isQuestion
                  sx={{ ml: { tl: 3 }, mt: { xs: 2, tl: 0 } }}
                />
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  display: { xs: 'none', lg: 'block' },
                  borderColor: '#B1B1B1',
                  height: { xs: 56, lg: 83 },
                  mx: 6,
                }}
              />
              <Box
                display="flex"
                flexDirection={{ xs: 'column', tl: 'row' }}
                alignItems={{ tl: 'center' }}
                mt={{ xs: 0, tl: 3, lg: 0 }}
              >
                <img src={XP} width={36} alt="" />
                <SummaryCell
                  label="Average Lifetime XP"
                  value={formatCompactNumber(Math.floor(teamAvgXp))}
                  description="Earn XP by completing quests!  The more consistent you are with your weekly workouts, the more quests you’ll be able to complete, helping you to accumulate XP.<br />Lifetime XP is the total amount of XP you’ve earned since starting at Black Box VR.  Lifetime XP allows you to unlock new exercises, Champions, powerups, game features, and much more!  This helps keep the game fresh for you while you gain muscle, burn fat, and get into the best shape of your life."
                  textAlign="flex-start"
                  isQuestion
                  sx={{ ml: { tl: 3 }, mt: { xs: 2, tl: 0 } }}
                />
              </Box>
            </Box>
            <CustomButton
              href={`${BBVR_SITE_URL}/locations/`}
              sx={{ mt: { xs: 4, lg: 5 }, width: { xs: '100%', tl: 'auto' } }}
            >
              Join the Team
            </CustomButton>
          </Box>
          <Box display={{ xs: 'block', tl: 'none' }} mt={4}>
            {renderCreatedDate()}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Details;
