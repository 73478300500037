import { Box, Divider } from '@mui/material';

import SummaryCell from '../../SummaryCell';

const CellDivider = ({ sx }) => (
  <Divider
    orientation="vertical"
    sx={{ borderColor: '#FFF', height: { xs: 56, lg: 83 }, mx: 3, ...sx }}
  />
);

const OverviewBoard = ({ data }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: { xs: 'wrap', tl: 'initial' },
          maxWidth: { xs: 340, tl: 'none' },
          m: { xs: 'auto', tl: 'unset' },

          '& > div': {
            mb: { xs: 4, tl: 0 },
            width: { xs: 'calc(50% - 16px)', tl: 'auto' },

            '&:last-child': {
              mb: 0,
            },
          },
          '& > hr': {
            mb: { xs: 4, tl: 0 },
            mx: { xs: 1, tl: 3 },
          },
        }}
      >
        <SummaryCell label={data[0].title} value={data[0].value} />
        <CellDivider />
        <SummaryCell
          label={data[1].title}
          value={data[1].value}
          description={data[1].description}
          isQuestion
        />
        <CellDivider sx={{ display: { xs: 'none', tl: 'block' } }} />
        <SummaryCell
          label={data[2].title}
          value={data[2].value}
          description={data[2].description}
          isQuestion
        />
        <CellDivider sx={{ display: { xs: 'block', tl: 'none', lg: 'block' } }} />
        <SummaryCell
          label={data[3].title}
          value={data[3].value}
          description={data[3].description}
          isQuestion
          sx={{ display: { xs: 'block', tl: 'none', lg: 'block' } }}
        />
        <CellDivider sx={{ display: { xs: 'none', lg: 'block' } }} />
        <SummaryCell
          label={data[4].title}
          value={data[4].value}
          sx={{ display: { xs: 'block', tl: 'none', lg: 'block' } }}
        />
      </Box>
      <Box
        sx={{
          display: { xs: 'none', tl: 'flex', lg: 'none' },
          justifyContent: 'center',
          alignItems: 'center',
          mt: 4,
        }}
      >
        <SummaryCell
          label={data[3].title}
          value={data[3].value}
          description={data[3].description}
          isQuestion
        />
        <CellDivider />
        <SummaryCell label={data[4].title} value={data[4].value} />
      </Box>
    </>
  );
};

export default OverviewBoard;
