import { AppBar, Box, IconButton } from '@mui/material';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Link } from 'react-router-dom';

import { BBVR_SITE_URL } from '../../config/constants';

import TopMenu from './TopMenu';
import RightSideMenu from '../../components/Header/RightSideMenu';
import { CustomButton } from '../../components/Buttons';
import useQuery from '../../utils/useQuery';
import LocalImage from '../LocalImage';
// import SearchInput from './SearchInput';

const Header = () => {
  const queryParameters = useQuery();
  const isTv = queryParameters.get('tv') === "true";

  if (isTv) {
    return null;
  }

  return (
    <>
      <RightSideMenu />
      <AppBar
        sx={{
          background: '#2C2F31',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: { xs: 48, lg: 60 },
          px: 2,

          '& a': {
            height: { xs: 24, lg: 34 },
          },
        }}
      >
        <Link to={BBVR_SITE_URL}>
          <LocalImage src="img/logo.svg" alt="Black Box" loading="lazy" height="100%" />
        </Link>
        <Box display={{ xs: 'none', lg: 'flex' }} alignItems="center">
          <TopMenu />
          <CustomButton
            sx={{
              minWidth: 139,
              height: 36,
              textTransform: 'capitalize',
              borderRadius: '36px',
              fontWeight: 'normal',
            }}
            href={`${BBVR_SITE_URL}/locations/`}
          >
            Try For $1!
          </CustomButton>
          {/* <SearchInput /> */}
        </Box>
        <Box display={{ xs: 'flex', lg: 'none' }} pr={4.5}>
          <IconButton aria-label="delete" size="small">
            <ShoppingBagIcon sx={{ fontSize: 22, color: '#FFF' }} />
          </IconButton>
        </Box>
      </AppBar>
    </>
  );
};

export default Header;
