import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { PASSWORD_PATTERN } from '../../../config/constants';
import { callBackbonePostApi } from '../../../services/backboneApiService';

import MetaTags from '../../../components/MetaTags';
import Loading from '../../../components/Loading';
import { Heading2 } from '../../../components/Heading';
import StyledInput from '../../../components/Form/StyledInput';
import { CustomButton } from '../../../components/Buttons';
import FooterMenu from '../../../components/Footer/FooterMenu';

const ResetPassword = () => {
  const navigate = useNavigate();
  const { recoveryToken } = useParams();

  const [isInitiate, setInitiate] = useState(true);
  const [error, setError] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    const checkValidateToken = () => {
      setInitiate(true);

      const payload = {
        action: 'validateRecoveryToken',
        recoveryToken,
      };

      callBackbonePostApi(payload)
        .then((data) => {
          setInitiate(false);

          if (!(data.status === 200 && data.data)) {
            navigate('/forgotpassword?error=invalid');
          }
        })
        .catch(() => {
          setInitiate(false);
          navigate('/forgotpassword?error=invalid');
        });
    };

    checkValidateToken();
  }, [recoveryToken, navigate]);

  const onSubmit = () => {
    if (!password) {
      setError('Please enter your new password.');
    } else if (!PASSWORD_PATTERN.test(password)) {
      setError(
        'Your password must be at least 8 characters and include at least one lowercase letter, one uppercase letter, and one number.'
      );
    } else {
      setIsLoading(true);

      const payload = {
        action: 'finishPasswordRecovery',
        recoveryToken,
        newPassword: password,
      };

      callBackbonePostApi(payload)
        .then((data) => {
          setIsLoading(false);

          if (data.status === 200 && data.data) {
            setIsSubmit(true);
          } else {
            navigate('/forgotpassword?error=invalid');
          }
        })
        .catch(() => {
          setIsLoading(false);

          enqueueSnackbar('Submit failed! Please try later again.', {
            variant: 'error',
          });
        });
    }
  };

  if (isInitiate) {
    return <Loading fullscreen />;
  }

  return (
    <Box>
      <MetaTags title="Black Box VR - Reset Password!" />
      <Box position="relative" display="flex" justifyContent="center" mb={28}>
        <Box maxWidth={!isSubmit ? 618 : 710} width="100%" mt={8} px={2}>
          {!isSubmit ? (
            // ------- Reset Password Form
            <Box>
              <Heading2>RESET YOUR PASSWORD</Heading2>
              <Typography mt={2}>Update your password using the form below.</Typography>
              <Box component="form" noValidate mt={4}>
                <StyledInput
                  label="New password:"
                  type="password"
                  sx={{ mb: 3 }}
                  onInput={(e) => {
                    setPassword(e.target.value);
                    setError('');
                  }}
                />
                {error && (
                  <Box>
                    <Typography sx={{ color: '#FF370B', pt: 1, pb: 2 }}>{error}</Typography>
                  </Box>
                )}
                <CustomButton
                  disabled={!!error}
                  loading={isLoading}
                  type="submit"
                  onClick={onSubmit}
                >
                  Reset Password
                </CustomButton>
              </Box>
            </Box>
          ) : (
            // ------- Success page
            <Box mb={3}>
              <Heading2>YOUR PASSWORD HAS BEEN CHANGED</Heading2>
              <Typography mt={2}>
                You can now log in to the app or to the website with your new password.
              </Typography>
              <Box mt={3}>
                <Link to="/signin">
                  <Typography component="span" color="#0076FF">
                    Log In To Your Account
                  </Typography>
                </Link>
              </Box>
            </Box>
          )}

          <Typography mt={2}>
            Having trouble logging in? Contact us at support@blackbox-vr.com.
          </Typography>
        </Box>
      </Box>
      <FooterMenu />
    </Box>
  );
};

export default ResetPassword;
