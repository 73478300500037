import { Box, Grid } from '@mui/material';

import { formatCompactNumber } from '../../../../../utils';

import { Heading } from '../../../../../components/Heading';
import Container from '../../../../../components/Layout/Container';
import { HtmlTooltip, TooltipContent } from '../../../../../components/Tooltip';
import BadgeButton from './BadgeButton';

const gradientStyle1 = {
  position: 'relative',
  display: 'inline-block',
  background:
    'linear-gradient(156deg, #FFF 0.04%, #CFD0D2 50.51%, #9FA3A9 50.52%, #B1B3B5 72.40%, #E6E7E8 100%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

const gradientStyle2 = {
  position: 'relative',
  background:
    'linear-gradient(144deg, #000 0.04%, #606060 50.51%, #121212 50.52%, #747174 72.40%, #909090 100%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
};

const gradientStyle3 = {
  position: 'relative',
  backgroundImage:
    'linear-gradient(180deg, #FFF 0%, #CFD0D2 50.51%, #9FA3A9 50.52%, #B1B3B5 72.40%, #E6E7E8 100%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  WebkitTextStroke: '1px #000',
};

const gridMaginTop = [
  { xs: 7, tl: 0 },
  {},
  { xs: 7, tl: 0 },
  { xs: 3, tl: 0 },
  { xs: -5, tl: 0 },
  { xs: 3, tl: 0 },
  { xs: -5, tl: 0 },
];

const getFontStyle = (shortcode) => {
  let fontStyle = {};
  const achievements2 = []; // Will do more later
  const achievements3 = [
    'totalcrystalsdestroyed',
    'totalgatesdestroyed',
    'totalunitscreated',
    'totalunitsdefeated',
    'totalwins',
  ]; // Will do more later

  if (achievements2.indexOf(shortcode.toLowerCase()) >= 0) fontStyle = { ...gradientStyle2 };
  else if (achievements3.indexOf(shortcode.toLowerCase()) >= 0) fontStyle = { ...gradientStyle3 };
  else fontStyle = { ...gradientStyle1 };

  // Add Margin Top in the special icons
  if (shortcode.toLowerCase() === 'totalwins') fontStyle = { ...fontStyle, mt: 3 };
  if (shortcode.toLowerCase() === 'totalpoints') fontStyle = { ...fontStyle, mt: '-26px' };
  if (shortcode.toLowerCase() === 'totalpuncheslanded') fontStyle = { ...fontStyle, mt: 3 };

  return fontStyle;
};

const AchievementBadges = ({ achievements = [] }) => {
  return (
    <Container>
      <Box
        position="relative"
        top={{ xs: 56, tl: 80, lg: 124 }}
        sx={{ display: { xs: 'flex', lg: 'block' }, justifyContent: 'center' }}
      >
        <Grid
          container
          spacing={{ xs: 2, lg: 0.5 }}
          sx={{
            width: { xs: 'fit-content', lg: '100%' },
            maxWidth: { xs: 352, tl: 'none' },
            justifyContent: { xs: 'center', tl: 'unset' },
          }}
        >
          {achievements.map((achievement, index) => (
            <HtmlTooltip
              key={index}
              title={
                <TooltipContent
                  title={formatCompactNumber(achievement.current_count, 0)}
                  desc={achievement.description}
                />
              }
              placement={index < 3 ? 'bottom-start' : index === 3 ? 'bottom' : 'bottom-end'}
              arrow
            >
              <Grid item mt={gridMaginTop[index]}>
                <BadgeButton bg={achievement.icon_url}>
                  <Heading
                    size={{ xs: 25, lg: 36 }}
                    sx={{ ...getFontStyle(achievement.shortcode) }}
                  >
                    {formatCompactNumber(achievement.current_count, 0)}
                  </Heading>
                </BadgeButton>
              </Grid>
            </HtmlTooltip>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default AchievementBadges;
