import { Box } from '@mui/material';

import useQuery from '../../../../utils/useQuery';

import BackSlider from './BackSlider';
import RightCornerTag from './RightCornerTag';
import SocialButtons from './SocialButtons';
import AvatarInfo from './AvatarInfo';
import AchievementBadges from './AchievementBadges';
import VipSection from './Vip';
import { capitalizeFirstLetter } from '../../../../utils';

const DetailsSection = ({
  username,
  userImage,
  userId,
  xpTitle,
  currentLeague,
  displayTrophyCount,
  details,
  achievements,
  gymInfo,
  isPublicProfile,
  inviteState,
  onShowChat,
  isLoading,
  vipCode,
  vipCodeStatus
}) => {
  const queryParameters = useQuery();
  const version = queryParameters.get('version');

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: { xs: 542, lg: 707 },
      }}
    >
      <BackSlider isWebviewVersion={version === 'webview'} />
      {version !== 'webview' && <RightCornerTag />}
      {!isLoading && vipCodeStatus && (
        <VipSection
          status={vipCodeStatus}
          username={capitalizeFirstLetter(username)}
          gymID={gymInfo?.id}
          vipCode={vipCode}
        />
      )}
      <AvatarInfo
        data={{ username, userImage, userId, xpTitle, currentLeague, displayTrophyCount, details }}
        gymInfo={gymInfo}
        isPublicProfile={isPublicProfile}
        inviteState={inviteState}
        onShowChat={onShowChat}
      />
      {isPublicProfile && <AchievementBadges achievements={achievements} />}
      <SocialButtons />
    </Box>
  );
};

export default DetailsSection;
