import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer/FooterMenu';
import { Heading2 } from '../../components/Heading';
import FullScreenBox from '../../components/Layout/FullScreenBox';

import { useTeamDetails } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';
import Details from '../Profiles/TeamProfile/Details';
import Recap from './Recap';
import Members from './Members';
import SeasonInfo from './SeasonInfo';
import FragmentsFound from './FragmentsFound';

const TeamRecap = () => {
  const [ teamRecapData, setData ] = useState(null);
  const [ isTeamRecapLoading, setIsLoading ] = useState(true);
  const { teamname } = useParams();
  const {
    data: { data, isLoading },
    error,
  } = useTeamDetails('teamname', teamname);
  const teamDetails = data?.results;

  useEffect(() => {
    callBackbonePostApi({
      action: "getTeamRaidRecap",
      teamName: teamname
    }, '')
    .then((data) => {
      setIsLoading(false);

      if (data?.status === 200 && data?.data) {
        setData(data?.data);
      }
    })
    .catch(() => {
      setIsLoading(true);
    });
  }, [teamname]);

  if (error) {
    return (
      <FullScreenBox>
        <Heading2>Member not found</Heading2>
      </FullScreenBox>
    );
  }

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - ${teamname}'s Recap!`}
        description={`Black Box VR - ${teamname}'s Recap!`}
      />
      {(isLoading || isTeamRecapLoading) ? (
        <Loading fullscreen />
      ) : (
        <>
          <Details data={teamDetails} />
          <Recap data={teamRecapData} />
          <Members data={teamDetails} recapData={teamRecapData} />
          <FragmentsFound data={teamRecapData} />
          <SeasonInfo data={teamDetails} recapData={teamRecapData} teamname={teamname} />
        </>
      )}
      <Footer />
    </Box>
  );
};

export default TeamRecap;
