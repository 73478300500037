import { Box, Hidden, Stack } from "@mui/material";
import LocalImage from "../../../../components/LocalImage";
import { useEffect, useState } from "react";

const Tracker = (props) => {
  const [raidPointsNeeded, setRaidPointsNeeded] = useState(0);
  const [currentRaidPoints, setCurrentRaidPoints] = useState(0);

  useEffect(() => {
      setRaidPointsNeeded(props.data.raidPointsNeeded);
      setCurrentRaidPoints(props.data.currentRaidPoints);
  }, [props.data])

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={{ xs: '100%', sm: '80%', xl: 'auto' }}
    >
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        marginX="auto"
        gap={0.25}
      >
        <LocalImage
          src="img/community/Raid Points.png"
          loading="lazy"
        />
        <Stack
          fontSize={48}
          fontWeight={700}
          lineHeight="48px"
          sx={{
            textAlign: 'right',
            textShadow: "0px 1.664px 0.832px #000",
            fontFamily: 'Roboto, sans-serif'
          }}
        >
          {raidPointsNeeded}
        </Stack>
      </Box>
      <Hidden implementation="css" xlDown >
        <Box
          display="flex"
          flexDirection="column-reverse"
          mt={1.75}
          mx="auto"
          width={props.width}
          height={props.height}
          bgcolor="#4A4F52"
          borderRadius="10px"
        >
          <Box
            position="relative"
            width={props.width}
            height={props.height * (currentRaidPoints / 10000)}
            sx={{
              background: "linear-gradient(0deg, #E4017D 0%, #6F013E 100%)"
            }}
            borderRadius="0px 0px 10px 10px"
          >
            <Stack
              position="absolute"
              textAlign="center"
              sx={{
                transform: `translate(-30px, -50%)`
              }}
            >
              <Box position="relative" height={45}>
                <LocalImage
                  src="img/community/tracker-button.png"
                  style={{width: `${props.width + 60}px`}}
                  alt=""
                  loading="lazy"
                  height="100%"
                />
                <Stack
                  position="absolute"
                  top="50%"
                  left="50%"
                  fontSize={36}
                  fontWeight={800}
                  color={'F1F1F1'}
                  sx={{
                    transform: 'translate(-50%, -50%)',
                    'WebkitTextStrokeWidth': '0.83px',
                    'WebkitTextStrokeColor': '#000',
                    fontFamily: 'Futura PT Cond, sans-serif'
                  }}
                >
                  {currentRaidPoints}
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Hidden>
      <Hidden implementation="css" xlUp>
        <Box
          display="flex"
          flexDirection="row-inverse"
          mt={5}
          mx="auto"
          height={50}
          bgcolor="#4A4F52"
          borderRadius="10px"
        >
          <Box
            position="relative"
            width={{ xs: `${currentRaidPoints / 100}%` }}
            height={50}
            sx={{
              background: "linear-gradient(0deg, #E4017D 0%, #6F013E 100%)"
            }}
            borderRadius="10px 0px 0px 10px"
          >
            <Stack
              display="flex"
              position="absolute"
              textAlign="center"
              right="0"
              sx={{
                transform: `translate(50%, -15px)`
              }}
            >
              <LocalImage
                src="img/community/tracker-button.png"
                alt=""
                loading="lazy"
                style={{ height: 80, width: 100 }}
              />
              <Stack
                position="absolute"
                top="50%"
                left="50%"
                fontSize={36}
                fontWeight={800}
                color={'F1F1F1'}
                sx={{
                  transform: 'translate(-50%, -50%)',
                  'WebkitTextStrokeWidth': '0.83px',
                  'WebkitTextStrokeColor': '#000',
                  fontFamily: 'Futura PT Cond, sans-serif'
                }}
              >
                {currentRaidPoints}
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Hidden>
    </Box>
  );
};

export default Tracker;
