import { Box } from '@mui/material';

import { Heading } from '../../../../components/Heading';

import BoardImage from '../../../../assets/img/battle-deck/board.png';

const TeamCard = ({ cardBg, icon, count }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        background: `url(${cardBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: { xs: 103, tl: 158, lg: 307 },
        height: { xs: 123, tl: 189, lg: 368 },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: { xs: 6, tl: 8, lg: 24 },
          right: { xs: -8, tl: -12, lg: -20 },
        }}
      >
        <Box
          sx={{
            background: `url(${BoardImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: { xs: 31, tl: 47, lg: 92 },
            height: { xs: 29, tl: 44, lg: 86 },
          }}
        >
          <Heading
            size={{ xs: 19, tl: 26, lg: 48 }}
            sx={{
              textAlign: 'center',
              lineHeight: { xs: 1.5, tl: 1.7, lg: 1.8 },
              WebkitTextStroke: '1px #000',
            }}
          >
            {count}
          </Heading>
        </Box>
        <Box
          sx={{
            background: icon ? `url(${icon})` : 'transparent',
            backgroundSize: { xs: '140%', lg: '155%' },
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            width: { xs: 30, tl: 47, lg: 88 },
            height: { xs: 30, tl: 47, lg: 88 },
          }}
        />
      </Box>
    </Box>
  );
};

export default TeamCard;
