import axios from 'axios';

const sparkApi = axios.create({
  baseURL: process.env.REACT_APP_SPARK_IO_API_URL,
});

sparkApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response);

    return Promise.reject(error.response || 'Something went wrong');
  }
);

export default sparkApi;
