import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { formatCompactNumber, sortObjectArray } from '../../../utils';

import { Heading1, Heading2, Heading3, Heading4 } from '../../../components/Heading';
import S3Avatar from '../../../components/S3Avatar';
import NumberMark from '../../../components/NumberMark';

const MembersList = ({ type, data, isTv }) => {
  let sortedData = [];
  if (type !== 'team') {
    const formatedData = Object.keys(data).map((key) => ({
      username: data[key].username || key,
      fullName: (data[key].full_name === 'N/A' ? '' : data[key].full_name) || (data[key].fullName === 'N/A' ? '' : data[key].fullName),
      value: parseInt(data[key].raidPointsContributed || data[key].totalWork || data[key].totalWorkouts || data[key].total_gems || data[key].raidPoints || data[key].averageWork || data[key].AllTimeHighest || data[key].CurrentStreak || data[key].lifetimeXP || data[key].SeasonXP),
      image: data[key].profile_pic_url || data[key].profilePicUrl || data[key].teamIcon,
      description: data[key].teamDescription,
      captain: data[key].captain,
    })).filter(item => item.value > 0);
    sortedData = sortObjectArray(formatedData, 'value', 'desc');
  } else {
    sortedData = data.map(item => ({
      username: item.teamName,
      fullName: '',
      value: parseInt(item.raidPoints),
      image: item.teamIcon,
      description: item.teamDescription,
      captain: item.captain
    })).filter(item => item.value > 0);
  }

  return (
    <Box sx={{ maxWidth: 966, margin: 'auto' }}>
      <Box my={8} mx={2}>
        {sortedData.slice(0, isTv ? 10 : sortedData.length).map((obj, index) => (
          <React.Fragment key={index}>
            {index < 10 ? (
              <Box display="flex" alignItems="center" mt={3} pl={{ xs: 2, lg: 0 }}>
                <Box display="flex" alignItems="center">
                  <Box
                    position="relative"
                    width={index === 0 ? { xs: 120, tl: 248 } : { xs: 80, tl: 100 }}
                    height={index === 0 ? { xs: 120, tl: 248 } : { xs: 80, tl: 100 }}
                  >
                    <NumberMark number={index + 1} />
                    <Link to={`/${type}/${obj.username}`}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          padding: '1px',
                          background:
                            index === 0
                              ? 'linear-gradient(180deg, #925F01 0%, #C3A128 30%, #FEF89D 40%, #F4CE02 80%)'
                              : '#313131',
                        }}
                      >
                        <S3Avatar src={(obj.image || "").indexOf("https") === 0 ? obj.image : ""} />
                      </Box>
                    </Link>
                  </Box>
                </Box>
                {index !== 0 ? (
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      ml: 4,
                      mr: { xs: 0, tl: 9 },
                    }}
                  >
                    <Box>
                      <Link to={`/${type}/${obj.username}`}>
                        <Heading3 sx={{ wordBreak: 'break-all' }}>{obj.username}</Heading3>
                      </Link>
                      <Typography mt={1}>{obj.fullName}</Typography>
                      {type === "team" && <Typography sx={{
                        fontSize: "16px",
                        fontWeight: 400,
                        marginTop: 1,
                      }}>
                        {obj.description}
                      </Typography>}
                      {type === "team" && <Typography sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        marginTop: 1,
                        color: "#B1B1B1",
                      }}>
                        Captain: {obj.captain}
                      </Typography>}
                    </Box>
                    <Heading2 sx={{ ml: 1 }}>{formatCompactNumber(obj.value)}</Heading2>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      ml: 4,
                      mr: { xs: 0, tl: 9 },
                    }}
                  >
                    <Box>
                      <Link to={`/${type}/${obj.username}`}>
                        <Heading1 sx={{ wordBreak: 'break-all' }}>{obj.username}</Heading1>
                      </Link>
                      <Typography mt={1}>{obj.fullName}</Typography>
                    </Box>
                    {type === "team" && <Typography sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      marginTop: 1,
                    }}>
                      {obj.description}
                    </Typography>}
                    {type === "team" && <Typography sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      marginTop: 1,
                      color: "#B1B1B1",
                    }}>
                      Captain: {obj.captain}
                    </Typography>}
                    <Heading1 sx={{ mt: 1 }}>{formatCompactNumber(obj.value)}</Heading1>
                  </Box>
                )}
              </Box>
            ) : (
              <Box display="flex" alignItems="center" mt="14px" pl={{ xs: 2, lg: 0 }}>
                <Box position="relative" width={{ xs: 80, tl: 100 }}>
                  <NumberMark number={index + 1} />
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    ml: 4,
                    mr: { xs: 0, tl: 9 },
                  }}
                >
                  <Link to={`/${type}/${obj.username}`}>
                    <Heading4 sx={{ wordBreak: 'break-all', fontWeight: 400 }}>
                      {obj.username}
                    </Heading4>
                  </Link>
                  <Heading4 sx={{ ml: 1, fontWeight: 400 }}>
                    {formatCompactNumber(obj.value)}
                  </Heading4>
                </Box>
              </Box>
            )}
            <Box display="flex" justifyContent="flex-end">
              <Divider
                sx={{
                  borderColor: '#B1B1B1',
                  mt: 2,
                  width: { xs: '100%', tl: index === 0 ? '100%' : 'calc(100% - 120px)' },
                }}
              />
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default MembersList;
