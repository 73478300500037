import { Box, Typography } from '@mui/material';
import Lottie from 'lottie-react';

import { formatCompactNumber } from '../../../../utils';

import S3Avatar from '../../../../components/S3Avatar';
import { Heading } from '../../../../components/Heading';

import Leagues from '../../../../config/leagues.json';
import SparkleAnimation1 from '../../../../assets/animations/sparkle1.json';
import SparkleAnimation2 from '../../../../assets/animations/sparkle2.json';
import OnboardingFrameImage from '../../../../assets/img/top-banner/avatar-frames/onboarding.png';
import BronzeFrameImage from '../../../../assets/img/top-banner/avatar-frames/bronze.png';
import SilverFrameImage from '../../../../assets/img/top-banner/avatar-frames/silver.png';
import GoldFrameImage from '../../../../assets/img/top-banner/avatar-frames/gold.png';
import PlatinumFrameImage from '../../../../assets/img/top-banner/avatar-frames/platinum.png';
import DiamondFrameImage from '../../../../assets/img/top-banner/avatar-frames/diamond.png';
import ChampionFrameImage from '../../../../assets/img/top-banner/avatar-frames/champion.png';
import ProFrameImage from '../../../../assets/img/top-banner/avatar-frames/pro.png';
import LightEffectImage from '../../../../assets/img/top-banner/avatar-frames/light-effect.png';
import LightEffectImageSm from '../../../../assets/img/top-banner/avatar-frames/light-effect-sm.png';
import MaskImage from '../../../../assets/img/top-banner/avatar-frames/avatar-mask.png';
import CupIcon from '../../../../assets/img/top-banner/avatar-frames/cup-icon.svg';

const AvatarImage = ({ data }) => {
  const { currentLeague, userImage, displayTrophyCount } = data;

  const getFrameImageInfo = (level) => {
    if (level === 1) {
      return {
        image: BronzeFrameImage,
        width: { xs: 274, tl: 310, lg: 400 },
        height: { xs: 362, tl: 350, lg: 452 },
        top: { xs: '20px', tl: '18px', lg: '24px' },
        textColor:
          'linear-gradient(180deg, #5B3619 1.35%, #90633A 31.67%, #F3D1A8 54.79%, #AD763D 75.17%, #7D4313 92.13%)',
      };
    } else if (level === 2) {
      return {
        image: SilverFrameImage,
        width: { xs: 274, tl: 310, lg: 400 },
        height: { xs: 362, tl: 350, lg: 452 },
        top: { xs: '20px', tl: '18px', lg: '24px' },
        textColor: 'linear-gradient(180deg, #777 0%, #C5C5C5 36.98%, #F0F0F0 58.39%, #767676 100%)',
      };
    } else if (level === 3) {
      return {
        image: GoldFrameImage,
        width: { xs: 274, tl: 310, lg: 400 },
        height: { xs: 362, tl: 350, lg: 452 },
        top: { xs: '20px', tl: '18px', lg: '24px' },
        textColor:
          'linear-gradient(180deg, #925F01 0%, #C3A128 36.98%, #FEF89D 58.39%, #F4CE02 100%)',
      };
    } else if (level === 4) {
      return {
        image: PlatinumFrameImage,
        width: { xs: 314, tl: 310, lg: 400 },
        height: { xs: 360, tl: 350, lg: 452 },
        top: { xs: '19px', tl: '18px', lg: '24px' },
        textColor:
          'linear-gradient(180deg, #052B8E 0%, #276DCC 36.98%, #5EAFFE 58.39%, #1547A8 100%)',
      };
    } else if (level >= 5 && level <= 9) {
      return {
        image: DiamondFrameImage,
        width: { xs: 312, tl: 310, lg: 400 },
        height: { xs: 360, tl: 350, lg: 452 },
        top: { xs: '19px', tl: '18px', lg: '23px' },
        textColor:
          'linear-gradient(180deg, #F87AE6 0%, #DF077B 36.98%, #BE3AC8 58.39%, #762188 100%)',
      };
    } else if (level >= 10 && level <= 14) {
      return {
        image: ChampionFrameImage,
        width: { xs: 330, tl: 325, lg: 415 },
        height: { xs: 398, tl: 390, lg: 502 },
        top: { xs: '58px', tl: '57px', lg: '74px' },
        textColor:
          'linear-gradient(142deg, #000 0%, #606060 51.58%, #121212 51.59%, #747174 73.95%, #909090 102.16%)',
      };
    } else if (level >= 15) {
      return {
        image: ProFrameImage,
        width: { xs: 350, tl: 346, lg: 440 },
        height: { xs: 412, tl: 403, lg: 516 },
        top: { xs: '58px', tl: '57px', lg: '73px' },
        left: { tl: '-1px' },
        textColor:
          'linear-gradient(141deg, #FFF 0.22%, #CFD0D2 52.59%, #9FA3A9 52.6%, #B1B3B5 75.3%, #E6E7E8 103.94%)',
      };
    } else {
      return {
        image: OnboardingFrameImage,
        width: { xs: 274, tl: 310, lg: 400 },
        height: { xs: 362, tl: 350, lg: 452 },
        top: { xs: '20px', tl: '18px', lg: '24px' },
        textColor: '#FFF',
      };
    }
  };

  const frameImageInfo = getFrameImageInfo(currentLeague);

  return (
    <Box
      pt={{ xs: 3, tl: 9 }}
      sx={{
        position: 'relative',
        '& img.effect': {
          position: 'absolute',
          top: 0,
          right: 0,
          height: 161,
          opacity: 0.25,
          display: { xs: 'none', tl: 'block' },
        },
        '& img.effect-sm': {
          position: 'absolute',
          top: 66,
          right: 0,
          width: 262,
          height: 328,
          opacity: 0.5,
          display: { xs: 'block', tl: 'none' },
        },
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${frameImageInfo?.image})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          width: frameImageInfo?.width,
          height: frameImageInfo?.height,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: { xs: 236, tl: 231, lg: 297 },
            minHeight: { xs: 253, tl: 247, lg: 318 },
            marginTop: frameImageInfo?.top,
            marginLeft: frameImageInfo?.left,
            maskImage: `url(${MaskImage})`,
            maskSize: '100%',
            maskRepeat: 'no-repeat',
            '& img': {
              objectFit: 'cover',
            },
          }}
        >
          <S3Avatar src={userImage} />
        </Box>
        <Box mt={{ xs: '10px', tl: '9px', md: '6px', lg: '9px' }}>
          <Heading
            size={{ xs: 22, md: 28, lg: 32 }}
            sx={{
              background: frameImageInfo?.textColor,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            {Leagues[currentLeague].name}
          </Heading>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: { xs: '12px', tl: '14px', md: '12px', lg: 2 },
            }}
          >
            <Typography variant="span" mr="6px" sx={{ fontSize: { xs: 16, tl: 14, lg: 16 } }}>
              {formatCompactNumber(displayTrophyCount)}
            </Typography>
            <img src={CupIcon} alt="" />
          </Box>
        </Box>
      </Box>
      {currentLeague > 4 && (
        <>
          <img src={LightEffectImage} alt="" className="effect" />
          <img src={LightEffectImageSm} alt="" className="effect-sm" />
          <Lottie
            animationData={currentLeague >= 10 ? SparkleAnimation1 : SparkleAnimation2}
            style={{
              position: 'absolute',
              top: currentLeague >= 10 ? 120 : 50,
              left: 0,
              right: 0,
              margin: 'auto',
            }}
          />
        </>
      )}
    </Box>
  );
};

export default AvatarImage;
