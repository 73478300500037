import { Box, Typography } from '@mui/material';
import { useState } from 'react';

import { Accordion, AccordionSummary, AccordionDetails } from './CustomAccordion';
import { Heading4 } from '../../../../../components/Heading';
import TabDataContent from './TabDataContent';

const MobileAccordion = ({ gender, accordionData, exerciseChartsData }) => {
  const [expanded, setExpanded] = useState(0);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box mt={3}>
      {accordionData.map((a, index) => (
        <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}>
          <AccordionSummary
            aria-controls={`panel${index + 1}d-content`}
            id={`panel${index + 1}d-header`}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <Box ml={2} display="flex" flexDirection="column" justifyContent="center">
                <Heading4 sx={{ marginBottom: 1 }}>{a.title}</Heading4>
                <Typography>{a.subTitle}</Typography>
              </Box>
              <Box sx={{ '& img': { borderRadius: '50%' } }}>
                <img src={a.icon} alt={a.title} width={64} height={64} />
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <TabDataContent
              title={a.title}
              subTitle={a.subTitle}
              iconImage={a.icon}
              photo={a.photo}
              gender={gender}
              details={exerciseChartsData[a.id]}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default MobileAccordion;
