import { Box, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { Heading1 } from '../../../../components/Heading';
import Container from '../../../../components/Layout/Container';
import StatsBoard from './StatsBoard';
import StatsSummary from './StatsSummary';
import GamePlayVideo from './GamePlayVideo';
import WorkoutCharts from './WorkoutCharts';
import PerformanceChart from './PerformanceChart';

import { formatLocalizedDate, formatCompactNumber } from '../../../../utils';
import WorkoutSummary from './WorkoutSummary';

const StatsSection = ({
  stats,
  videoData,
  showVideo,
  volumeOverTime,
  workOverTime,
  exerciseChartsData,
  gender,
  personalRecordData,
  goToPersonalRecords
}) => {
  const isHideExerciseChart =
    isEmpty(exerciseChartsData.chestPress.details) &&
    isEmpty(exerciseChartsData.latPulldown.details) &&
    isEmpty(exerciseChartsData.overheadPress.details) &&
    isEmpty(exerciseChartsData.squat.details) &&
    isEmpty(exerciseChartsData.standingRow.details) &&
    isEmpty(exerciseChartsData.stiffLeg.details);

  return (
    <Box sx={{ position: 'relative', marginTop: { xs: 8, tl: 15, lg: 22 } }}>
      <Container>
        <Box sx={{ display: 'flex', alignItems: 'center' }} mx={{ xs: 2, tl: '0' }}>
          <Heading1>ALL-TIME STATS</Heading1>
          <Box ml={{ xs: 6, tl: 31, md: 32 }}>
            <Typography>Member Since {formatLocalizedDate(stats.memberSince)}</Typography>
            <Typography>Lifetime XP: {formatCompactNumber(stats.lifetimeXp)}</Typography>
          </Box>
        </Box>
      </Container>
      <StatsBoard stats={stats} />
      <Container>
        <StatsSummary stats={stats} />
      </Container>
      {showVideo && <GamePlayVideo videoData={videoData} />}
      <Container>
        {(!isEmpty(volumeOverTime) || !isEmpty(workOverTime)) && (
          <WorkoutCharts
            averageVolume={stats.averageVolume}
            volumeOverTime={volumeOverTime}
            averageWork={stats.averageWork}
            workOverTime={workOverTime}
          />
        )}
        {!isHideExerciseChart && (
          <PerformanceChart gender={gender} exerciseChartsData={exerciseChartsData} />
        )}
        <WorkoutSummary goToPersonalRecords={goToPersonalRecords} stats={personalRecordData} />
      </Container>
    </Box>
  );
};

export default StatsSection;
