import { Box, Typography } from '@mui/material';

import { formatCompactNumber } from '../../utils';

import Container from '../Layout/Container';
import { Heading1, Heading2 } from '../Heading';
import OverviewBoard from './OverviewBoard';

import OverviewBg from '../../assets/img/team/overview-bg.png';
import OverviewBgMd from '../../assets/img/team/overview-bg-md.png';
import OverviewBgSm from '../../assets/img/team/overview-bg-sm.png';

const Overview = ({ title, description, data, smallHeading }) => {
  const boardData = [
    { title: 'Workouts Completed', value: formatCompactNumber(data?.total_workouts) },
    {
      title: 'Sets | Reps',
      value: `${formatCompactNumber(data?.team_total_sets)}|${formatCompactNumber(
        data?.team_total_reps
      )}`,
      description:
        'A “set” in strength training refers to a specific number of consecutive repetitions of an exercise performed without rest.<br />A “rep” or repetition is one complete motion of a particular exercise, from the starting position back to the starting position again.',
    },
    {
      title: 'Volume (Lbs.)',
      value: formatCompactNumber(data?.team_total_volume),
      description:
        'In strength training, “volume” refers to the total amount of weight lifted, commonly calculated as the number of sets multiplied by the number of repetitions per set, further multiplied by the weight used for each exercise.<br />Example set: 100 lbs. x 10 reps = 1000 lbs. of volume',
    },
    {
      title: 'Work (Ft x Lbs.)',
      value: formatCompactNumber(data?.team_total_work),
      description:
        'Work in foot-pounds (ft.*lbs) is a measure of the energy expended in lifting a weight over a certain distance. It is calculated by multiplying the force exerted on the weight (measured in pounds) by the distance the weight is moved (measured in feet).<br />Unlike volume, work also takes into account the rep length.',
    },
    { title: 'Calories Burned', value: formatCompactNumber(data?.team_total_calories_burned) },
  ];

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundImage: {
            xs: `url(${OverviewBgSm})`,
            tl: `url(${OverviewBgMd})`,
            lg: `url(${OverviewBg})`,
          },
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          padding: { xs: 4, tl: '32px 76px', md: '40px 64px' },
        }}
      >
        {!smallHeading && <Heading1>{title}</Heading1>}
        {!!smallHeading && <Heading2>{title}</Heading2>}
        <Typography mt={2} sx={{ textAlign: 'center' }}>
          {description}
        </Typography>
        <Box width="100%" mt={5}>
          <OverviewBoard data={boardData} />
        </Box>
      </Box>
    </Container>
  );
};

export default Overview;
