import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

import FullScreenBox from '../../components/Layout/FullScreenBox';
import { Heading } from '../../components/Heading';

const PageNotFound = () => {
  return (
    <FullScreenBox flexDirection="column">
      <SentimentVeryDissatisfiedIcon sx={{ fontSize: 200 }} />
      <Heading size={120}>404</Heading>
      <Heading size={50} sx={{ mt: 6, fontWeight: 400 }}>
        Page not found
      </Heading>
    </FullScreenBox>
  );
};

export default PageNotFound;
