import { Box } from '@mui/material';
import { PostTargetType } from '@amityco/js-sdk';

import MetaTags from '../../components/MetaTags';
import FooterMenu from '../../components/Footer/FooterMenu';
import SocialFeed from '../../components/SocialFeed';
import TopBanner from '../Leaderboards/TopBanner';
import GetRewarded from './GetRewarded';
import RewardMilestone from './RewardMilestone';
import GemsStore from './GemsStore';
import Leaderboards from './Leaderboards';

import { SOCIAL_SEARCH_TYPE } from '../../config/constants';
import LeaderboardBg from '../../assets/img/unity-gems/bg.webp';

const UnityGems = () => {
  const breadcrumbs = [
    { title: 'Home', link: 'https://www.blackbox-vr.com' },
    { title: 'Community', link: '/' },
    { title: 'Unity Gems' }
  ];

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - Unity Gems And Rewards`}
        description={`Black Box VR - Unity Gems And Rewards`}
      />
      <TopBanner
        title={"Black Box VR Community"}
        subTitle={"Unity Gems And Rewards"}
        breadcrumbs={breadcrumbs}
      />
      <Box
        sx={{
          height: {
            xs: 300,
            tl: 592,
          },
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${LeaderboardBg})`,
        }}
      >
      </Box>
      <GetRewarded />
      <RewardMilestone />
      <GemsStore />
      <Leaderboards />
      <SocialFeed
        userId={'5cb20e57c7a3f404ff343783'}
        targetType={PostTargetType.CommunityFeed}
        searchType={SOCIAL_SEARCH_TYPE.GEM}
        sx={{ mt: { xs: 8, tl: 10, lg: 16 } }}
      />
      <FooterMenu />
    </Box>
  );
};

export default UnityGems;
