import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { PostTargetType } from '@amityco/js-sdk';

import MetaTags from '../../components/MetaTags';
import SocialFeed from '../../components/SocialFeed';
import Overview from '../../components/Overview';
import Loading from '../../components/Loading';
import TopBanner from '../Leaderboards/TopBanner';
import BoiseNewsEvents from '../Profiles/GymProfile/BoiseNewsEvents';
import RightCornerTag from '../Profiles/MemberProfile/Details/RightCornerTag';
import Banner from './Banner';
import FeaturedMember from './FeaturedMember';
import SeasonInfo from './SeasonInfo';
import GymProfileFooter from '../Profiles/GymProfile/Footer';

import { SOCIAL_SEARCH_TYPE } from '../../config/constants';
import CommunitydBg from '../../assets/img/community/bg.webp';
import { useBoiseNewsEvents } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';

const Community = () => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ data, setData ] = useState(null);

  useEffect(() => {
    callBackbonePostApi({
      action: "getCommunityAggregatedData",
    }, '')
    .then((data) => {
      setIsLoading(false);

      if (data?.status === 200 && data?.data) {
        setData(data?.data);
      }
    })
    .catch(() => {
      setIsLoading(true);
    });
  }, []);

  const {
    data: { data: newsData, isLoading: isNewsLoading },
  } = useBoiseNewsEvents();
  const breadcrumbs = [
    { title: 'Home', link: 'https://www.blackbox-vr.com' },
    { title: 'Community' }
  ];

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - The VR Athlete Community`}
        description={`Black Box VR - The VR Athlete Community`}
      />
      <RightCornerTag />
      <TopBanner
        title={"YOUR NEXT-GEN FIT SQUAD"}
        subTitle={"THE VR ATHLETE COMMUNITY"}
        breadcrumbs={breadcrumbs}
      />
      <Box
        sx={{
          height: {
            xs: 300,
            tl: 592,
          },
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: `url(${CommunitydBg})`,
        }}
      >
      </Box>
      <Banner />
      {!!isLoading && <Loading />}
      {!isLoading && data && <Overview
        title="Community Totals"
        description=""
        data={{
          total_workouts: data.total_workoutcount ?? 0,
          team_total_sets: data.total_setcount ?? 0,
          team_total_reps: data.total_repcount ?? 0,
          team_total_volume: data.total_volume ?? 0,
          team_total_work: data.total_work ?? 0,
          team_total_calories_burned: data.total_caloriesburned ?? 0,
        }}
        smallHeading
      />}
      <FeaturedMember />
      <SeasonInfo />
      <BoiseNewsEvents
        name="News And Events"
        data={newsData}
        viewAllSx={{
          background: "transparent",
          borderColor: "#0076FF",
        }}
        isLoading={isNewsLoading}
      />
      <SocialFeed
        userId={'5cb20e57c7a3f404ff343783'}
        targetType={PostTargetType.CommunityFeed}
        searchType={SOCIAL_SEARCH_TYPE.GYM}
        sx={{ mt: { xs: 8, tl: 10, lg: 16 } }}
      />
      <GymProfileFooter />
    </Box>
  );
};

export default Community;
