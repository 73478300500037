import { Box } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import Container from '../../../../components/Layout/Container';
import { Heading1 } from '../../../../components/Heading';
import BoiseTeamCard from './BoiseTeamCard';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const BoiseTeams = ({ name, teams }) => {
  return (
    <Container>
      <Box mt={{ xs: 0, tl: 4, lg: 10 }} mx={{ xs: 2, tl: 0 }}>
        <Heading1>{name}</Heading1>
        <Box mt={{ xs: 3, tl: 4, lg: 6 }}>
          <Swiper
            slidesPerView={2}
            spaceBetween={20}
            breakpoints={{
              600: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 4,
                spaceBetween: 25,
              },
              1200: {
                slidesPerView: 5,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            className="custom-swiper"
          >
            {Object.keys(teams).map((id, index) => (
              <SwiperSlide key={index}>
                <BoiseTeamCard data={teams[id]} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Box>
    </Container>
  );
};

export default BoiseTeams;
