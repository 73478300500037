import { Box, Typography } from '@mui/material';

import { Heading1, Heading2 } from '../../../components/Heading';

const TopBanner = () => {
  return (
    <Box
      sx={{
        background: '#141516',
      }}
    >
      <Box textAlign="center" px={4} py={6}>
        <Heading1>Final Step:</Heading1>
        <br />
        <Heading2>Schedule your intro workout!</Heading2>
      </Box>
      <Box sx={{ background: '#353A3E', textAlign: 'center' }} p={5}>
        <Typography sx={{ fontSize: { xs: 20, lg: 32 } }}>
          Use this discount code in the form below to claim your $1 workout offer:
        </Typography>
        <Heading2 sx={{ py: 5 }}>Transform44</Heading2>
        <Typography sx={{ fontSize: { xs: 16, lg: 24 } }}>
          Choose a date and time below for your intro workout. This code will expire soon and this
          location has very limited capacity. Schedule your workout right away to hold your spot.
        </Typography>
      </Box>
    </Box>
  );
};

export default TopBanner;
