import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';
import { isSafari } from 'react-device-detect';

import { formatCompactNumber } from '../../../../utils';
import Leagues from '../../../../config/leagues.json';

import { Heading4 } from '../../../../components/Heading';
import LocalImage from '../../../../components/LocalImage';
import S3Avatar from '../../../../components/S3Avatar';
import CaptainMark from '../../../../components/CaptainMark';
import { CustomButton } from '../../../../components/Buttons';
import SummaryCell from '../../../../components/SummaryCell';

const columns = [
  {
    id: 'image',
    label: 'Name',
    width: { xs: 72, lg: 128 },
    format: (value, isCaptain) => (
      <Link to={`/member/${value.name}`}>
        <Box
          sx={{
            position: 'relative',
            width: { xs: 64, lg: 96 },
            height: { xs: 64, lg: 96 },
            mr: { xs: 0, lg: 4 },
            ' img': { width: '100%', height: '100%', objectFit: 'cover' },
          }}
        >
          <S3Avatar src={value.url} />
          {isCaptain && <CaptainMark />}
        </Box>
      </Link>
    ),
  },
  {
    id: 'title',
    label: '',
    minWidth: '12vw',
    format: (value) => (
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Link to={`/member/${value.name}`}>
          <Heading4 sx={{ fontSize: { xs: 16, lg: 24 } }}>{value.name}</Heading4>
        </Link>
        {value.desc && (
          <Typography mt={{ xs: 1, lg: 2 }} sx={{ fontSize: { xs: 14, lg: 16 } }}>
            {value.desc}
          </Typography>
        )}
      </Box>
    ),
  },
  {
    id: 'league',
    label: 'League Ranking',
    labelDescription:
      'Win workout battles to earn trophies. The more trophies you have, the higher your League Ranking!   The competitors get more and more difficult, which will keep you from plateauing with your fitness goals. Let’s see how far you can make it!<br />There are 19 Leagues, each with ten Arenas:<br /><ul><li>Bronze</li><li>Silver</li><li>Gold</li><li>Platinum</li><li>Diamond I - V</li><li>Championship I - V</li><li>Pro I - V</li></ul>',
    format: (value) => (
      <Box display="flex" alignItems="center">
        <LocalImage
          src={`img/ranking/${Leagues[value].key}.png`}
          alt={Leagues[value].name}
          width={32}
          height={32}
        />
        <Heading4 sx={{ ml: 2 }}>{Leagues[value].name}</Heading4>
      </Box>
    ),
  },
  {
    id: 'trophies',
    label: 'Trophies',
    labelDescription:
      'Win workout battles to earn trophies!  The number of trophies you get depends on how many points you beat your competitor by. But be careful! If you lose your battle, you lose trophies. Push hard, level up your body and your battle deck, and you’ll be moving up in no time.',
    format: (value) => <Heading4>{formatCompactNumber(value)}</Heading4>,
  },
  {
    id: 'xp',
    label: 'Lifetime XP',
    labelDescription:
      'XP is earned by going to battle, working out hard, and completing quests!  Quests guide you to do the things that will give you the best possible fitness results while making it more fun than any traditional workout plan.<br />The more quests you complete by staying on track with your fitness goals, the higher your total Lifetime XP will climb. As you reach higher levels of Lifetime XP, you’ll unlock new game features, new Champions, new Powerups, new cosmetics, and new IRL rewards like shirts, hats, and more.',
    format: (value) => <Heading4>{formatCompactNumber(value)}</Heading4>,
  },
  {
    id: 'arrow',
    lable: '',
    width: 50,
    align: 'right',
    format: (value) => (
      <Link to={`/member/${value}`}>
        {' '}
        <ArrowForwardIcon className="action" sx={{ fontSize: { xs: 24, lg: 48 } }} />
      </Link>
    ),
  },
];

const MembersTable = ({ members }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <TableContainer>
      <StyledTable>
        <TableHead>
          <TableRow>
            <TableCell key="empty-hd-1" className="empty" />
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                sx={{ width: column.width, minWidth: column.minWidth, '& p': { fontSize: 16 } }}
              >
                <SummaryCell
                  label={column.label}
                  description={column.labelDescription}
                  isQuestion={!!column.labelDescription}
                  toolipSize="large"
                  textAlign="flex-start"
                  noMargin
                />
              </TableCell>
            ))}
            <TableCell key="empty-hd-2" className="empty" />
          </TableRow>
        </TableHead>
        <TableBody>
          {members.map((row, index) => {
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={index}
                className={index === 0 ? 'captain' : ''}
              >
                <TableCell key="empty-1" className="empty" />
                {columns.map((column) => {
                  const value = column.id !== 'arrow' ? row[column.id] : row?.title?.name;

                  if (index === 0 || isSmallScreen) {
                    if (column.id === 'image') {
                      return (
                        <TableCell key={column.id} colSpan="2">
                          <Box
                            display="flex"
                            alignItems={{ xs: 'flex-start', lg: 'center' }}
                            flexDirection={{ xs: 'column', lg: 'row' }}
                          >
                            {column.format ? column.format(value, index === 0) : value}
                            {columns[1].format(row[columns[1].id])}
                          </Box>
                        </TableCell>
                      );
                    }
                    if (column.id !== 'title') {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    }
                  } else {
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  }

                  return null;
                })}
                <TableCell key="empty-2" className="empty" />
              </TableRow>
            );
          })}
          {Array.from(Array(12 - members.length).keys()).map((_, index) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={`available-${index}`}>
              <TableCell className="empty" />
              <TableCell>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: 64, lg: 96 },
                    height: { xs: 64, lg: 96 },
                    border: '1px solid #313131',
                  }}
                >
                  <Typography
                    fontSize={12}
                    sx={{ maxWidth: 60, textAlign: 'center', lineHeight: '20px' }}
                  >
                    Spot Available
                  </Typography>
                </Box>
              </TableCell>
              <TableCell colSpan="5">
                <Box position="relative" display="flex" justifyContent="center" alignItems="center">
                  <Heading4 sx={{ mr: { xs: 8, lg: 0 } }}>Available</Heading4>
                  <CustomButton
                    className="action"
                    sx={{ position: 'absolute', right: 0, display: { xs: 'none', lg: 'block' } }}
                  >
                    Request to Join
                  </CustomButton>
                  <CustomButton
                    className="action"
                    sx={{
                      position: 'absolute',
                      right: 0,
                      height: 27,
                      padding: '4px 16px',
                      lineHeight: '20px',
                      display: { xs: 'block', lg: 'none !important' },
                    }}
                  >
                    Join
                  </CustomButton>
                </Box>
              </TableCell>
              <TableCell className="empty" />
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default MembersTable;

const StyledTable = styled(Table)((props) => ({
  '& .MuiTableRow-root': {
    '& td': {
      cursor: 'auto',

      '&:nth-of-type(2) > div > a > div': {
        marginBottom: 16,
      },
    },

    '&.captain': {
      background: !isSafari
        ? 'linear-gradient(90deg, rgba(187, 10, 148, 0.50) 0%, rgba(147, 4, 115, 0.45) 64.69%, rgba(148, 1, 115, 0.15) 88.66%)'
        : 'rgba(187, 10, 148, 0.50) !important',
      backdropFilter: 'blur(12px)',

      '& td': {
        border: 0,
      },
      '& td:nth-of-type(2)': {
        '& > div > a > div': {
          width: 128,
          height: 128,
          marginBottom: 24,

          [props.theme.breakpoints.up('lg')]: {
            width: 152,
            height: 152,
            marginBottom: 0,
          },

          '& img': {
            border: '1px solid #0099CD',
          },
        },
      },
    },

    '& .MuiTableCell-root': {
      background: 'transparent',
      color: '#FFF',
      fontSize: 16,
      borderTop: '1px solid #B1B1B1',
      borderBottom: 0,
      padding: props.theme.spacing(2, 1),

      '&.empty': {
        border: 0,
        padding: 0,
        width: 'calc(50vw - 625px)',
        minWidth: '7vw',
        pointerEvents: 'none',
        cursor: 'auto',
      },

      [props.theme.breakpoints.up('lg')]: {
        '& .action': {
          display: 'none',
        },
      },
    },
    '& th.MuiTableCell-root': {
      padding: props.theme.spacing(3, 1),
    },
    '& td:nth-of-type(2)': {
      [props.theme.breakpoints.up('lg')]: {
        border: 0,
      },
    },

    [props.theme.breakpoints.up('lg')]: {
      '&:hover': {
        '& .action': {
          display: 'inline-flex',
        },
      },
    },
  },

  '& tr:nth-of-type(2)': {
    '& td': {
      border: '0 !important',
    },
  },
}));
