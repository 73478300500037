import { Box, Typography } from '@mui/material';
import { useState, useEffect } from 'react';

import Container from '../../../components/Layout/Container';
import { Heading2, Heading3 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';
import Loading from '../../../components/Loading';

import { formatCompactNumber } from '../../../utils';
import { callBackbonePostApi } from '../../../services/backboneApiService';

const formatDate = (date) => {
  if (!date) {
    return "";
  }
  const parts = date.split(",");
  const subParts = parts[0].split(" ");
  return subParts[0].substring(0, 3) + " " + subParts[1] + ", " + parts[1].substring(1, 5);
};

const SeasonRecap = ({
  data,
  season,
}) => {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ seasonData, setData ] = useState(null);

  useEffect(() => {
    callBackbonePostApi({
      action: "getSeasonDetails",
      seasonNumber: parseInt(season) 
    }, '')
    .then((res) => {
      setIsLoading(false);

      if (res?.status === 200 && res?.data) {
        setData(res?.data);
      }
    })
    .catch(() => {
      setIsLoading(true);
    });
  }, [season]);

  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading2>YOUR SEASON {data?.seasonId} RECAP!</Heading2>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Typography sx={{
            fontSize: "24px",
            lineHeight: "32px",
            marginTop: 2,
          }}>
            {formatDate(seasonData?.month1Start)} - {formatDate(seasonData?.seasonEnd)}
          </Typography>
          <Box sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              tl: "row",
            },
            justifyContent: "space-between",
            marginTop: 6,
            padding: "25px 16px 25px 16px",
            backgroundColor: "#141516"
          }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalImage src="img/seasons/trophy.webp" alt="Trophies Gained" loading="lazy" height="124px" />
              <Box ml={2}>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  marginBottom: 1,
                }}>Trophies Gained</Typography>
                <Heading3 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.trophiesearned)}</Heading3>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalImage src="img/seasons/volume.webp" alt="Workouts Completed" loading="lazy" height="124px" />
              <Box ml={2}>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  marginBottom: 1,
                }}>Workouts Completed</Typography>
                <Heading3 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.workoutcount?.value)}</Heading3>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalImage src="img/seasons/season-xp.webp" alt="Season XP Earned" loading="lazy" height="124px" />
              <Box ml={2}>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  marginBottom: 1,
                }}>Season XP Earned</Typography>
                <Heading3 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.final_season_xp?.value)}</Heading3>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocalImage src="img/team/team-raid-points.webp" alt="Raid Points Earned" loading="lazy" height="124px" />
              <Box ml={2}>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "26px",
                  marginBottom: 1,
                }}>Raid Points Earned</Typography>
                <Heading3 sx={{ marginBottom: 2 }}>{formatCompactNumber(data?.final_raid_points?.value)}</Heading3>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

export default SeasonRecap;
