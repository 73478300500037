import { Box, Typography } from '@mui/material';

import { Heading } from '../../../../../components/Heading';

const BadgeFrame = ({ background, mdBackground, label, value }) => {
  return (
    <Box
      sx={{
        background: {
          xs: `url(${mdBackground})`,
          md: `url(${background})`,
        },
        width: { xs: 310, md: 407 },
        height: 88,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{
          fontSize: { xs: 20, tl: 22, md: 24 },
          textTransform: 'uppercase',
          ml: { xs: 12, md: 13 },
          mr: 2,
        }}
      >
        {label}
      </Typography>
      <Heading sx={{ fontSize: { xs: 22, tl: 28, md: 48 } }}>{value}</Heading>
    </Box>
  );
};

export default BadgeFrame;
