import * as React from 'react';
import { Box, Typography, Grid, Menu, MenuItem } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading2, Heading3 } from '../../../components/Heading';
import { formatCompactNumber } from '../../../utils';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useMemo } from 'react';

const periods = [
  "Single Workout",
  "Week",
  "Month",
  "Season"
];

const ExerciseDetails = ({
  data
}) => {
  const [anchorEl, setAnchorEl] = React.useState({});
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    details: periods[0]
  });

  const open = {
    details: Boolean(anchorEl.details),
  }
  const handleOpenMenu = (event, key) => {
    setAnchorEl({
      ...anchorEl,
      [key]: event.currentTarget,
    });
  };

  const handleClose = (key) => {
    setAnchorEl({
      ...anchorEl,
      [key]: null,
    });
  };

  const handleSelect = (newPeriod, key) => {
    setSelectedPeriod({
      ...selectedPeriod,
      [key]: newPeriod,
    });
    setAnchorEl({
      ...anchorEl,
      [key]: null,
    });
  };

  const getPeriodKey = useMemo(() => {
    if (selectedPeriod?.details === "Single Workout") {
      return "AllTime";
    } else {
      return selectedPeriod?.details;
    }
  }, [selectedPeriod])

  return (
    <Container sx={{ marginTop: 8 }}>
      <Box
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row"
        }}
        gap={{
          xs: 4,
          sm: 8
        }}
      >
        <Heading2 sx={{ '& br': { display: { xs: 'none', md: 'block' } } }}>By Exercise</Heading2>
        <Box display="flex" mt={0.5}>
          <Box
            sx={{
              borderRadius: "8px",
              backgroundColor: "rgb(238, 238, 238)",
              color: "rgb(33, 43, 54)",
              height: "36px",
              position: "relative",
              paddingLeft: "10px",
              paddingRight: "30px",
            }}
            mr={1}
            onClick={(event) => handleOpenMenu(event, "details")}
          >
            <Typography
              sx={{ cursor: "pointer" }}
              fontSize={14}
              lineHeight="36px"
              display="flex"
              alignItems="center"
            >
              {selectedPeriod.details}
              <CalendarTodayIcon sx={{ marginLeft: 1, fontSize: 14 }} />
            </Typography>
            <Box
              sx={{
                position: "absolute",
                right: "0px",
                top: "6px",
              }}
            >
              <KeyboardArrowDownIcon />
            </Box>
          </Box>
          <Menu
            anchorEl={anchorEl.details}
            open={open.details}
            onClose={() => handleClose("details")}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              style: {},
            }}
          >
            {periods.map((option) => (
              <MenuItem key={option} selected={option === selectedPeriod.details} onClick={() => handleSelect(option, "details")}>
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Grid
        container
        display={{
          xs: 'none',
          md: 'flex'
        }}
        mt={3}
      >
        <Grid item md={4} mb={3}>
          <Typography sx={{
            fontSize: "14px",
            lineHeight: "22px",
            opacity: 0.8,
          }}>
            Exercise
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography sx={{
            fontSize: "14px",
            lineHeight: "22px",
            opacity: 0.8,
            textAlign: "center",
          }}>
            Sets
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography sx={{
            fontSize: "14px",
            lineHeight: "22px",
            opacity: 0.8,
            textAlign: "center",
          }}>
            Reps
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography sx={{
            fontSize: "14px",
            lineHeight: "22px",
            opacity: 0.8,
            textAlign: "center",
          }}>
            Work (Ft.-Lbs.)
          </Typography>
        </Grid>
        <Grid item md={2}>
          <Typography sx={{
            fontSize: "14px",
            lineHeight: "22px",
            opacity: 0.8,
            textAlign: "center",
          }}>
            Total Volume (Lbs.)
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        mt={{
          xs: 2,
          sm: 0
        }}
      >
        {(data?.exerciseDetails || []).map((item, index) => <React.Fragment key={item?.shortCode}>
          <Grid item mt={index ? 2 : 0} xs={12} md={4} rowSpacing={2} bgcolor={index % 2 ? '#141516' : ''}>
            <Box sx={{
              display: "flex",
            }}>
              <img
                src={item?.maleStartOfRepImage}
                width="132"
                height="132"
                alt="no-cage"
              />
              <Box ml={1}>
                <Heading3>{item?.exerciseName}</Heading3>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  marginTop: 1,
                }}>
                  {item?.attackName || item?.name}
                </Typography>
                <Typography sx={{
                  fontSize: "16px",
                  lineHeight: "22px",
                  color: "#CECECE",
                  marginTop: 1,
                }}>
                  {item?.musclesWorked}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item mt={{ md: index ? 2 : 0 }} xs={3} md={2} bgcolor={index % 2 ? '#141516' : ''} sx={{
            textAlign: "center"
          }}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                display={{
                  md: 'none'
                }}
                flexGrow={1}
                mb={1}
                sx={{
                  fontSize: "14px",
                  lineHeight: "22px",
                  opacity: 0.8,
                }}
              >
                Sets
              </Typography>
              <Heading2>{formatCompactNumber(data?.personalBests?.[getPeriodKey]?.[`best_${item?.exerciseKey}_sets`]?.value)}</Heading2>
            </Box>
          </Grid>
          <Grid item mt={{ md: index ? 2 : 0 }} xs={3} md={2} bgcolor={index % 2 ? '#141516' : ''} sx={{
            textAlign: "center"
          }}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                display={{
                  md: 'none'
                }}
                flexGrow={1}
                mb={1}
                sx={{
                  fontSize: "14px",
                  lineHeight: "22px",
                  opacity: 0.8,
                }}
              >
                Reps
              </Typography>
              <Heading2>{formatCompactNumber(data?.personalBests?.[getPeriodKey]?.[`best_${item?.exerciseKey}_reps`]?.value)}</Heading2>
            </Box>
          </Grid>
          <Grid item mt={{ md: index ? 2 : 0 }} xs={3} md={2} bgcolor={index % 2 ? '#141516' : ''} sx={{
            textAlign: "center"
          }}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                display={{
                  md: 'none'
                }}
                flexGrow={1}
                mb={1}
                sx={{
                  fontSize: "14px",
                  lineHeight: "22px",
                  opacity: 0.8,
                }}
              >
                Work (Ft.-Lbs.)
              </Typography>
              <Heading2>{formatCompactNumber(data?.personalBests?.[getPeriodKey]?.[`best_${item?.exerciseKey}_work`]?.value)}</Heading2>
            </Box>
          </Grid>
          <Grid item mt={{ md: index ? 2 : 0 }} xs={3} md={2} bgcolor={index % 2 ? '#141516' : ''} sx={{
            textAlign: "center"
          }}>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                display={{
                  md: 'none'
                }}
                flexGrow={1}
                mb={1}
                sx={{
                  fontSize: "14px",
                  lineHeight: "22px",
                  opacity: 0.8,
                }}
              >
                Total Volumn (Lbs.)
              </Typography>
              <Heading2>{formatCompactNumber(data?.personalBests?.[getPeriodKey]?.[`best_${item?.exerciseKey}_volume`]?.value)}</Heading2>
            </Box>
          </Grid>
        </React.Fragment>)}
      </Grid>
    </Container>
  );
};

export default ExerciseDetails;
