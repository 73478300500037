import { Box, IconButton } from '@mui/material';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Container from '../../../components/Layout/Container';
import LocalImage from '../../../components/LocalImage';
import { Heading2, Heading3, Heading4 } from '../../../components/Heading';
import { formatCompactNumber } from '../../../utils';
import QuestionIcon from '../../../assets/img/stats/question-icon.png';
import { HtmlTooltip, TooltipContent } from '../../../components/Tooltip';

const FragmentsFound = ({
  data,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Box mt={4}>
      <Container>
        <Heading2>Fragments Found</Heading2>
        <Box sx={{
          backgroundColor: "#141516",
          marginTop: 4,
          padding: {
            xs: 2,
            tl: 4,
          },
        }}>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 4,
          }}>
            <LocalImage src="img/team/fragments.webp" alt="heroic" loading="lazy" height="128px" />
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Box ml={1}>
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                }}>
                  <Heading4 sx={{ color: "#D4D4D4" }}>Total Found</Heading4>
                  <HtmlTooltip
                    title={<TooltipContent title="" desc="Fragments are used to upgrade powerful Gear items! When somebody finds Fragments during an Arena Raid, every member of their team earns that same amount. Everyone benefits!" />}
                    placement="bottom"
                    arrow
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    onClose={handleTooltipClose}
                    open={open}
                  >
                    <IconButton onClick={handleTooltipOpen} sx={{ color: '#ffffff' }}>
                      <img src={QuestionIcon} width="16px" height="16px" alt="total found" />
                    </IconButton>
                  </HtmlTooltip>
                </Box>
                <Heading2>{formatCompactNumber(data?.total_team_fragments?.total)}</Heading2>
              </Box>
            </ClickAwayListener>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              tl: "row",
            },
            justifyContent: "space-around"
          }}>
            <Box>
              <Heading4 sx={{ textAlign: {
                xs: "left",
                tl: "center",
                color: "#D4D4D4",
              }}}>Common</Heading4>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 1,
              }}>
                <LocalImage src="img/team/fragments-1.webp" alt="common" loading="lazy" height="80px" />
                <Box ml={1}>
                  <Heading3>{formatCompactNumber(data?.total_team_fragments?.common)}</Heading3>
                </Box>
              </Box>
            </Box>
            <Box>
              <Heading4 sx={{ textAlign: {
                xs: "left",
                tl: "center",
                color: "#D4D4D4",
              }}}>Uncommon</Heading4>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 1,
              }}>
                <LocalImage src="img/team/fragments-2.webp" alt="uncommon" loading="lazy" height="80px" />
                <Box ml={1}>
                  <Heading3>{formatCompactNumber(data?.total_team_fragments?.uncommon)}</Heading3>
                </Box>
              </Box>
            </Box>
            <Box>
              <Heading4 sx={{ textAlign: {
                xs: "left",
                tl: "center",
                color: "#D4D4D4",
              }}}>Rare</Heading4>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 1,
              }}>
                <LocalImage src="img/team/fragments-3.webp" alt="rare" loading="lazy" height="80px" />
                <Box ml={1}>
                  <Heading3>{formatCompactNumber(data?.total_team_fragments?.rare)}</Heading3>
                </Box>
              </Box>
            </Box>
            <Box>
              <Heading4 sx={{ textAlign: {
                xs: "left",
                tl: "center",
                color: "#D4D4D4",
              }}}>Heroic</Heading4>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                marginTop: 1,
              }}>
                <LocalImage src="img/team/fragments-4.webp" alt="heroic" loading="lazy" height="80px" />
                <Box ml={1}>
                  <Heading3>{formatCompactNumber(data?.total_team_fragments?.heroic)}</Heading3>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FragmentsFound;
