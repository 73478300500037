import { useState } from "react";
import { Box, ClickAwayListener, IconButton, Typography } from "@mui/material";
import { Heading2 } from "../../../components/Heading";
import QuestionIcon from '../../../assets/img/stats/question-icon.png';
import { HtmlTooltip, TooltipContent } from "../../../components/Tooltip";

const Description = ({
  title,
  data,
  percent,
  tooltip
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 3,
      width: { xs: '360px', sm: '420px', tl: '100%' },
      margin: 'auto'
    }}>
      <ClickAwayListener
        onClickAway={handleTooltipClose}
        children={
          <Box sx={{
            width: '50%',
            display: "flex",
            flexDirection: "row",
            alignItems: { xs: 'left', tl: 'center' },
            gap: 1,
            borderLeft: '1px solid white',
            paddingLeft: { xs: 2, sm: 0 }
          }}>

            <Typography
              flexGrow={1}
              sx={{
                marginLeft: { xs: 0, sm: 2, tl: 3 }
              }}
            >{title}</Typography>
            <HtmlTooltip
              title={<TooltipContent title="" desc={tooltip} />}
              placement="bottom"
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
              onClose={handleTooltipClose}
              open={tooltipOpen}
            >
              <IconButton onClick={handleTooltipOpen} sx={{ color: '#ffffff' }}>
                <img src={QuestionIcon} width="16px" height="16px" alt="total found" />
              </IconButton>
            </HtmlTooltip>
          </Box>
        }
      />
      <Box sx={{
        width: '50%',
        textAlign: { xs: 'right', tl: 'left' }
      }}>
        <Heading2>{data || 0}{percent === true ? '%' : ''}</Heading2>
      </Box>
    </Box>
  );
}

export default Description;
