import { Box, Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import SeasonInfo from '../../../Community/SeasonInfo';
import Container from '../../../../components/Layout/Container';
import LocalImage from '../../../../components/LocalImage';
import { Heading1, Heading3, Heading4 } from '../../../../components/Heading';
import NumberMark from '../../../../components/NumberMark';
import S3Avatar from '../../../../components/S3Avatar';
import { HtmlTooltip, TooltipContent } from '../../../../components/Tooltip';
import QuestionIcon from '../../../../assets/img/stats/question-icon.png';

import { formatCompactNumber, sortObjectArray } from '../../../../utils';

const TeamProfileSeasonInfo = ({
  data
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const members = [];
  data?.members_info?.forEach((m) => {
    const member = {
      username: m.member_username,
      id: m.member_id,
      image: m.member_profile_pic_url,
      league: m.member_league,
      raid_points: +m.member_raid_points,
      xp: m.member_xp,
    };

    if (member.raid_points > 0) {
      members.push(member);
    }
  });
  const sortedMembers = [...sortObjectArray(members, 'raid_points', 'desc')];

  return (
    <Box>
      <SeasonInfo />
      {data?.team_raid_points > 0 && <Container>
        <Box sx={{
          backgroundColor: "#141516",
          marginTop: 4,
          padding: {
            xs: 2,
            tl: 4,
          },
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              tl: "row",
            },
            justifyContent: "space-between"
          }}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Box sx={{
                display: "flex",
              }}>
                <LocalImage src="img/team/team-raid-points.webp" alt="Team Raid Points" loading="lazy" height="131px" />
                <Box ml={3}>
                  <Box sx={{
                    display: "flex",
                  }}>
                    <Heading3>TEAM RAID POINTS</Heading3>
                    <HtmlTooltip
                      title={<TooltipContent title="" desc="Raid Points are a scoring mechanism used during Arena Raids, a competitive team event within the game. During these raids, you and your team battle against NPC Challengers, and the difficulty of the challengers increases as you defeat each one. The higher the Challenger you defeat, the higher your Raid Points score! These points contribute to your team’s total score for the event, influencing your rank on the leaderboard and the rewards you receive." />}
                      placement="bottom"
                      arrow
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      onClose={handleTooltipClose}
                      open={open}
                    >
                      <IconButton onClick={handleTooltipOpen} sx={{ color: '#ffffff' }}>
                        <img src={QuestionIcon} width="16px" height="16px" alt="total found" />
                      </IconButton>
                    </HtmlTooltip>
                  </Box>
                  <Heading1>{data?.team_raid_points}</Heading1>
                </Box>
              </Box>
            </ClickAwayListener>
            <Box>
              <Heading3>GYM RANK</Heading3>
              <Heading1>{data?.gym_rank}</Heading1>
            </Box>
            <Box mt={{
              xs: 2,
              tl: 0,
            }}>
              <Heading3>GYM LEADERBOARD</Heading3>
              <Link to="/leaderboards?category=teamRaid&period=currentSeason">
                <Heading4 sx={{ marginTop: 1, color: "#0076FF" }}>THIS SEASON</Heading4>
              </Link>
              <Link to="/leaderboards?category=teamRaid&period=lastSeason">
                <Heading4 sx={{ marginTop: 1, color: "#0076FF" }}>LAST SEASON</Heading4>
              </Link>
            </Box>
          </Box>
          {sortedMembers.length > 0 && <Box sx={{
            marginTop: 9,
          }}>
            <Heading3>TOP SEASON RAID POINTS CONTRIBUTORS</Heading3>
            <Box sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                tl: "row",
              },
              justifyContent: "space-between"
            }}>
              {!!sortedMembers[0] && <Box display="flex" mt={4} pl={{ xs: 2, tl: 3 }}>
                <Box display="flex" alignItems="center">
                  <Box
                    position="relative"
                    width={{ xs: 120, tl: 248 }}
                    height={{ xs: 120, tl: 248 }}
                  >
                    <NumberMark number={1} />
                    <Link to={`/member/${sortedMembers[0].username}`}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          padding: '1px',
                          background: 'linear-gradient(180deg, #925F01 0%, #C3A128 30%, #FEF89D 40%, #F4CE02 80%)',
                        }}
                      >
                        <S3Avatar src={sortedMembers[0].image} />
                      </Box>
                    </Link>
                  </Box>
                </Box>
                <Box
                  sx={{
                    ml: 4,
                    mr: { xs: 0, tl: 9 },
                  }}
                >
                  <Box>
                    <Link to={`/member/${sortedMembers[0].username}`}>
                      <Heading4 sx={{ wordBreak: 'break-all' }}>{sortedMembers[0].username}</Heading4>
                    </Link>
                    <Typography mt={1} fontSize="16px">{sortedMembers[0].name}</Typography>
                  </Box>
                  <Heading3 sx={{ mt: 1 }}>{formatCompactNumber(sortedMembers[0].raid_points)}</Heading3>
                </Box>
              </Box>}
              <Box>
                {!!sortedMembers[1] && <Box display="flex" mt={4} pl={{ xs: 2, lg: 0 }}>
                  <Box display="flex" alignItems="center">
                    <Box
                      position="relative"
                      width={{ xs: 80, tl: 100 }}
                      height={{ xs: 80, tl: 100 }}
                    >
                      <NumberMark number={2} />
                      <Link to={`/member/${sortedMembers[1].username}`}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            padding: '1px',
                            background: '#313131',
                          }}
                        >
                          <S3Avatar src={sortedMembers[1].image} />
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      ml: 4,
                      mr: { xs: 0, tl: 9 },
                    }}
                  >
                    <Box>
                      <Link to={`/member/${sortedMembers[1].username}`}>
                        <Heading4 sx={{ wordBreak: 'break-all' }}>{sortedMembers[1].username}</Heading4>
                      </Link>
                      <Typography mt={1} fontSize="16px">{sortedMembers[1].name}</Typography>
                    </Box>
                    <Heading3 sx={{ mt: 1 }}>{formatCompactNumber(sortedMembers[1].raid_points)}</Heading3>
                  </Box>
                </Box>}
                {!!sortedMembers[2] && <Box display="flex" mt={4} pl={{ xs: 2, lg: 0 }}>
                  <Box display="flex" alignItems="center">
                    <Box
                      position="relative"
                      width={{ xs: 80, tl: 100 }}
                      height={{ xs: 80, tl: 100 }}
                    >
                      <NumberMark number={3} />
                      <Link to={`/member/${sortedMembers[2].username}`}>
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            padding: '1px',
                            background: '#313131',
                          }}
                        >
                          <S3Avatar src={sortedMembers[2].image} />
                        </Box>
                      </Link>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      ml: 4,
                      mr: { xs: 0, tl: 9 },
                    }}
                  >
                    <Box>
                      <Link to={`/member/${sortedMembers[2].username}`}>
                        <Heading4 sx={{ wordBreak: 'break-all' }}>{sortedMembers[2].username}</Heading4>
                      </Link>
                      <Typography mt={1} fontSize="16px">{sortedMembers[2].name}</Typography>
                    </Box>
                    <Heading3 sx={{ mt: 1 }}>{formatCompactNumber(sortedMembers[2].raid_points)}</Heading3>
                  </Box>
                </Box>}
              </Box>
            </Box>
          </Box>}
        </Box>
      </Container>}
    </Box>
  );
};

export default TeamProfileSeasonInfo;
