import { Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading1 } from '../../../components/Heading';

const Banner = () => {
  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading1>Conquer Challenges, Earn Rewards, AND Crush YOUR Goals</Heading1>
      <Typography sx={{
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 400,
        marginTop: 7,
      }}>
        Black Box VR’s workouts never get dull! Seasons help turn intense exercise into a constantly evolving, fun, and challenging experience that keeps you engaged and motivated on your journey to life-changing health and fitness.
      </Typography>
    </Container>
  );
};

export default Banner;
