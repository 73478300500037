import { Box } from '@mui/material';
import { Heading4, Heading5 } from '../Heading';

const NumberMark = ({ number }) => {
  let background = '';

  if (number === 1) {
    background = 'linear-gradient(180deg, #925F01 0%, #C3A128 30%, #FEF89D 40%, #F4CE02 80%)';
  } else if (number === 2) {
    background = 'linear-gradient(180deg, #777777 0%, #C5C5C5 30%, #F0F0F0 40%, #767676 80%)';
  } else if (number === 3) {
    background =
      'linear-gradient(180deg, #5B3619 0%, #90633A 30%, #F3D1A8 50%, #AD763D 70%, #7D4313 80%)';
  } else {
    background = '#fff';
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: number > 1 ? '-16px' : { xs: '-16px', lg: '-24px' },
        left: number > 1 ? '-16px' : { xs: '-16px', lg: '-24px' },
        background: background,
        width: number > 1 ? 32 : { xs: 40, tl: 48 },
        height: number > 1 ? 32 : { xs: 40, tl: 48 },
        borderRadius: '50%',
        padding: '2px',
        zIndex: 2,
      }}
    >
      <Box
        sx={{
          background: '#141516',
          width: number > 1 ? 32 : { xs: 40, tl: 48 },
          height: number > 1 ? 32 : { xs: 40, tl: 48 },
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {number === 1 ? <Heading4>#{number}</Heading4> : <Heading5>#{number}</Heading5>}
      </Box>
    </Box>
  );
};

export default NumberMark;
