import { Box, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading2 } from '../../../components/Heading';
import LocalImage from '../../../components/LocalImage';

const GetRewarded = () => {
  return (
    <Container sx={{ marginTop: 6 }}>
      <Heading2>GET REWARDED FOR HELPING OTHERS GET FIT!</Heading2>
      <Box sx={{ marginTop: 3 }}>
        <Box sx={{
          float: {
            xs: "none",
            tl: "right",
          },
          marginLeft: {
            xs: 0,
            tl: 4.5,
          },
          marginBottom: 1
        }}>
          <LocalImage src="img/unity-gems/get-rewarded.webp" alt="Get Rewarded" loading="lazy" width="100%" />
        </Box>
        <Box>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            fontWeight: 400,
          }}>
            <span style={{ fontWeight: 800 }}>At Black Box VR</span>, we believe that fitness is more than just a solo journey. It’s about community, support, and shared success. That’s why we’ve designed a system that not only makes working out enjoyable but also connects you with friends and fellow members for a truly transformative experience.
          </Typography>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            fontWeight: 800,
            marginTop: 3,
          }}>
            Unity Gems: Your Key to Exciting Rewards!
          </Typography>
          <ul>
            <li style={{ marginLeft: "24px", marginTop: "20px" }}>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: 400,
              }}>
                <span style={{ fontWeight: 800 }}>Earn VIP Invites</span>: Earn up to three invite codes per Season on the Season XP reward ladder. Be sure to share them quickly… they expire after the Season ends!
              </Typography>
            </li>
            <li style={{ marginLeft: "24px", marginTop: "20px" }}>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: 400,
              }}>
                <span style={{ fontWeight: 800 }}>Invite and Grow</span>: Bring friends using your Insider Invitation codes and you’ll earn Unity Gems as they hit game and fitness milestones. We win together!
              </Typography>
            </li>
            <li style={{ marginLeft: "24px", marginTop: "20px" }}>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: 400,
              }}>
                <span style={{ fontWeight: 800 }}>Celebrate Milestones</span>: From completing onboarding workouts to hitting XP targets, every achievement brings Gem rewards. Your continued dedication to helping your friend succeed over the long-term makes a difference!
              </Typography>
            </li>
            <li style={{ marginLeft: "24px", marginTop: "20px" }}>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: 400,
              }}>
                <span style={{ fontWeight: 800 }}>Spend Gems Wisely</span>: Use Gems for Streak Passes, game upgrades, fun swag, useful merchandise, and exclusive gym perks. Check the Store section of the app!
              </Typography>
            </li>
            <li style={{ marginLeft: "24px", marginTop: "20px" }}>
              <Typography sx={{
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: 400,
              }}>
                <span style={{ fontWeight: 800 }}>Boost Your Progress</span>: Unlock special content, gain resource multipliers, and access unique  perks for your team.
              </Typography>
            </li>
          </ul>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            fontWeight: 800,
            marginTop: 3,
            marginBottom: 3,
          }}>
            Together, We’re Stronger!
          </Typography>
          <Typography sx={{
            fontSize: "16px",
            lineHeight: "26px",
            fontWeight: 400,
          }}>
            Let’s create an experience where every gamified workout brings you and those you love closer to reaching important health and fitness goals.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default GetRewarded;
