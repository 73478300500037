import { Box, IconButton, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from 'react';

import { Heading2, Heading3 } from '../Heading';

import QuestionIcon from '../../assets/img/stats/question-icon.png';
import StarIcon from '../../assets/img/week/star.webp';
import { HtmlTooltip, TooltipContent } from '../Tooltip';

const SummaryCell = ({
  label,
  value,
  description,
  isQuestion,
  textAlign = 'center',
  tooltipPosition = 'bottom',
  toolipSize,
  noMargin = false,
  isLarge,
  isStar,
  sx,
}) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={sx}>
        <Typography
          fontSize={14}
          mt={noMargin ? 0 : -1}
          mb={noMargin ? 0 : 1}
          sx={{
            height: "32px",
            display: 'flex',
            justifyContent: textAlign,
            alignItems: 'center',
            '& img': { width: 16, height: 16 },
          }}
        >
          {label}{' '}
          {isQuestion && (
            <HtmlTooltip
              title={<TooltipContent title={value} desc={description} />}
              placement={tooltipPosition}
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
              toolipSize={toolipSize}
              noSubject={!value}
              onClose={handleTooltipClose}
              open={open}
            >
              <IconButton onClick={handleTooltipOpen} sx={{ color: '#ffffff' }}>
                <img src={QuestionIcon} alt={label} />
              </IconButton>
            </HtmlTooltip>
          )}
        </Typography>
        <Box sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          {!!isStar && <img
            src={StarIcon}
            alt="Star"
            width="24px"
            height="24px"
            style={{ marginRight: "8px" }}
          />}
          {!!value && !isLarge && <Heading3 sx={{ textAlign: 'center' }}>{value}</Heading3>}
          {!!value && !!isLarge && <Heading2 sx={{ textAlign: 'center', color: isStar ? "#22C55E" : "#FFFFFF" }}>{value}</Heading2>}
          {!!isStar && <img
            src={StarIcon}
            alt="Star"
            width="24px"
            height="24px"
            style={{ marginLeft: "8px" }}
          />}
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default SummaryCell;
