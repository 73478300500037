import { Box, Divider, Grid, Typography } from '@mui/material';

import { capitalizeFirstLetter, formatCompactNumber } from '../../../../utils';

import { Heading, Heading1, Heading3 } from '../../../../components/Heading';
import Container from '../../../../components/Layout/Container';
import CustomBreadcrumbs from '../../../../components/Breadcrumbs';
import AvatarImage from './AvatarImage';
import FollowButton from './FollowButton';
import MemberFollows from './MemberFollows';

const AvatarInfo = ({ data, gymInfo, isPublicProfile, inviteState, breadcrumbs }) => {
  const { username, userId, xpTitle, details } = data;

  return (
    <Container>
      <Box sx={{ paddingTop: 4 }}>
        <CustomBreadcrumbs items={breadcrumbs} />
      </Box>
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: { xs: 'column', tl: 'row' },
          alignItems: { xs: 'center', tl: 'center' },
          top: { xs: 0, tl: 7 },
        }}
      >
        <AvatarImage data={data} />
        <Box
          pt={{ xs: 2, tl: 4 }}
          ml={{ xs: 0, tl: 6 }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign={{ xs: 'center', tl: 'start' }}
        >
          {!!userId && (
            <Box display={{ xs: 'none', tl: 'block' }} mb={{ xs: 2, md: 3 }}>
              <FollowButton userId={userId} inviteState={inviteState} />
            </Box>
          )}
          <Heading1>{username}</Heading1>
          <Box mt={1}>
            <Heading variant="span" size={24} sx={{ fontWeight: 100, mr: 1 }}>
              XP TITLE:
            </Heading>
            <Heading variant="span" size={24} sx={{ fontWeight: 700, color: '#FF00FF' }}>
              {xpTitle}
            </Heading>
          </Box>
          <Divider sx={{ borderColor: '#FF00FF', mt: 2 }} />
          {isPublicProfile && (
            <Grid
              container
              spacing={4}
              mt={{ xs: 1, tl: 2 }}
              justifyContent={{ xs: 'center', tl: 'flex-start' }}
              flexWrap="nowrap"
            >
              <Grid item>
                <Heading3>{formatCompactNumber(details?.total_volume)}</Heading3>
                <Typography sx={{ fontSize: '14px', mt: 0.5 }}>Lbs. Lifted</Typography>
              </Grid>
              <Grid item>
                <Heading3>{formatCompactNumber(details?.total_work)}</Heading3>
                <Typography sx={{ fontSize: '14px', mt: 0.5 }}>Ft.*Lbs. Work</Typography>
              </Grid>
              <Grid item>
                <Heading3>{details?.workout_count || 0}</Heading3>
                <Typography sx={{ fontSize: '14px', mt: 0.5 }}>Workouts</Typography>
              </Grid>
            </Grid>
          )}
          {!!userId && <MemberFollows gymInfo={gymInfo} userId={userId} />}
          {!isPublicProfile && (
            <Typography mt={3}>
              * {capitalizeFirstLetter(username)} has chosen to hide their public profile.
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default AvatarInfo;
