import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';

import Container from '../../../components/Layout/Container';
import { Heading2, Heading4 } from '../../../components/Heading';
import Loading from '../../../components/Loading';
import { callBackbonePostApi } from '../../../services/backboneApiService';

const RewardMilestone = () => {
  const [ gemAwardMilestones, setGemAwardMilestones ] = useState(null);

  const [ isLoading, setIsLoading ] = useState(true);

  useEffect(() => {
    callBackbonePostApi({
      action: "getCurrentGemAwardMilestones",
    }, '')
    .then((data) => {
      if (data?.status === 200 && data?.data) {
        setGemAwardMilestones(data?.data);
      }
      setIsLoading(false);
    })
    .catch(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Container sx={{ marginTop: 5 }}>
      <Heading2>REWARD MILESTONES</Heading2>
      <Typography sx={{
        fontSize: "16px",
        lineHeight: "26px",
        fontWeight: 400,
        marginTop: 3,
      }}>
        As your invited friend achieves important milestones in the game, you’ll be rewarded for your care and support!
      </Typography>
      <Box sx={{ marginTop: 3 }}>
        {!!isLoading && <Loading />}
        {!isLoading && !!gemAwardMilestones && <Box>
          <Box sx={{ marginBottom: 3 }}>
            <Heading4>ONBOARDING</Heading4>
            <ul style={{ marginTop: "24px" }}>
              {(gemAwardMilestones?.Onboarding || []).map(item =>
                <li
                  key={item.name}
                  style={{
                    marginLeft: "25px",
                  }}
                >
                  <Typography sx={{
                    fontSize: "16px",
                    lineHeight: "26px",
                    fontWeight: 400,
                  }}>
                    {item.name} - {item.reward}
                  </Typography>
                </li>
              )}
            </ul>
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <Heading4>LIFETIME XP</Heading4>
            <ul style={{ marginTop: "24px" }}>
              {(gemAwardMilestones?.LifetimeXP || []).map(item =>
                <li
                  key={item.name}
                  style={{
                    marginLeft: "25px",
                  }}
                >
                  <Typography sx={{
                    fontSize: "16px",
                    lineHeight: "26px",
                    fontWeight: 400,
                  }}>
                    {item.name} - {item.reward}
                  </Typography>
                </li>
              )}
            </ul>
          </Box>
          <Box sx={{ marginBottom: 3 }}>
            <Heading4>RECURRING</Heading4>
            <ul style={{ marginTop: "24px" }}>
              {(gemAwardMilestones?.Recurring || []).map(item =>
                <li
                  key={item.name}
                  style={{
                    marginLeft: "25px",
                  }}
                >
                  <Typography sx={{
                    fontSize: "16px",
                    lineHeight: "26px",
                    fontWeight: 400,
                  }}>
                    {item.name} - {item.reward}
                  </Typography>
                </li>
              )}
            </ul>
          </Box>
        </Box>}
      </Box>
    </Container>
  );
};

export default RewardMilestone;
