import * as React from 'react';
import {
  Box,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { formatCompactNumber, formatLocalizedDate, formatNumber } from '../../../utils';

import { Heading2, Heading3, Heading4 } from '../../../components/Heading';

const periods = [
  "All-Time",
  "Last 30 Days",
  "Last 90 Days",
  "Last 365 Days",
];

const getOption = (dates, values, name, color1, color2) => ({
  grid: {
    height: 250,
    left: 0,
    top: 37,
    right: '60px',
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      label: {
        formatter: function (params) {
          return formatLocalizedDate(params.value, 'YYYY-MM-DD');
        },
      },
    },
  },
  xAxis: {
    type: 'category',
    data: dates,
    show: false,
  },
  yAxis: {
    type: 'value',
    position: 'right',
    axisLabel: {
      fontWeight: 400,
      fontSize: 16,
      color: '#fff',
      formatter: function (value) {
        return formatCompactNumber(value);
      },
    },
  },
  series: [
    {
      name: name,
      data: values,
      type: 'bar',
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: color1 },
          { offset: 1, color: color2 },
        ]),
        borderRadius: 5,
      },
    },
  ],
  responsive: true,
  maintainAspectRatio: false
});

const WorkoutCharts = ({
  endDate,
  averageVolume,
  averageWork,
  averageSets,
  averageReps,
  averageWorkout,
  volumeOverTime,
  workOverTime,
  repsPerWeek,
  setsPerWeek,
  workoutPerWeek,
}) => {
  const [anchorEl, setAnchorEl] = React.useState({});
  const open = {
    volume: Boolean(anchorEl.volume),
    work: Boolean(anchorEl.work),
    workout: Boolean(anchorEl.workout),
    sets: Boolean(anchorEl.sets),
    reps: Boolean(anchorEl.reps),
  };
  const [selectedPeriod, setSelectedPeriod] = React.useState({
    volume: periods[0],
    work: periods[0],
    workout: periods[0],
    sets: periods[0],
    reps: periods[0],
  });
  const allVDates = Object.keys(volumeOverTime).sort();
  const allWDates = Object.keys(workOverTime).sort();
  const allRDates = Object.keys(repsPerWeek).sort();
  const allSDates = Object.keys(setsPerWeek).sort();
  const allUDates = Object.keys(workoutPerWeek).sort();
  let vDates = [];
  let wDates = [];
  let rDates = [];
  let sDates = [];
  let uDates = [];
  const thirtyDays = new Date(new Date(endDate).getTime() - 30 * 24 * 60 * 60 * 1000);
  const ninetyDays = new Date(new Date(endDate).getTime() - 90 * 24 * 60 * 60 * 1000);
  const oneYearDays = new Date(new Date(endDate).getTime() - 365 * 24 * 60 * 60 * 1000);

  switch (selectedPeriod.work) {
    case periods[0]:
      wDates = allWDates.filter(dt => dt <= endDate);
      break;
    case periods[1]:
      wDates = allWDates.filter(dt => dt <= endDate && new Date(dt) >= thirtyDays);
      break;
    case periods[2]:
      wDates = allWDates.filter(dt => dt <= endDate && new Date(dt) >= ninetyDays);
      break;
    case periods[3]:
      wDates = allWDates.filter(dt => dt <= endDate && new Date(dt) >= oneYearDays);
      break;
    default:
  }

  switch (selectedPeriod.workout) {
    case periods[0]:
      uDates = allUDates.filter(dt => dt <= endDate);
      break;
    case periods[1]:
      uDates = allUDates.filter(dt => dt <= endDate && new Date(dt) >= thirtyDays);
      break;
    case periods[2]:
      uDates = allUDates.filter(dt => dt <= endDate && new Date(dt) >= ninetyDays);
      break;
    case periods[3]:
      uDates = allUDates.filter(dt => dt <= endDate && new Date(dt) >= oneYearDays);
      break;
    default:
  }

  switch (selectedPeriod.sets) {
    case periods[0]:
      sDates = allSDates.filter(dt => dt <= endDate);
      break;
    case periods[1]:
      sDates = allSDates.filter(dt => dt <= endDate && new Date(dt) >= thirtyDays);
      break;
    case periods[2]:
      sDates = allSDates.filter(dt => dt <= endDate && new Date(dt) >= ninetyDays);
      break;
    case periods[3]:
      sDates = allSDates.filter(dt => dt <= endDate && new Date(dt) >= oneYearDays);
      break;
    default:
  }

  switch (selectedPeriod.reps) {
    case periods[0]:
      rDates = allRDates.filter(dt => dt <= endDate);
      break;
    case periods[1]:
      rDates = allRDates.filter(dt => dt <= endDate && new Date(dt) >= thirtyDays);
      break;
    case periods[2]:
      rDates = allRDates.filter(dt => dt <= endDate && new Date(dt) >= ninetyDays);
      break;
    case periods[3]:
      rDates = allRDates.filter(dt => dt <= endDate && new Date(dt) >= oneYearDays);
      break;
    default:
  }

  switch (selectedPeriod.volume) {
    case periods[0]:
      vDates = allVDates.filter(dt => dt <= endDate);
      break;
    case periods[1]:
      vDates = allVDates.filter(dt => dt <= endDate && new Date(dt) >= thirtyDays);
      break;
    case periods[2]:
      vDates = allVDates.filter(dt => dt <= endDate && new Date(dt) >= ninetyDays);
      break;
    case periods[3]:
      vDates = allVDates.filter(dt => dt <= endDate && new Date(dt) >= oneYearDays);
      break;
    default:
  }

  const vValues = vDates.map((date) => volumeOverTime[date]);
  const wValues = wDates.map((date) => workOverTime[date]);
  const rValues = rDates.map((date) => repsPerWeek[date]);
  const sValues = sDates.map((date) => setsPerWeek[date]);
  const uValues = uDates.map((date) => workoutPerWeek[date]);

  const handleOpenMenu = (event, key) => {
    setAnchorEl({
      ...anchorEl,
      [key]: event.currentTarget,
    });
  };

  const handleClose = (key) => {
    setAnchorEl({
      ...anchorEl,
      [key]: null,
    });
  };

  const handleSelect = (newPeriod, key) => {
    setSelectedPeriod({
      ...selectedPeriod,
      [key]: newPeriod,
    });
    setAnchorEl({
      ...anchorEl,
      [key]: null,
    });
  };

  return (
    <Box sx={{ marginTop: 8 }}>
      <Heading2 sx={{ marginBottom: 5 }}>WEEKLY WORKOUT CHARTS</Heading2>
      <Box
        mt={1}
        mx={{ xs: 2, tl: 0 }}
      >
        <Box flex={1} mr={{ md: 1 }}>
          <Box sx={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Heading3 sx={{ '& br': { display: { xs: 'none', md: 'block' } } }}>
              Workouts Per Week
            </Heading3>
            <Box>
              <Box
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "rgb(238, 238, 238)",
                  color: "rgb(33, 43, 54)",
                  height: "36px",
                  position: "relative",
                  paddingLeft: "10px",
                  paddingRight: "30px",
                }}
                mr={1}
                onClick={(event) => handleOpenMenu(event, "workout")}
              >
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={14}
                  lineHeight="36px"
                  display="flex"
                  alignItems="center"
                >
                  {selectedPeriod.workout}
                  <CalendarTodayIcon sx={{ marginLeft: 1, fontSize: 14 }} />
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "6px",
                  }}
                >
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>
              <Menu
                anchorEl={anchorEl.workout}
                open={open.workout}
                onClose={() => handleClose("workout")}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {},
                }}
              >
                {periods.map((option) => (
                  <MenuItem key={option} selected={option === selectedPeriod.workout} onClick={() => handleSelect(option, "workout")}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              mt: 4,
            }}
          >
            <Box>
              <Typography>Avg Sets</Typography>
              <Heading4>{formatNumber(Math.round(averageWorkout))}</Heading4>
              <Typography>Per Week</Typography>
            </Box>
          </Box>
          <ReactEcharts option={getOption(uDates, uValues, "Workouts", "#22C55E", "#6EE399")} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 7 }}>
            <Typography>
              {uDates.length > 0 ? formatLocalizedDate(uDates[0], 'MMM YYYY') : ''}
            </Typography>
            <Typography>
              {uDates.length > 0 ? formatLocalizedDate(uDates[uDates.length - 1], 'MMM YYYY') : ''}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        flexWrap="wrap"
        mt={3}
        mx={{ xs: 2, tl: 0 }}
      >
        <Box flex={1} mr={{ md: 1 }}>
          <Box sx={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Heading3 sx={{ '& br': { display: { xs: 'none', md: 'block' } } }}>
              Sets Per Week
            </Heading3>
            <Box>
              <Box
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "rgb(238, 238, 238)",
                  color: "rgb(33, 43, 54)",
                  height: "36px",
                  position: "relative",
                  paddingLeft: "10px",
                  paddingRight: "30px",
                }}
                mr={1}
                onClick={(event) => handleOpenMenu(event, "sets")}
              >
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={14}
                  lineHeight="36px"
                  display="flex"
                  alignItems="center"
                >
                  {selectedPeriod.sets}
                  <CalendarTodayIcon sx={{ marginLeft: 1, fontSize: 14 }} />
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "6px",
                  }}
                >
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>
              <Menu
                anchorEl={anchorEl.sets}
                open={open.sets}
                onClose={() => handleClose("sets")}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {},
                }}
              >
                {periods.map((option) => (
                  <MenuItem key={option} selected={option === selectedPeriod.sets} onClick={() => handleSelect(option, "sets")}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              mt: 4,
            }}
          >
            <Box>
              <Typography>Avg Sets</Typography>
              <Heading4>{formatNumber(Math.round(averageSets))}</Heading4>
              <Typography>Per Week</Typography>
            </Box>
          </Box>
          <ReactEcharts option={getOption(sDates, sValues, "Sets", "#9E00FF", "#FFFFFF")} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 7 }}>
            <Typography>
              {sDates.length > 0 ? formatLocalizedDate(sDates[0], 'MMM YYYY') : ''}
            </Typography>
            <Typography>
              {sDates.length > 0 ? formatLocalizedDate(sDates[sDates.length - 1], 'MMM YYYY') : ''}
            </Typography>
          </Box>
        </Box>
        <Box flex={1} ml={{ md: 1 }} mt={{ xs: 6, md: 0 }}>
          <Box sx={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Heading3 sx={{ '& br': { display: { xs: 'none', md: 'block' } } }}>
              Reps Per Week
            </Heading3>
            <Box>
              <Box
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "rgb(238, 238, 238)",
                  color: "rgb(33, 43, 54)",
                  height: "36px",
                  position: "relative",
                  paddingLeft: "10px",
                  paddingRight: "30px",
                }}
                mr={1}
                onClick={(event) => handleOpenMenu(event, "reps")}
              >
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={14}
                  lineHeight="36px"
                  display="flex"
                  alignItems="center"
                >
                  {selectedPeriod.reps}
                  <CalendarTodayIcon sx={{ marginLeft: 1, fontSize: 14 }} />
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "6px",
                  }}
                >
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>
              <Menu
                anchorEl={anchorEl.reps}
                open={open.reps}
                onClose={() => handleClose("reps")}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {},
                }}
              >
                {periods.map((option) => (
                  <MenuItem key={option} selected={option === selectedPeriod.reps} onClick={() => handleSelect(option, "reps")}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              mt: 4,
            }}
          >
            <Box>
              <Typography>Avg Reps</Typography>
              <Heading4>{formatNumber(Math.round(averageReps))}</Heading4>
              <Typography>Per Week</Typography>
            </Box>
          </Box>
          <ReactEcharts option={getOption(rDates, rValues, "Reps", "#169DFF", "#FFFFFF")} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 7 }}>
            <Typography>
              {rDates.length > 0 ? formatLocalizedDate(rDates[0], 'MMM YYYY') : ''}
            </Typography>
            <Typography>
              {rDates.length > 0 ? formatLocalizedDate(rDates[rDates.length - 1], 'MMM YYYY') : ''}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        flexWrap="wrap"
        mt={3}
        mx={{ xs: 2, tl: 0 }}
      >
        <Box flex={1} mr={{ md: 1 }}>
          <Box sx={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Heading3 sx={{ '& br': { display: { xs: 'none', md: 'block' } } }}>
              Volume Per Week
            </Heading3>
            <Box>
              <Box
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "rgb(238, 238, 238)",
                  color: "rgb(33, 43, 54)",
                  height: "36px",
                  position: "relative",
                  paddingLeft: "10px",
                  paddingRight: "30px",
                }}
                mr={1}
                onClick={(event) => handleOpenMenu(event, "volume")}
              >
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={14}
                  lineHeight="36px"
                  display="flex"
                  alignItems="center"
                >
                  {selectedPeriod.volume}
                  <CalendarTodayIcon sx={{ marginLeft: 1, fontSize: 14 }} />
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "6px",
                  }}
                >
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>
              <Menu
                anchorEl={anchorEl.volume}
                open={open.volume}
                onClose={() => handleClose("volume")}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {},
                }}
              >
                {periods.map((option) => (
                  <MenuItem key={option} selected={option === selectedPeriod.volume} onClick={() => handleSelect(option, "volume")}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              mt: 4,
            }}
          >
            <Box>
              <Typography>Avg Volume</Typography>
              <Heading4>{formatNumber(Math.round(averageVolume))} LBS</Heading4>
              <Typography>Per Week</Typography>
            </Box>
            <Box textAlign="right">
              <Typography>Lbs. of Volume</Typography>
              <Typography>(Sets x Reps)</Typography>
            </Box>
          </Box>
          <ReactEcharts option={getOption(vDates, vValues, "Volume", "#FF00FF", "#FF2D56")} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 7 }}>
            <Typography>
              {vDates.length > 0 ? formatLocalizedDate(vDates[0], 'MMM YYYY') : ''}
            </Typography>
            <Typography>
              {vDates.length > 0 ? formatLocalizedDate(vDates[vDates.length - 1], 'MMM YYYY') : ''}
            </Typography>
          </Box>
        </Box>
        <Box flex={1} ml={{ md: 1 }} mt={{ xs: 6, md: 0 }}>
          <Box sx={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <Heading3 sx={{ '& br': { display: { xs: 'none', md: 'block' } } }}>
              Work Done Per Week
            </Heading3>
            <Box>
              <Box
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "rgb(238, 238, 238)",
                  color: "rgb(33, 43, 54)",
                  height: "36px",
                  position: "relative",
                  paddingLeft: "10px",
                  paddingRight: "30px",
                }}
                mr={1}
                onClick={(event) => handleOpenMenu(event, "work")}
              >
                <Typography
                  sx={{ cursor: "pointer" }}
                  fontSize={14}
                  lineHeight="36px"
                  display="flex"
                  alignItems="center"
                >
                  {selectedPeriod.work}
                  <CalendarTodayIcon sx={{ marginLeft: 1, fontSize: 14 }} />
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "6px",
                  }}
                >
                  <KeyboardArrowDownIcon />
                </Box>
              </Box>
              <Menu
                anchorEl={anchorEl.work}
                open={open.work}
                onClose={() => handleClose("work")}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                PaperProps={{
                  style: {},
                }}
              >
                {periods.map((option) => (
                  <MenuItem key={option} selected={option === selectedPeriod.work} onClick={() => handleSelect(option, "work")}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              mt: 4,
            }}
          >
            <Box>
              <Typography>Avg Work</Typography>
              <Heading4>{formatNumber(Math.round(averageWork))} FT*LBS</Heading4>
              <Typography>Per Week</Typography>
            </Box>
            <Box textAlign="right">
              <Typography>Ft.*Lbs. of Work</Typography>
            </Box>
          </Box>
          <ReactEcharts option={getOption(wDates, wValues, "Work", "#169DFF", "#FF00FF")} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 7 }}>
            <Typography>
              {wDates.length > 0 ? formatLocalizedDate(wDates[0], 'MMM YYYY') : ''}
            </Typography>
            <Typography>
              {wDates.length > 0 ? formatLocalizedDate(wDates[wDates.length - 1], 'MMM YYYY') : ''}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkoutCharts;
