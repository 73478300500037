import axios from 'axios';
import useSWR from 'swr';

import useApiResponse from '../hooks/useApiResponse';

const wpApi = axios.create({
  baseURL: process.env.REACT_APP_WP_API_URL,
});

wpApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error.response);

    return Promise.reject(error.response || 'Something went wrong');
  }
);

export default wpApi;

export function getCacheKey(url, params) {
  const cacheKey = [url];
  if (params) {
    cacheKey.push(JSON.stringify(params));
  }

  return cacheKey;
}

export function useWpApi(url, params, config) {
  const { data, mutate, error } = useSWR(url ? getCacheKey(url, params) : null, async () => {
    const { data } = await wpApi.get(url, { params, ...config });
    return { data, isLoading: false };
  });

  const cachedResponse = useApiResponse(data);

  return {
    data: cachedResponse || { isLoading: url ? true : false },
    mutate,
    error,
  };
}
