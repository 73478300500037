import { Box, Typography } from "@mui/material";
import Container from "../../../../components/Layout/Container";
import { Heading1 } from "../../../../components/Heading";
import ResultDiagram from "./ResultDiagram";
import { useEffect, useState } from "react";
import axios from "axios";

const SeasonReward = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const init = async () => {
      await axios.post('https://elyjpeuz80.execute-api.us-west-2.amazonaws.com/prod/Backbone', {
        "action": "getGymRewardInfo",
        "gymId": 2
      }, {
        headers: {
          'x-api-key': 'X2W1pNLc2V4C9rziMe9Mb6jWOhZgGL7T7IFSaAd2'
        }
      }).then((response) => {
        const promise = response.data.map(async value => {
          if (value.period === "currentSeason") {
            setData(value);
          }
        });
        Promise.all(promise);
      })
    }

    init();
  }, []);

  return (
    <Container>
      <Box mt={{ xs: 8, tl: 10, lg: 16 }} mx={{ xs: 2, tl: 0 }}>
        <Heading1 sx={{ mx: 0.5 }}>Full Gym Season Reward!</Heading1>
        <Box mt={4} display="flex" flexDirection="column" gap={4}>
          <Typography fontSize={24}>
            Get ready to level up your experience at Black Box VR! Every season, we offer an exciting opportunity for our gym tribe to come together and earn a fantastic reward. By collectively accumulating enough Raid Points, you can help unlock this upgrade for our gym environment.
          </Typography>
          <Typography fontSize={24}>
            If we all contribute, we will get into the best shape of our lives and win this awesome upgrade that benefits the whole gym community!
          </Typography>
        </Box>
        <ResultDiagram data={data} width={75} height={552}/>
      </Box>
    </Container >
  );
};

export default SeasonReward;
