import { Box } from '@mui/material';

const BadgeButton = ({ bg, children }) => {
  return (
    <Box
      sx={{
        background: `url(${bg})`,
        backgroundSize: '100%',
        width: { xs: 96, tl: 80, lg: 115 },
        height: { xs: 96, tl: 80, lg: 115 },
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};

export default BadgeButton;
