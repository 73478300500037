import { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import Container from '../../../components/Layout/Container';
import {
  Heading1,
  Heading3,
  Heading4,
} from '../../../components/Heading';
import Loading from '../../../components/Loading';
import { HtmlTooltip, TooltipContent } from '../../../components/Tooltip';
import PlaceholderImg from '../../../assets/img/placeholder-img.webp';

const RewardItem = ({
  item,
}) => {
  const [ open, setOpen ] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (<ClickAwayListener onClickAway={handleTooltipClose}>
    <Box>
      <HtmlTooltip
        title={<TooltipContent title={item.name} desc={item.description} />}
        arrow
        disableFocusListener
        disableHoverListener
        disableTouchListener
        noSubject={!item.name}
        onClose={handleTooltipClose}
        open={!!open}
      >
        <img
          src={item.imageUrl || PlaceholderImg}
          width="100%"
          alt="Season Rewards Item"
          style={{ cursor: "pointer "}}
          onClick={() => handleTooltipOpen()}
        />
      </HtmlTooltip>
      <Heading4
        sx={{
          marginTop: 3,
          marginBottom: 1,
        }}
      >
        {item.name}
      </Heading4>

      </Box>
    </ClickAwayListener>
  );
};

const Rewards = ({
  data,
  isLoading,
}) => {
  return (
    <Container sx={{ marginTop: 9 }}>
      <Heading1>Season Rewards</Heading1>
      <Typography sx={{
        fontSize: "24px",
        lineHeight: "38px",
        fontWeight: 400,
        marginTop: 3,
        marginBottom: 4,
      }}>
        Get exclusive real-life and digital rewards for completing your weekly workouts, completing challenges as a team, and smashing your fitness goals! 
      </Typography>
      <Box sx={{ marginTop: 3 }}>
        {isLoading && <Loading />}
        {!isLoading && <Box>
          <Heading3 sx={{ marginTop: 5, marginBottom: 4 }}>INDIVIDUAL REWARDS</Heading3>
          <Grid container spacing={3}>
            {(data?.individualRewards?.seasonXPRewards || []).filter(item => !!item).map((item, index) => (
              <Grid item key={item.name} md={3} xs={12}>
                <RewardItem item={item} />
              </Grid>
            ))}
          </Grid>
        </Box>}
      </Box>
      <Box sx={{ marginTop: 3 }}>
        {isLoading && <Loading />}
        {!isLoading && <Box>
          <Heading3 sx={{ marginTop: 5, marginBottom: 4 }}>TEAM REWARDS</Heading3>
          <Grid container spacing={3}>
            {(data?.teamRewards?.seasonXPRewards || []).filter(item => !!item).map((item, index) => (
              <Grid item key={item.name} md={3} xs={12}>
                <RewardItem item={item} />
              </Grid>
            ))}
          </Grid>
        </Box>}
      </Box>
    </Container>
  );
};

export default Rewards;
