import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import MetaTags from '../../components/MetaTags';
import Loading from '../../components/Loading';
import Footer from '../../components/Footer/FooterMenu';
import { CustomButton } from '../../components/Buttons';
import DetailsSection from '../SeasonRecap/Details';
import VideoItem from '../VideoList/Video';

import { useUserDetails } from '../../services/communityService';
import { callBackbonePostApi } from '../../services/backboneApiService';

const Workout = () => {
  const { username, sessionId } = useParams();
  const {
    data: { data: userDetails, isLoading: isLoadingUserDetails },
  } = useUserDetails(username, null);
  const [isRecapLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  const isLoading = !userDetails || isLoadingUserDetails || isRecapLoading;
  const profileAggregates = userDetails?.results?.profile_aggregates;
  const userProfileInfo = userDetails?.results?.user_profile_info
    ? userDetails.results.user_profile_info[0]
    : {};

  useEffect(() => {
    if (!!sessionId) {
      callBackbonePostApi({
        action: "getSessionSummaryById",
        sessionId: sessionId
      }, '')
        .then((data) => {
          setIsLoading(false);

          if (data?.status === 200 && data?.data) {
            setData(data?.data);
          }
        })
        .catch(() => {
          setIsLoading(true);
        });
    }
  }, [sessionId]);

  const userId = userDetails?.results?.user_id;
  const isPublicProfile = userProfileInfo.publicProfileAllowed;
  const gymInfo = { id: userProfileInfo.gym_id, name: userProfileInfo.gym_name };
  const userImage = userProfileInfo.profile_pic_url;
  const xpTitle = userDetails?.results?.xp_title ?? '';
  const inviteState = userDetails?.results?.invite_button_state;
  const currentLeague = parseInt(userProfileInfo.league?.N ?? 0);
  const displayTrophyCount = userProfileInfo.trophies;
  const achievements = userDetails?.results?.recent_achievements;

  const breadcrumbs = [
    { title: 'Home', link: 'https://www.blackbox-vr.com' },
    { title: 'Community', link: '/' },
    { title: 'Meridian Gym', link: '/gym/2/Meridian' },
    { title: 'Members', link: '/' },
    { title: username.charAt(0).toUpperCase() + username.slice(1).toLowerCase(), link: `/member/${username}` },
    { title: 'Game Videos' },
  ];

  return (
    <Box>
      <MetaTags
        title={`Black Box VR - ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}'s Videos`}
        description={`Black Box VR - ${username.charAt(0).toUpperCase() + username.slice(1).toLowerCase()}'s Videos`}
      />
      {isLoading ? (
        <Loading fullscreen />
      ) : (
        <>
          <DetailsSection
            username={username}
            userImage={userImage}
            userId={userId}
            xpTitle={xpTitle}
            currentLeague={currentLeague}
            displayTrophyCount={displayTrophyCount}
            details={profileAggregates}
            achievements={achievements}
            gymInfo={gymInfo}
            isPublicProfile={isPublicProfile}
            inviteState={inviteState}
            breadcrumbs={breadcrumbs}
          />
          {!!data && <VideoItem data={{
            calories: data.Calories,
            chal_name: data.ChalName || "",
            chal_score: data.ChalScore,
            isRaidBattle: data.isRaidBattle,
            rep_count: data.RepCount,
            set_count: data.SetCount,
            slice_count: data.SliceCount,
            starttime: data.StartTime,
            total_volume: setData.total_volume || 0,
            total_work: data.TotalWork,
            trophies_earned: data.UserEarnedTrophies || 0,
            user_champions_deployed: data.UserChampionsDeployed,
            video_link: data.cloudFrontLink,
            user_score: data.UserScore,
            match_outcome: data.matchOutcome || "",
            challenger_reached: data.challengerReached || data.points || 0,
            raid_points_earned: data.pointsDelta || 0
          }} order={0} />}
          <Box sx={{
            marginTop: 9,
            marginBottom: 35,
            textAlign: "center",
            paddingLeft: {
              xs: 2,
              tl: 0,
            },
            paddingRight: {
              xs: 2,
              tl: 0,
            },
          }}>
            <Typography sx={{
              fontSize: "24px",
              width: {
                xs: "auto",
                tl: "455px",
              },
              margin: "auto"
            }}>
              NOTE: Gameplay videos are automatically deleted after a certain number of days.
            </Typography>
            <CustomButton href={`/member/${username}`} sx={{ marginTop: 9 }}>
              View Profile Main Page
            </CustomButton>
          </Box>
        </>
      )}
      <Footer />
    </Box>
  );
};

export default Workout;
