import { Box } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import Markdown from "react-markdown";
import { useCallback, useEffect, useState } from "react";
import { pickBy, mean, map as _map } from "lodash";

import Container from "../../../components/Layout/Container";
import { Heading2, Heading4 } from "../../../components/Heading";

const AIExerciseAnalysis = ({
  data,
}) => {

  const [exerciseSetsTitles, setExerciseSetsTitles] = useState([]);
  const [exerciseSetsTotalSets, setExerciseSetsTotalSets] = useState([]);
  const [exerciseSetsFlags, setExerciseSetsFlags] = useState([]);
  const [exerciseRecapResponse, setExerciseRecapResponse] = useState("");

  const getExerciseNameFromKey = useCallback((key) => {
    try {
      return data?.directDamageDetails.find(item => item.exerciseKey === key).exerciseName;
    } catch (e) {
      return key;
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      setExerciseRecapResponse(data?.currentWeek?.weeklyRecap?.exercise_recap_response || "");
      const compoundExerciseStats = pickBy(data?.currentWeek?.weeklyRecap?.exerciseStats, (val) => val.isCompoundExercise !== false);
      setExerciseSetsTitles(Object.keys(compoundExerciseStats).map(item => getExerciseNameFromKey(item)));
      const tempValues = _map(compoundExerciseStats, 'total_sets')
      setExerciseSetsTotalSets(tempValues);
      const averageValue = mean(tempValues);
      const tempFlags = tempValues.map((val) => {
        return val < averageValue / 4;
      })
      setExerciseSetsFlags(tempFlags);
    }
  }, [data, getExerciseNameFromKey]);

  const getOptionBarCharts = useCallback((types, values, flags) => ({
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: types
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: values.map((val, ind) => {
          return {
            value: val,
            itemStyle: {
              color: flags[ind] === true ? "#a90000" : "#D9D9D9"
            }
          }
        }),
        type: "bar",
      },
    ],
  }), []);

  const getOptionPieCharts = useCallback((types, values) => ({
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: "50%",
        data: values.map((value, index) => {
          return { value: value, name: types[index] }
        }),
      },
    ],
    backgroundColor: "#0F0C28",
  }), []);

  return (
    <Container sx={{
      marginTop: 6
    }}>
      <Heading2 sx={{
        marginBottom: 4
      }}>AI EXERCISE ANALYSIS</Heading2>
      {exerciseRecapResponse.split("\n").map((item, idx) => (
        <Box key={`description-${idx}`} mb={3.5} sx={{
          fontSize: "16px",
          lineHeight: "26px"
        }}>
          <Markdown>{item}</Markdown>
        </Box>
      ))}
      <Box sx={{
        display: "flex",
        flexDirection: { xs: "column", tl: "row" },
        justifyContent: "space-between",
        gap: 8,
      }}>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            width: { xs: "100%", tl: "70%" }
          }}
        >
          <Heading4>COMPOUND EXERCISE SETS</Heading4>
          <ReactEcharts option={
            getOptionBarCharts(exerciseSetsTitles, exerciseSetsTotalSets, exerciseSetsFlags)}
            style={{
              height: "600px",
              backgroundColor: "#0F0C28",
              flexGrow: 1
            }}
          />
        </Box>
        <Box sx={{
          width: { xs: "100%", tl: "30%" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row", tl: "column" },
          gap: 2.5
        }}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              flexGrow: 1,
            }}
          >
            <Heading4>PUSH VS. PULL EXERCISES</Heading4>
            <ReactEcharts option={
              getOptionPieCharts(["Push Exercises", "Pull Exercies"],
                [data?.currentWeek?.weeklyRecap?.exerciseTypeStats?.Push?.total_sets,
                data?.currentWeek?.weeklyRecap?.exerciseTypeStats?.Pull?.total_sets])
            } />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
              flexGrow: 1
            }}
          >
            <Heading4>UPPER VS. LOWER BODY</Heading4>
            <ReactEcharts option={
              getOptionPieCharts(["Upper Body", "Lower Body"],
                [data?.currentWeek?.weeklyRecap?.bodySectionStats?.Upper?.total_sets,
                data?.currentWeek?.weeklyRecap?.bodySectionStats?.Lower?.total_sets])
            } />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default AIExerciseAnalysis;
