import { Box } from '@mui/material';

import { useCallback, useEffect, useState } from 'react';

import useQuery from '../../../../utils/useQuery';
import { getAmityAPIToken } from '../../../../services/amityApi';
import {
  useFollowInfo,
  followMember,
  unfollowMember,
  sendInvitation,
} from '../../../../services/communityService';
import { removeAmityBearerToken } from '../../../../utils/cookie';

import { CustomButton } from '../../../../components/Buttons';

const FollowButton = ({ userId, inviteState }) => {
  const queryParameters = useQuery();
  const appLoginUserId = queryParameters.get('userId') || userId;

  const [isFollowing, setIsFollowing] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const [inviteStateStore, setInviteStateStore] = useState();

  useEffect(() => {
    setInviteStateStore(inviteState);
  }, [inviteState]);

  useEffect(() => {
    removeAmityBearerToken();
    getAmityAPIToken(appLoginUserId);
  }, [appLoginUserId]);

  const { data: followInfoData, mutate, error } = useFollowInfo(userId);
  const { follows = [{}] } = followInfoData?.data ?? {};

  const onToggleFollow = useCallback(() => {
    if (follows[0]?.status !== 'none') {
      setIsFollowing(true);
      unfollowMember(userId)
        .then(() => {
          mutate();
          setIsFollowing(false);
        })
        .catch((error) => {
          setIsFollowing(false);
        });
    } else {
      setIsFollowing(true);
      followMember(userId)
        .then(() => {
          mutate();
          setIsFollowing(false);
        })
        .catch((error) => {
          setIsFollowing(false);
        });
    }
  }, [follows, userId, mutate]);

  const onInviteToTeam = () => {
    const { inviterId, inviterTeamId, inviterUsername, invitedId, invitedUsername } =
      inviteStateStore;
    const payload = {
      action: 'inviteToTeam',
      inviterId,
      inviterTeamId,
      inviterUsername,
      invitedId,
      invitedUsername,
    };

    setIsInviting(true);

    sendInvitation(payload)
      .then((data) => {
        setIsInviting(false);
        if (data.status === 200) {
          setInviteStateStore(1);
        }
      })
      .catch((error) => {
        setIsInviting(false);
      });
  };

  const getInviteButtonText = (state) => {
    if (state === 1) {
      return 'Team Invite Sent';
    } else if (state === 2) {
      return 'Invite to Team';
    } else {
      return 'Currently on a team';
    }
  };

  if (!!error) {
    mutate();
  }

  if (userId === appLoginUserId) {
    return <></>;
  }

  return (
    <Box>
      <CustomButton onClick={onToggleFollow} loading={isFollowing || followInfoData.isLoading}>
        {follows[0]?.status === 'none' ? 'Follow' : 'Unfollow'}
      </CustomButton>
      {(inviteStateStore === 1 || inviteStateStore?.state === 2 || inviteStateStore === 3) && (
        <CustomButton
          variant="outlined"
          sx={{ ml: 2 }}
          disabled={inviteStateStore === 1 || inviteStateStore === 3}
          loading={isInviting}
          onClick={onInviteToTeam}
        >
          {getInviteButtonText(inviteStateStore?.state ?? inviteStateStore)}
        </CustomButton>
      )}
    </Box>
  );
};

export default FollowButton;
