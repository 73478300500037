import { Box } from '@mui/material';

import FooterMenu from '../../../../components/Footer/FooterMenu';
import FooterBanner from '../../../../components/Footer/FooterBanner';

const TeamProfileFooter = () => {
  return (
    <Box>
      <Box sx={{ marginBottom: 16 }}>
        <FooterBanner />
      </Box>
      <FooterMenu />
    </Box>
  );
};

export default TeamProfileFooter;
