import { Box } from '@mui/material';

import { formatCompactNumber } from '../../../../../utils';

import OverviewBoard from '../../../../../components/Overview/OverviewBoard';

const StatsSummary = ({ stats }) => {
  const data = [
    { title: 'Workouts Completed', value: formatCompactNumber(stats.workoutCount) },
    {
      title: 'Sets | Reps',
      value: `${formatCompactNumber(stats.totalSets)}|${formatCompactNumber(stats.totalReps)}`,
      description:
        'A “set” in strength training refers to a specific number of consecutive repetitions of an exercise performed without rest.<br />A “rep” or repetition is one complete motion of a particular exercise, from the starting position back to the starting position again.',
    },
    {
      title: 'Volume (Lbs.)',
      value: formatCompactNumber(stats.totalVolume),
      description:
        'In strength training, “volume” refers to the total amount of weight lifted, commonly calculated as the number of sets multiplied by the number of repetitions per set, further multiplied by the weight used for each exercise.<br />Example set: 100 lbs. x 10 reps = 1000 lbs. of volume',
    },
    {
      title: 'Work (Ft x Lbs.)',
      value: formatCompactNumber(stats.totalWork),
      description:
        'Work in foot-pounds (ft.*lbs) is a measure of the energy expended in lifting a weight over a certain distance. It is calculated by multiplying the force exerted on the weight (measured in pounds) by the distance the weight is moved (measured in feet).<br />Unlike volume, work also takes into account the rep length.',
    },
    { title: 'Calories', value: formatCompactNumber(stats.totalCalories) },
  ];

  return (
    <Box
      sx={{
        background: '#141516',
        padding: { xs: 4, tl: '32px 76px', md: '40px 64px' },
        mt: { xs: 8, tl: 5, md: 2 },
      }}
    >
      <OverviewBoard data={data} />
    </Box>
  );
};

export default StatsSummary;
