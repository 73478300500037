import * as React from 'react';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';

import { formatLocalizedDate } from '../../../../../utils';

const option = (dates, values) => ({
  grid: {
    height: 405,
    left: 40,
    top: 10,
    right: 0,
  },
  xAxis: {
    type: 'category',
    // boundaryGap: false,
    // axisTick: {
    //   show: false,
    // },
    // axisLabel: {
    //   align: 'right',
    // },
    data: dates,
    show: false,
  },
  yAxis: {
    type: 'value',
    // min: 100,
    // max: 150,
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      label: {
        formatter: function (params) {
          return formatLocalizedDate(params.value, 'YYYY-MM-DD');
        },
      },
    },
  },
  series: [
    {
      data: values,
      type: 'line',
      smooth: true,
      showSymbol: false,
      symbolSize: 12,
      lineStyle: {
        width: 3,
        color: '#FF00FF',
      },
      itemStyle: {
        color: '#FF00FF',
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: '#FF00FF',
          },
          {
            offset: 1,
            color: 'rgba(229, 139, 204, 0.00)',
          },
        ]),
      },
    },
  ],
});

const periods = [
  "All-Time",
  "Last 7 Days",
  "Last 30 Days",
  "Last 90 Days",
  "Last 365 Days",
];

const ChartSection = ({ title, data }) => {
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ selectedPeriod, setSelectedPeriod ] = React.useState(periods[0]);
  const open = Boolean(anchorEl);
  const allDates = Object.keys(data);
  allDates.sort();
  let dates = [];

  switch (selectedPeriod) {
    case periods[0]:
      dates = allDates;
      break;
    case periods[1]:
      const sevenDays = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      dates = allDates.filter(dt => new Date(dt) >= sevenDays);
      break;
    case periods[2]:
      const thirtyDays = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      dates = allDates.filter(dt => new Date(dt) >= thirtyDays);
      break;
    case periods[3]:
      const ninetyDays = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
      dates = allDates.filter(dt => new Date(dt) >= ninetyDays);
      break;
    case periods[4]:
      const oneYearDays = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
      dates = allDates.filter(dt => new Date(dt) >= oneYearDays);
      break;
    default:
  }

  const values = dates.map((date) => data[date]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (newPeriod) => {
    setSelectedPeriod(newPeriod);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", tl: "center" }}
        mb={{ xs: 5, tl: 9 }}
        flexDirection={{ xs: "column", tl: "row" }}
      >
        <Typography fontWeight={700}>{title} - One Rep Max (Lbs.) Progress</Typography>
        <Box
          sx={{
            borderRadius: "8px",
            backgroundColor: "rgb(238, 238, 238)",
            color: "rgb(33, 43, 54)",
            height: "36px",
            position: "relative",
            paddingLeft: "10px",
            paddingRight: "30px",
          }}
          mt={{ xs: 1, tl: 0 }}
          onClick={handleOpenMenu}
        >
          <Typography
            sx={{ cursor: "pointer" }}
            fontSize={14}
            lineHeight="36px"
            display="flex"
            alignItems="center"
          >
            {selectedPeriod}
            <CalendarTodayIcon sx={{ marginLeft: 1, fontSize: 14 }} />
          </Typography>
          <Box
            sx={{
              position: "absolute",
              right: "0px",
              top: "6px",
            }}
          >
            <KeyboardArrowDownIcon />
          </Box>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {},
          }}
        >
          {periods.map((option) => (
            <MenuItem key={option} selected={option === selectedPeriod} onClick={() => handleSelect(option)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 446 }}>
        <ReactEcharts option={option(dates, values)} style={{ width: '100%', height: '100%' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 'calc(100% - 40px)',
            pl: 5,
            mt: 1,

            '& p': {
              color: '#A2A3A5',
              fontSize: 13,
            },
          }}
        >
          <Typography>
            {dates.length > 0 ? formatLocalizedDate(dates[0], 'MMM YYYY') : ''}
          </Typography>
          <Typography>
            {dates.length > 0 ? formatLocalizedDate(dates[dates.length - 1], 'MMM YYYY') : ''}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ChartSection;
