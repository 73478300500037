export const BBVR_SITE_URL = 'https://www.blackbox-vr.com';

export const EMAIL_PATTERN = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.+-]+\.[a-zA-Z]{2,4}$/;

export const EMOJI_PATTERN =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

export const SIGN_SYMBOL_PATTERN = /[@]/;

export const PASSWORD_PATTERN = /(?=.*\d)(?=.*[a-zA-Z]).{8,}/;

export const USERNAME_PATTERN = /^[a-zA-Z0-9]{3,40}$/;

export const SOCIAL_SEARCH_TYPE = { USER: 'user', TEAM: 'team', GYM: 'gym', GEM: 'gem' };

export const FITNESS_GOAL = [
  {
    id: 1,
    name: 'Full Body Transformation',
  },
  { id: 2, name: 'Build Muscle and Strength' },
  { id: 3, name: 'Lose Fat and Get Lean' },
  { id: 4, name: 'Improve for Sports' },
  { id: 5, name: 'Improve Overall Health' },
];

export const HELP_TEXT_LIST = [
  'Unlike traditional workout methods that may have felt boring or monotonous, Black Box VR offers an innovative, game-like experience.',
  'Our immersive VR technology transforms exercise into a captivating adventure, making it far more enjoyable and engaging.',
  'The AI-driven customization of Black Box VR tailors workouts specifically to your fitness level and objectives, ensuring that each session is both effective and time-efficient.',
  "You're not just working out; you're following a personalized path designed for rapid and sustainable progress.",
  "It's important to recognize that your previous struggles with fitness weren't a reflection of your commitment, but rather the lack of suitable options in the fitness industry.",
  'Black Box VR is a brain hack designed to keep you motivated and committed, a stark contrast to your past experiences.',
  "You'll join a group of like-minded individuals who are all on their fitness journeys. This sense of community can provide extra motivation and support.",
  'The ability to track your progress in real-time will constantly remind you of how far you’ve come and how close you are to your goals.',
  "Black Box VR isn't just a gym; it's a revolution in fitness. By signing up, you're not just working out, you're part of a pioneering movement that's redefining what exercise can be.",
];

export const QUESTIONS = [
  {
    question: 'What is your current activity level?',
    answer: [
      {
        id: 1,
        name: 'I’m not really active at all.',
      },
      {
        id: 2,
        name: 'I get some light exercise on most days.',
      },
      {
        id: 3,
        name: 'I workout very often and intensely.',
      },
    ],
  },
  {
    question: 'How fast do you want to reach your fitness goal?',
    answer: [
      {
        id: 1,
        name: 'I’m in a rush!',
      },
      {
        id: 2,
        name: 'As quickly as it realistically takes.',
      },
      {
        id: 3,
        name: 'I’m not in a hurry and can focus on the journey.',
      },
    ],
  },
  {
    question: 'Are you able to commit to 30 minute VR workouts, three times per week?',
    answer: [
      {
        id: 1,
        name: 'I can’t do it.',
      },
      {
        id: 2,
        name: 'Yes, that’s no problem at all.',
      },
    ],
  },
];

export const GENDER = {
  male: 'male',
  female: 'female',
};

export const LEADERBOARD_ACTIONS = {
  totalWork: { id: 'getTotalWorkLeaderboard', title: 'Total Work Done', category: 'individual' },
  totalWorkouts: { id: 'getTotalWorkoutsLeaderboard', title: 'Completed Workouts', category: 'individual' },
  averageWork: { id: 'getAverageWorkLeaderboard', title: 'Average Work', category: 'individual' },
  gems: { id: 'getGemsLeaderboard', title: 'Unity Gems Earned', category: 'individual' },
  individualRaid: { id: 'getIndividualRaidPointsLeaderboard', title: 'Individual Raid Points', category: 'individual' },
  fullgymreward: { id: 'fullGymRewardLeaderboard', title: 'Full Gym Raid Points Reward', category: 'fullgymreward' },
  lifetimeXp: { id: 'getLifetimeXPLeaderboard', title: 'Lifetime XP', category: 'individual' },
  seasonXp: { id: 'getSeasonXPLeaderboard', title: 'Season XP', category: 'individual' },
  streaks: { id: 'getStreakLeaderboard', title: 'Streaks', category: 'individual' },
  teamRaid: { id: 'getTeamRaidPointsLeaderboard', title: 'Team Raid Points', category: 'teams' },
};

export const LEADERBOARD_PERIODS = {
  all: { id: 'allTime', title: 'All-Time' },
  lastYear: { id: 'lastYear', title: 'Last Year' },
  thisYear: { id: 'thisYear', title: 'This Year' },
  lastSeason: { id: 'lastSeason', title: 'Last Season' },
  thisStreak: { id: 'currentStreaks', title: 'Current Streak' },
  currentSeason: { id: 'currentSeason', title: 'Current Season' },
  lastMonth: { id: 'lastMonth', title: 'Last Month' },
  thisMonth: { id: 'thisMonth', title: 'This Month' },
  lastWeek: { id: 'lastWeek', title: 'Last Week' },
  thisWeek: { id: 'thisWeek', title: 'This Week' },
  last24Hours: { id: 'last24Hours', title: 'Last 24 Hours' },
  lastHours: { id: 'last1Hour', title: 'Last 1 Hour' },
};

export const LEADERBOARD_LOCATIONS = {
  2: { id: 2, title: 'Meridian' },
  3: { id: 3, title: 'San Francisco' },
};

export const VIPCODE_STATUS = {
  VALID: "VALID",
  EXPIRED: "EXPIRED",
  INVALID: "INVALID",
}
